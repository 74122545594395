/**
 * This is create auth user tabel
 * @author Rahul Bhati
 * @since 1.0
 */

import {
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { enableStatusApi } from "../../apis/user-api";
import theme from "../../assets/theme/theme";
import { showAlertModal, hideAlertModal } from "../../store/actions/ui-actions";
import { connect, useSelector } from "react-redux";
import { ALERT } from "../../utils/ui-constants";
import { useState } from "react";
import ResetPassword from "./modals/reset-password";
const useStyles = makeStyles({
  skeleton: {
    padding: theme.spacing(3),
  },
});

const CrudOperationTable = (props) => {
  const classes = useStyles();

  const [buttonValue, setButtonValue] = useState("");
  const [userName, setUserName] = useState("");
  const { username } = useSelector(
    (state) => state.userReducer.userInfo
  );

  const onActionClick = (action, userName, status) => (event) => {
    switch (action) {
      case "CHANGE_STATUS":
        props.showAlertModal({
          heading: "Warning",
          description: `Do you want to ${
            status ? "disable" : "enable"
          } ${userName}?`,
          actionButtons: [
            {
              title: "No",
              callback: (event) => {
                props.hideAlertModal(event);
              },
            },
            {
              title: "Yes",
              callback: (event) => {
                enableStatus(userName, status, event);
              },
            },
          ],
          variant: ALERT.WARNING,
        });
        break;

      case "RESET":
        {
          setButtonValue("RESET");
          setUserName(userName);
        }
        break;

      case "EDIT":
        {
          props.onActionClick(action, userName);
        }
        break;

      default:
        break;
    }
  };

  const enableStatus = (userName, status, event) => {
    enableStatusApi(userName)
      .then((res) => {
        props._fetchAuthUserData(event);
        props.showAlertModal({
          heading: "Success",
          description: `Auth User ${
            status ? "Disabled" : "Enabled"
          } successfully.`,
          actionButtons: [
            {
              title: "Close",
              callback: (event) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.SUCCESS,
        });
      })
      .catch((err) => {
        props.showAlertModal({
          heading: "Error",
          description: `${err?.response?.data?.message}`,
          actionButtons: [
            {
              title: "Close",
              callback: (event) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
      <div style={{ maxHeight: "700px", overflowY: "scroll" }}>
        <Table>
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1,}} >
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email Id</TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>Authorities</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">{`Action`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.waitForAuthUser ? (
              Array(5)
                .fill(5)
                .map((skeleton, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={7} align="center">
                      <Skeleton
                        variant="rect"
                        width="95%"
                        height="10%"
                        animation="wave"
                        className={classes.skeleton}
                      />
                    </TableCell>
                  </TableRow>
                ))
            ) : props.authUserData.length !== 0 ? (
              props.authUserData.map((info, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{info.userName}</TableCell>
                  <TableCell>{info.firstName}</TableCell>
                  <TableCell>{info.lastName}</TableCell>
                  <TableCell>{info.emailId}</TableCell>
                  <TableCell>{info.phoneNo}</TableCell>
                  <TableCell>{info.authorities.join("")}</TableCell>
                  <TableCell>{info.enabled ? "Enabled" : "Disabled"}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={info.userName === username}
                      title="Change Status"
                      onClick={onActionClick(
                        "CHANGE_STATUS",
                        info.userName,
                        info.enabled
                      )}
                    >
                      <span className="material-icons">undo</span>
                    </IconButton>
                    <IconButton
                      size="small"
                      color="primary"
                      title="Reset Password"
                      onClick={onActionClick("RESET", info.userName)}
                    >
                      <span className="material-icons">lock_outline</span>
                    </IconButton>
                    <IconButton
                      size="small"
                      color="primary"
                      title="Edit Auth User"
                      onClick={onActionClick("EDIT", info.userName)}
                    >
                      <span className="material-icons">edit</span>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="h5"> Data Not Available </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        </div>
      </TableContainer>
      <ResetPassword
        buttonValue={buttonValue}
        userName={userName}
        handleClose={() => setButtonValue("")}
      />
    </>
  );
};

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(null, mapDispatchToProps)(CrudOperationTable);
