import { colors, createTheme, responsiveFontSizes } from "@material-ui/core";
import { common } from "@material-ui/core/colors";

const palette = {
    primary: {
        main: colors.blueGrey[900]
    },
    secondary: {
        main: colors.deepOrange[500]
    },
    type: 'light'
};

const theme = createTheme({

    palette: {
        ...palette
    },
    overrides: {
        MuiAppBar: {
            root: {
                zIndex: 1300,
            }
        },
        MuiTab: {
            root: {
                maxWidth: 'fit-content',
                textTransform: 'initial',
                fontSize: `1rem`
            }
        },
        MuiBottomNavigation: {
            root: {
                justifyContent: 'flex-start'
            }
        },
        MuiBottomNavigationAction: {
            root: {
                color: palette.primary
            }
        },
        MuiDialog: {
            container: {
                height: '80%'
            }
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: palette.primary.main,
                padding: '8px 24px',
                color: common.white
            }
        },
        MuiDialogContent: {
            dividers: {
                padding: `32px 24px`
            }
        },
        MuiAccordionSummary: {
            root: {
                background: palette.primary.main,
                color: common.white
            },
            expandIcon: {
                color: common.white
            }
        },
        MuiTabs: {
            indicator: {
                height: 4
            }
        },
        MuiTableHead: {
            root: {
                backgroundColor: palette.primary.main,
            }
        },
        MuiTableCell: {
            head: {
                fontWeight: 500,
                color: 'white'
            }
        },
        MuiButtonBase: {
            root: {
                '&:disabled': {
                    color: colors.grey[500],
                }
            }
        },
        MuiButton: {
            root: {
                textTransform: 'initial',
                fontSize: `0.95rem`
            },
            sizeLarge: {
                fontSize: `1rem`
            },
            sizeSmall: {
                fontSize: `0.9rem`
            },
        },
        MuiChip: {
            root: {
                borderRadius: `4px`,
                margin: `4px`
            },
            deleteIcon: {
                '&:hover': {
                    color: colors.red[600],
                }
            },
            deleteIconSmall: {
                '&:hover': {
                    color: colors.red[600],
                }
            },
            deleteIconOutlinedColorPrimary: {
                '&:hover': {
                    color: colors.red[600],
                }
            },
            deleteIconOutlinedColorSecondary: {
                '&:hover': {
                    color: colors.red[600],
                }
            },
            deleteIconColorPrimary: {
                '&:hover': {
                    color: colors.red[600],
                }
            },
            deletableColorSecondary: {
                '&:hover': {
                    color: colors.red[600],
                }
            }
        }
    }

});

export default responsiveFontSizes(theme);