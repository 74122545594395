/**
 * <p> This is Authenticated Route module to protect web page access with out login </p>
 *
 * @author Aman Harde
 *
 */

import { useSelector, useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import LeftDrawer from "../components/drawer/left-drawer";
import { logout } from "../store/actions/user-action";


const AuthenticatedRoutes = ({
  component: Component,
 // requiredRoles,
  ...rest
}) => {
  const { token } = useSelector((state) => state.userReducer.userInfo);
  const dispatch = useDispatch();

  // const roles = userInfo ? userInfo.roles : [];
  // const hasRequiredRole = requiredRoles.some((role) => roles.includes(role));

  const handleLogout = () => {
    // Dispatch the logout action to clear user info from the Redux store
    dispatch(logout());
    // Clear authentication token from local storage
    localStorage.removeItem("accessToken");
  };

  return token ? (
    <div id="left-drawer-wrapper">
      <LeftDrawer />
      <Route {...rest} render={(props) => <Component {...props} />} />
    </div>
  ) : (
    <Redirect to={`/login`} refresh="true" strict />
  );
};

export default AuthenticatedRoutes;
