/**
 * This is dashboard to handel search filters, and issue boards
 * @author Aman Harde
 * @since 1.0
 */

import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { filterAllIssues } from "../../apis/issue-api";
import { fetchLabels } from "../../apis/label-api";
import { fetchMileStonesByPatternAndStatus } from "../../apis/milestone-api";
import { getUsersByPattern } from "../../apis/user-api";
import SearchBar from "../../components/search-bar";
import {
  AppliedFilter,
  FilterOption,
  PageRequest,
  Suggestion,
  SuggestionValues,
} from "../../components/search-bar/intefaces";
import searchRequestConvertor from "../../components/search-bar/searchRequestConvertor";
import { IssueInfo } from "../../interface/issue-inteface";
import { LabelInfo } from "../../interface/label-interface";
import { MilestoneRef } from "../../interface/milestone-interface";
import { AuthUser } from "../../interface/user-interface";
import { showTab } from "../../store/actions/ui-actions";
import { TAB_TYPE } from "../../utils/ui-constants";
import IssueBoard from "../dashboard/issue-board";
import { ISSUE_STATE } from "../issue";
import { fetchFilter } from "../../store/actions/ui-actions";
import { FetchType } from "../../store/type";

const style = makeStyles({
  root: {
    display: "contents",
  },
});

interface InitialIssueState {
  data: IssueInfo[];
  pageRequest: PageRequest;
  wait: boolean;
  totalElements: number;
}

const initialIssueState: InitialIssueState = {
  data: [] as IssueInfo[],
  pageRequest: {
    page: 1,
    size: 20,
  } as PageRequest,
  wait: false,
  totalElements: 0,
};

const filterOptions: FilterOption[] = [
  {
    label: "Assignee",
    value: "assignee",
    type: "suggestion",
    suggestionType: "dynamic",
  },
  {
    label: "Label",
    value: "label",
    type: "suggestion",
    suggestionType: "dynamic",
  },
  {
    label: "Milestone",
    value: "milestone",
    type: "suggestion",
    suggestionType: "dynamic",
  },
  { label: "Issue number", value: "issueNumber", type: "string" },
  {
    label: "Created By",
    value: "createdBy",
    type: "suggestion",
    suggestionType: "dynamic",
  },
];

const assignee: Suggestion = {
  values: [],
  key: filterOptions[0].label,
  ref: filterOptions[0].value,
};

const MILESTONE_STATUS = ["OPEN"];

const createdBy: Suggestion = {
  values: [],
  key: filterOptions[4].label,
  ref: filterOptions[4].value,
};

const label: Suggestion = {
  values: [],
  key: filterOptions[1].label,
  ref: filterOptions[1].value,
};

const milestone: Suggestion = {
  values: [],
  key: filterOptions[2].label,
  ref: filterOptions[2].value,
};

const Dashboard: FC = () => {
  const classes = style();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    appliedFilters: [] as AppliedFilter[],
  });

  const filterTextFieldRef = useRef<HTMLInputElement>();

  const [openIssues, setOpenIssues] =
    useState<InitialIssueState>(initialIssueState);
  const [newIssues, setNewIssues] =
    useState<InitialIssueState>(initialIssueState);
  const [onHoldIssues, setOnHoldIssues] =
    useState<InitialIssueState>(initialIssueState);
  const [resolvedIssues, setResolvedIssues] =
    useState<InitialIssueState>(initialIssueState);

  const fetchOpenIssues = (filterRequest: any, isScrollable?: boolean) => {
    setOpenIssues({
      ...openIssues,
      wait: true,
    });

    filterRequest.state = ISSUE_STATE.OPEN;

    filterAllIssues(filterRequest)
      .then((res: AxiosResponse<any>) => {
        setOpenIssues({
          ...openIssues,
          data: isScrollable
            ? openIssues.data.concat(res.data.content)
            : res.data.content,
          pageRequest: {
            ...openIssues.pageRequest,
            page:
              res.data.content.length === 0
                ? openIssues.pageRequest.page
                : res.data.number + 1,
            size: res.data.size,
          },
          wait: false,
          totalElements: res.data.totalElements,
        });
      })
      .catch((err: any) => {
        setOpenIssues({
          ...openIssues,
          wait: false,
        });
      });
  };

  const fetchNewIssues = (filterRequest: any, isScrollable?: boolean) => {
    setNewIssues({
      ...newIssues,
      wait: true,
    });

    filterRequest.state = ISSUE_STATE.NEW;

    filterAllIssues(filterRequest)
      .then((res: AxiosResponse<any>) => {
        setNewIssues({
          ...newIssues,
          data: isScrollable
            ? newIssues.data.concat(res.data.content)
            : res.data.content,
          pageRequest: {
            ...newIssues.pageRequest,
            page:
              res.data.content.length === 0
                ? newIssues.pageRequest.page
                : res.data.number + 1,
            size: res.data.size,
          },
          wait: false,
          totalElements: res.data.totalElements,
        });
      })
      .catch((err: any) => {
        setNewIssues({
          ...openIssues,
          wait: false,
        });
      });
  };

  const fetchOnHoldIssues = (filterRequest: any, isScrollable?: boolean) => {
    setOnHoldIssues({
      ...onHoldIssues,
      wait: true,
    });

    filterRequest.state = ISSUE_STATE.ON_HOLD;

    filterAllIssues(filterRequest)
      .then((res: AxiosResponse<any>) => {
        setOnHoldIssues({
          ...onHoldIssues,
          data: isScrollable
            ? onHoldIssues.data.concat(res.data.content)
            : res.data.content,
          pageRequest: {
            ...onHoldIssues.pageRequest,
            page:
              res.data.content.length === 0
                ? onHoldIssues.pageRequest.page
                : res.data.number + 1,
            size: res.data.size,
          },
          wait: false,
          totalElements: res.data.totalElements,
        });
      })
      .catch((err: any) => {
        setOnHoldIssues({
          ...onHoldIssues,
          wait: false,
        });
      });
  };

  const fetchResolvedIssues = (filterRequest: any, isScrollable?: boolean) => {
    setResolvedIssues({
      ...resolvedIssues,
      wait: true,
    });

    filterRequest.state = ISSUE_STATE.RESOLVED;

    filterAllIssues(filterRequest)
      .then((res: AxiosResponse<any>) => {
        setResolvedIssues({
          ...resolvedIssues,
          data: isScrollable
            ? resolvedIssues.data.concat(res.data.content)
            : res.data.content,
          pageRequest: {
            ...resolvedIssues.pageRequest,
            page:
              res.data.content.length === 0
                ? resolvedIssues.pageRequest.page
                : res.data.number + 1,
            size: res.data.size,
          },
          wait: false,
          totalElements: res.data.totalElements,
        });
      })
      .catch((err: any) => {
        setResolvedIssues({
          ...resolvedIssues,
          wait: false,
        });
      });
  };

  const onFilterChange = (appliedFilters: AppliedFilter[]) => {
    const filterRequest = searchRequestConvertor(
      appliedFilters,
      openIssues.pageRequest
    );

    switch (filterRequest.state as ISSUE_STATE) {
      case "OPEN":
        fetchOpenIssues(filterRequest);

        setNewIssues(initialIssueState);
        setOnHoldIssues(initialIssueState);
        setResolvedIssues(initialIssueState);
        break;

      case "NEW":
        fetchNewIssues(filterRequest);

        setOpenIssues(initialIssueState);
        setOnHoldIssues(initialIssueState);
        setResolvedIssues(initialIssueState);
        break;

      case "ON_HOLD":
        fetchOnHoldIssues(filterRequest);

        setOpenIssues(initialIssueState);
        setNewIssues(initialIssueState);
        setResolvedIssues(initialIssueState);
        break;

      case "RESOLVED":
        fetchResolvedIssues(filterRequest);

        setOpenIssues(initialIssueState);
        setNewIssues(initialIssueState);
        setOnHoldIssues(initialIssueState);
        break;

      default:
        fetchOpenIssues(filterRequest);
        fetchNewIssues(filterRequest);
        fetchOnHoldIssues(filterRequest);
        fetchResolvedIssues(filterRequest);

        break;
    }

    setState({
      ...state,
      appliedFilters,
    });
  };

  const onButtonClick = (tabValue: TAB_TYPE) => (event: any) => {
    dispatch(showTab(tabValue));
  };

  const onIssueClick = (issueNumber: string, event: any) => {
    dispatch(showTab("VIEW_ISSUE_INFO_TAB", { issueNumber }));
  };

  const fetchIssuesOnScroll = (issueState: ISSUE_STATE) => {
    switch (issueState) {
      case "OPEN":
        fetchOpenIssues(
          searchRequestConvertor(state.appliedFilters, {
            ...openIssues.pageRequest,
            page: openIssues.pageRequest.page + 1,
          }),
          true
        );
        break;

      case "NEW":
        fetchNewIssues(
          searchRequestConvertor(state.appliedFilters, {
            ...newIssues.pageRequest,
            page: newIssues.pageRequest.page + 1,
          }),
          true
        );

        break;
      case "ON_HOLD":
        fetchOnHoldIssues(
          searchRequestConvertor(state.appliedFilters, {
            ...onHoldIssues.pageRequest,
            page: onHoldIssues.pageRequest.page + 1,
          }),
          true
        );
        break;

      case "RESOLVED":
        fetchResolvedIssues(
          searchRequestConvertor(state.appliedFilters, {
            ...resolvedIssues.pageRequest,
            page: resolvedIssues.pageRequest.page + 1,
          }),
          true
        );
        break;
    }
  };

  const onSuggestionChange = (selectedKey: FilterOption, value: string) => {
    switch (selectedKey.value) {
      case "assignee":
        return new Promise<SuggestionValues[]>((resolve, reject) => {
          getUsersByPattern({ authUser: value }).then(
            (res: AxiosResponse<any>) => {
              let values: SuggestionValues[] = [];

              res.data.map((user: AuthUser) => {
                values.push({
                  displayMember: user.username,
                  valueMember: { assignee: user.username },
                });
              });
              resolve(values);
            }
          );
        });

      case "createdBy":
        return new Promise<SuggestionValues[]>((resolve, reject) => {
          getUsersByPattern({ authUser: value }).then(
            (res: AxiosResponse<any>) => {
              let values: SuggestionValues[] = [];

              res.data.map((user: AuthUser) => {
                values.push({
                  displayMember: user.username,
                  valueMember: { assignee: user.username },
                });
              });
              resolve(values);
            }
          );
        });

      case "label":
        return new Promise((resolve, reject) => {
          fetchLabels({ value: value }).then((res: AxiosResponse<any>) => {
            let values: SuggestionValues[] = [];
            res.data.map((_label: LabelInfo) => {
              values.push({
                displayMember: `${_label.name}:${_label.value}`,
                valueMember: { label: _label.value },
              });
            });

            resolve(values);
          });
        });

      case "milestone":
        return new Promise((resolve, reject) => {
          fetchMileStonesByPatternAndStatus({
            pattern: value,
            status: MILESTONE_STATUS.reduce((f, s) => `${f},${s}`),
          }).then((res: AxiosResponse<any>) => {
            let values: SuggestionValues[] = [];

            res.data.map((_milestone: MilestoneRef) => {
              values.push({
                displayMember: `${_milestone.projectRef.value} : [ ${_milestone.version} ]`,
                valueMember: {
                  label: `${_milestone.projectRef.value} : [ ${_milestone.version} ]`,
                },
              });
            });
            resolve(values);
          });
        });
    }
  };
  return (
    <Container id="dashboard-wrapper" maxWidth="xl">
      <Grid container spacing={2} className={classes.root}>
        <Grid
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          id="dashboard-head-wrapper"
        >
          <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
            <Typography variant="h4">{"IssueBoard"}</Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            item
            xl={2}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            direction="row-reverse"
            alignContent="flex-end"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<i className="fas fa-bug" />}
                size="small"
                id="rise-issue-button"
                onClick={onButtonClick("NEW_ISSUE_TAB")}
              >
                {"New Issue"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Divider />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          id="dashboard-search-bar-wrapper"
        >
          <SearchBar
            keys={filterOptions}
            suggestions={[assignee, createdBy, label, milestone]}
            collectionName={"Search"}
            fetchSavedFilter={() => {
              dispatch(fetchFilter(FetchType.FETCH_START));
            }}
            onFilterChange={(filters: any) =>
              new Promise((resolve, reject) => {
                resolve(onFilterChange(filters));
                reject();
              })
            }
            onSuggestionChange={onSuggestionChange}
          />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          id="dashboard-issue-board-wrapper"
        >
          <IssueBoard
            openIssues={openIssues}
            newIssues={newIssues}
            onHoldIssues={onHoldIssues}
            resolvedIssues={resolvedIssues}
            onIssueClick={onIssueClick}
            fetchIssuesOnScroll={fetchIssuesOnScroll}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
