/**
 * This is create issue form
 * @author Aman Harde
 * @since 1.0
 */

import { Button, CircularProgress, Container, Divider, FormHelperText, Grid, ListItem, ListItemText, makeStyles, TextField, Typography, useMediaQuery } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createIssue, fetchIssuesRef } from "../../apis/issue-api";
import { getUsersByPattern } from "../../apis/user-api";
import { AuthUser } from "../../interface/user-interface";
import { Skeleton } from "@material-ui/lab";
import { MilestoneRef } from "../../interface/milestone-interface";
import { fetchMileStonesByPatternAndStatus } from "../../apis/milestone-api";
import { fetchLabels } from "../../apis/label-api";
import { LabelInfo, LabelRef } from "../../interface/label-interface";
import { checkFieldErrors } from "../../utils/field-error-checker";
import theme from "../../assets/theme/theme";
import MDEditor, { codePreview, commands } from '@uiw/react-md-editor';
import { showTab } from "../../store/actions/ui-actions";
import { useDispatch, useSelector } from "react-redux";
import { setCreateIssueData } from "../../store/actions/issue-action";
import { RootState } from "../../store/reducers";
import { initialState } from "../../store/reducers/issue-reducer";
import { IssueRef } from "../../interface/issue-inteface";


const style = makeStyles({
    root: {
        display: 'contents'
    },
    preview: {
        display: 'contents',
        margin: theme.spacing(2)
    },
    renderOption: {
        margin: 0,
        padding: 0
    },

    mdEditor: {
        zIndex: 1,
    }


});


const CreateIssue: FC = () => {

    const classes = style();
    const breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch();
    const formData = useSelector((rootState: RootState) => rootState.issueReducer.createIssueForm);


    const [state, setState] = useState({
        fieldError: {
            title: '',
            desc: ''
        },
        wait: {
            forSubmit: false
        },
        errorMessage: ''
    });

    const [authUsers, setAuthUsers] = useState({
        data: [] as AuthUser[],
        wait: false
    });

    const [milestones, setMilestones] = useState({
        data: [] as MilestoneRef[],
        wait: false
    });

    const [_labels, setLabels] = useState({
        data: [] as LabelRef[],
        wait: false
    });

    const MILESTONE_STATUS = ['OPEN'];

    const [issuesRef, setIssuesRef] = useState({
        data: [] as IssueRef[],
        wait: false
    });

    const resetState = (event: any) => {
        dispatch(setCreateIssueData(initialState.createIssueForm));
        setState({
            ...state,
            fieldError: {
                title: '',
                desc: ''
            },
            wait: {
                forSubmit: false
            },
            errorMessage: ''
        });
    }

    const fetchAuthUsers = (event: any) => {
        setAuthUsers({
            ...authUsers,
            wait: true
        });

        getUsersByPattern({ authUser: event.target.value }).then((res: AxiosResponse<any>) => {
            setAuthUsers({
                ...authUsers,
                data: res.data,
                wait: false
            });
        }).catch((err: any) => {
            setAuthUsers({
                ...authUsers,
                wait: false
            });
        });
    }

    const fetchMilestones = (event: any) => {
        setMilestones({
            ...milestones,
            wait: true
        });

        fetchMileStonesByPatternAndStatus({ pattern: event.target.value, status: MILESTONE_STATUS.reduce((f, s) => `${f},${s}`) }).then((res: AxiosResponse<any>) => {
            console.log("xyz", res.data);
            setMilestones({
                ...milestones,
                data: res.data,
                wait: false
            });
        }).catch((err: any) => {
            setMilestones({
                ...milestones,
                wait: false
            });
        });
    }

    const _fetchLabels = (event: any) => {
        setLabels({
            ..._labels,
            wait: true
        });

        fetchLabels({ value: event.target.value }).then((res: AxiosResponse<any>) => {
            setLabels({
                ..._labels,
                data: res.data.map((label: LabelInfo) => { return { value: label.value } as LabelRef }),
                wait: false
            });
        }).catch((err: any) => {
            setLabels({
                ..._labels,
                wait: false
            });
        });
    }

    const _fetchIssuesRef = (event: any) => {
        setIssuesRef({
            ...issuesRef,
            wait: true
        });

        fetchIssuesRef({ pattern: event.target.value }).then((res: AxiosResponse<any>) => {
            setIssuesRef({
                ...issuesRef,
                data: res.data,
                wait: false
            });
        }).catch((err: any) => {
            setIssuesRef({
                ...issuesRef,
                wait: false
            });
        });
    }

    const onInputChange = (field: string) => (event: any) => {

        switch (field) {
            case 'assignees':
                fetchAuthUsers(event);
                break;

            case 'milestone':
                fetchMilestones(event);
                break;

            case 'labels':
                _fetchLabels(event);
                break;

            case 'relatedLinks':
            case 'dependsOnLinks':
                _fetchIssuesRef(event);
                break;

            default:

                dispatch(setCreateIssueData({ ...formData, [field]: event.target.value }));
                setState({
                    ...state,
                    fieldError: {
                        ...state.fieldError,
                        [field]: ""
                    },
                    errorMessage: ''
                });
                break;
        }
    }


    const onAutoCompleteSelect = (field: string) => async (event: any, newValue: any) => {

        if (field === 'milestone' && newValue) {

            if (newValue.projectRef && newValue.projectRef.value) {
                const milestoneLabels = await fetchLabels({ value: newValue.projectRef.value });
                const labelData = milestoneLabels.data.map((label: LabelInfo) => {
                    return { value: label.value } as LabelRef;
                });


                dispatch(setCreateIssueData({ ...formData, [field]: newValue, labels: labelData }));
            } else {

                dispatch(setCreateIssueData({ ...formData, [field]: newValue }));
            }
        } else {

            dispatch(setCreateIssueData({ ...formData, [field]: newValue }));
        }

        setState({
            ...state,
            fieldError: {
                ...state.fieldError,
                [field]: "",
            },
            errorMessage: "",
        });
    };



    const validateFields = (): boolean => {

        let fieldError = checkFieldErrors(state.fieldError, { ...formData });
        setState({
            ...state,
            fieldError: fieldError.errors
        });

        return fieldError.haError;
    }

    const onButtonClick = (value: string) => (event: any) => {

        switch (value) {
            case 'reset':
                resetState(event);
                break;

            case 'create':
                submitForm(event);
                break;
            default:
                break;
        }
    }

    const submitForm = (event: any) => {

        if (!validateFields()) {
            setState({
                ...state,
                wait: {
                    ...state.wait,
                    forSubmit: true
                }
            });

            createIssue(formData).then((res: AxiosResponse<any>) => {
                dispatch(showTab("VIEW_ISSUE_INFO_TAB", { issueNumber: res.data.issueNumber }));
                resetState(event);
            }).catch((err: any) => {
                setState({
                    ...state,
                    errorMessage: 'Error while creating issue. Please try again.',
                    wait: {
                        ...state.wait,
                        forSubmit: false
                    }
                });
            });
        }
    }

    return (
        <Container id='create-issue-form-wrapper' maxWidth='xl'>
            <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.root}>
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
                        <Typography variant="h4">{"Create New Issue"}</Typography>
                    </Grid>
                </Grid>
                <Divider />
                <Container maxWidth='lg'>
                    <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.root} >
                        <Grid container spacing={breakpointDownSm ? 0 : 2} item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <Typography variant='subtitle1' align={breakpointDownSm ? 'left' : 'right'}><strong>{`Title`}</strong>  </Typography>
                            </Grid>

                            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    label='Issue Title'
                                    placeholder='Insert issue title'
                                    id='create-issue-title'
                                    value={formData.title}
                                    onChange={onInputChange('title')}
                                    error={state.fieldError.title !== ''}
                                    helperText={state.fieldError.title}
                                    disabled={state.wait.forSubmit}
                                />

                            </Grid>
                        </Grid>

                        <Grid container spacing={breakpointDownSm ? 0 : 2} item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <Typography variant='subtitle1' align={breakpointDownSm ? 'left' : 'right'}><strong>{`Milestone`}</strong>  </Typography>
                            </Grid>

                            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>

                                <Autocomplete
                                    size='small'
                                    fullWidth
                                    multiple={false}
                                    id='create-issue-milestone'
                                    options={milestones.data}
                                    onChange={onAutoCompleteSelect('milestone')}
                                    getOptionLabel={(option: MilestoneRef) => (`${option.projectRef.value} : [ ${option.version} ]`)}
                                    getOptionSelected={(option, value) => (option.projectRef.value === value.projectRef.value && option.version === value.version)}
                                    value={formData.milestone}
                                    noOptionsText={'Milestones not found'}
                                    loading={milestones.wait}
                                    disabled={state.wait.forSubmit}
                                    loadingText={
                                        <Grid container spacing={2}>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    renderOption={(option) => (
                                        <ListItem disableGutters dense className={classes.renderOption}>
                                            <ListItemText
                                                primary={`${option.projectRef.value} : [ ${option.version} ]`}
                                                primaryTypographyProps={{ variant: 'subtitle1', color: 'initial' }}
                                                className={classes.renderOption}
                                            />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder='Insert milestone'
                                            onChange={onInputChange('milestone')}
                                        />
                                    )}
                                />

                            </Grid>
                        </Grid>

                        <Grid container spacing={breakpointDownSm ? 0 : 2} item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <Typography variant='subtitle1' align={breakpointDownSm ? 'left' : 'right'}><strong>{`Assignees`}</strong>  </Typography>
                            </Grid>

                            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                <Autocomplete
                                    size='small'
                                    fullWidth
                                    multiple
                                    limitTags={4}
                                    id='create-issue-assignees'
                                    options={authUsers.data}
                                    onChange={onAutoCompleteSelect('assignees')}
                                    getOptionLabel={(option: AuthUser) => option.username}
                                    getOptionSelected={(option, value) => option.username === value.username}
                                    value={formData.assignees}
                                    noOptionsText={'Users not found'}
                                    loading={authUsers.wait}
                                    disabled={state.wait.forSubmit}
                                    loadingText={
                                        <Grid container spacing={2}>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder='Select Assignees'
                                            onChange={onInputChange('assignees')}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={breakpointDownSm ? 0 : 2} item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <Typography variant='subtitle1' align={breakpointDownSm ? 'left' : 'right'}><strong>{`Labels`}</strong>  </Typography>
                            </Grid>

                            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                <Autocomplete
                                    size='small'
                                    fullWidth
                                    multiple
                                    limitTags={4}
                                    id='create-issue-labels'
                                    options={_labels.data}
                                    onChange={onAutoCompleteSelect('labels')}
                                    getOptionLabel={(option) => option.value}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    value={formData.labels}
                                    noOptionsText={'Labels not found'}
                                    loading={_labels.wait}
                                    disabled={state.wait.forSubmit}
                                    loadingText={
                                        <Grid container spacing={2}>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder='Select labels'
                                            onChange={onInputChange('labels')}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>

                        <Grid container spacing={breakpointDownSm ? 0 : 2} item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <Typography variant='subtitle1' align={breakpointDownSm ? 'left' : 'right'}><strong title='Issue realted to'>{`Related Issues`}</strong></Typography>
                            </Grid>

                            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                <Autocomplete
                                    size='small'
                                    fullWidth
                                    multiple
                                    limitTags={4}
                                    id='create-issue-related-links'
                                    options={issuesRef.data}
                                    onChange={onAutoCompleteSelect('relatedLinks')}
                                    getOptionLabel={(option) => option.issueNumber}
                                    getOptionSelected={(option, value) => option.issueNumber === value.issueNumber}
                                    value={formData.relatedLinks}
                                    noOptionsText={'Issues not found'}
                                    loading={issuesRef.wait}
                                    disabled={state.wait.forSubmit}
                                    loadingText={
                                        <Grid container spacing={2}>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    renderOption={(option) => (
                                        <ListItem disableGutters dense className={classes.renderOption}>
                                            <ListItemText
                                                primary={option.issueNumber}
                                                primaryTypographyProps={{ variant: 'caption' }}
                                                secondary={option.title}
                                                secondaryTypographyProps={{ variant: 'subtitle1', color: 'initial' }}
                                                className={classes.renderOption}
                                            />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder='Link Issues by issue number or title'
                                            onChange={onInputChange('relatedLinks')}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={breakpointDownSm ? 0 : 2} item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <Typography variant='subtitle1' align={breakpointDownSm ? 'left' : 'right'}><strong title='Issue depends On'>{`Dependant Issue`}</strong>  </Typography>
                            </Grid>

                            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
                                <Autocomplete
                                    size='small'
                                    fullWidth
                                    multiple
                                    limitTags={4}
                                    id='create-issue-depends-on-links'
                                    options={issuesRef.data}
                                    onChange={onAutoCompleteSelect('dependsOnLinks')}
                                    getOptionLabel={(option) => option.issueNumber}
                                    getOptionSelected={(option, value) => option.issueNumber === value.issueNumber}
                                    value={formData.dependsOnLinks}
                                    noOptionsText={'Issues not found'}
                                    loading={issuesRef.wait}
                                    disabled={state.wait.forSubmit}
                                    loadingText={
                                        <Grid container spacing={2}>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                                <Skeleton
                                                    variant="rect"
                                                    animation='wave'
                                                    width={`90%`}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    renderOption={(option) => (
                                        <ListItem disableGutters dense className={classes.renderOption}>
                                            <ListItemText
                                                primary={option.issueNumber}
                                                primaryTypographyProps={{ variant: 'caption' }}
                                                secondary={option.title}
                                                secondaryTypographyProps={{ variant: 'subtitle1', color: 'initial' }}
                                                className={classes.renderOption}
                                            />
                                        </ListItem>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder='Link Issues by issue number or title'
                                            onChange={onInputChange('dependsOnLinks')}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={breakpointDownSm ? 0 : 2} item xl={12} lg={12} md={12} sm={12} xs={12} >
                            <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                                <Typography variant='subtitle1' align={breakpointDownSm ? 'left' : 'right'}><strong>{`Description`}</strong>  </Typography>
                            </Grid>

                            <Grid className={classes.mdEditor} item xl={10} lg={10} md={10} sm={12} xs={12}>

                                <MDEditor
                                    value={formData.desc}
                                    preview="edit"
                                    height={200}
                                    // extraCommands={[codePreview, commands.fullscreen]}
                                    onChangeCapture={onInputChange('desc')}
                                    placeholder='Insert issue description'
                                    highlightEnable={false}
                                    aria-disabled={state.wait.forSubmit}
                                    id='create-issue-desc'
                                    style={state.fieldError.desc !== '' ? { borderColor: theme.palette.error.main } : undefined}
                                />

                                <FormHelperText error={state.fieldError.desc !== ''}>
                                    {state.fieldError.desc}
                                </FormHelperText>
                            </Grid>

                        </Grid>

                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} justifyContent='center'>
                            <FormHelperText error={state.errorMessage !== ''}>
                                {state.errorMessage}
                            </FormHelperText>
                        </Grid>

                        <Grid container spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12} direction='row-reverse' alignContent='flex-end'>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    id='create-issue-button'
                                    onClick={onButtonClick('create')}
                                    disabled={state.wait.forSubmit}
                                    endIcon={state.wait.forSubmit && <CircularProgress color='primary' size={20} />}
                                >
                                    {'Create Issue'}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    size='small'
                                    id='reset-issue-button'
                                    onClick={onButtonClick('reset')}
                                    disabled={state.wait.forSubmit}
                                >
                                    {'Reset'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Container >
    )
}

export default CreateIssue;