
import { Dispatch } from "redux";
import { IssueType } from "../type";
import { Action } from '.'
import { IssueCreateForm } from "../../interface/issue-inteface";


export const setCreateIssueData = (data: IssueCreateForm) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: IssueType.SET_CREATE_ISSUE_DATA,
            payload: data
        });
    }
}