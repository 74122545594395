import {
  AppBar,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { FC, Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import theme from "../assets/theme/theme";
import { showTab } from "../store/actions/ui-actions";
import { logout } from "../store/actions/user-action";
import { RootState } from "../store/reducers";
import { TAB_TYPE } from "../utils/ui-constants";
import ChangePassword from "../view/login/change-password";
import { ExpandMore } from "@material-ui/icons";
import FeedbackIcon from '@material-ui/icons/Feedback';
const style = makeStyles({
  grow: { flexGrow: 1 },

  appBar: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  expandMore: {
    fontSize: "24px !important",
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  expandLess: {
    transform: "rotate(180deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  popover: {
    marginTop: 4,
  },
  button: {
    justifyContent: "space-between",
    color: "black",
    backgroundColor: "#fff",
  },
  marginAuto: {
    margin: "auto",
  },
  headerButton: {
    background: theme.palette.common.white,
    textTransform: "none",
  },
});

const authServerUrl = process.env.REACT_APP_AUTH_SERVER_URL;

const Header: FC = (props: any) => {
  const prevProps = useRef(props);
  const classes = style();
  const history = useHistory();
  const dispatch = useDispatch();
  const { username, roles } = useSelector(
    (state: RootState) => state.userReducer.userInfo
  );

  console.log("getroles", roles);

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAttendance = Boolean(anchorEl);
  const openAllServices = Boolean(anchorEl);

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeAllServices = () => {
    setAnchorEl(null);
  };

  const allServicesButtonClick = (tabValue: TAB_TYPE) => (event: any) => {
    console.log("role enter", roles);
    // if (!roles.includes(tabValue)) {
    //   // Redirect to 403 page if user does not have access
    //   history.push("/403");
    //   return;
    // }
    console.log("tab", tabValue);

    setAnchorEl(event.currentTarget);
  };

  const onTabButtonClick = (tabValue: TAB_TYPE) => (event: any) => {
    history.push("/workspace");
    dispatch(showTab(tabValue));
    closeAttendance();
    setAnchorEl(null);
  };

  const onAttendanceTabButtonClick = (tabValue: TAB_TYPE) => (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAttendance = () => {
    setAnchorEl(null);
  };

  const TeamAttendance = (tabValue: TAB_TYPE) => (event: any) => {
    history.push("/workspace");
    dispatch(showTab(tabValue));
    setAnchorEl(null);
  };

  const userAttendance = (tabValue: TAB_TYPE) => (event: any) => {
    history.push("/workspace");
    dispatch(showTab(tabValue));
    setAnchorEl(null);
  };

  const userActivity = (tabValue: TAB_TYPE) => (event: any) => {
    history.push("/workspace");
    dispatch(showTab(tabValue));
    setAnchorEl(null);
  };

  const teamPerformance = (tabValue: TAB_TYPE) => (event: any) => {
    history.push("/workspace");
    dispatch(showTab(tabValue));
    setAnchorEl(null);
  }


  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const onButtonClick = (name: string) => (event: any) => {
    switch (name) {
      case "login":
        history.push(`/redirect`);

        break;

      case "logout":
        history.push(`/login`);
        dispatch(logout());

        // let logoutURL = `${authServerUrl}/logout?response_type=${SECURIY_CONFIG.RESPONSE_TYPE}&client_id=${SECURIY_CONFIG.CLIENT_ID}&redirect_uri=${authServerUrl}/login&${SECURIY_CONFIG.SCOPE}=openid`;
        // let logoutURL = `${authServerUrl}/login`

        let logoutURL = `${authServerUrl}/login`;

        window.location.href = logoutURL;

        break;
    }
  };

  const handleChangePassword = (event: any) => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const CreateIssue = (tabValue: TAB_TYPE) => {
    history.push("/workspace");
    dispatch(showTab(tabValue));
    setAnchorEl(null);
  };

  return (
    <>
      <Grid id="header-wrapper">
        <AppBar
          position="fixed"
          color="primary"
          className={classes.appBar}
          id="header"
        >
          <Toolbar variant="dense">
            <Grid className={classes.grow}>
              <Typography variant="h6" color="inherit">
                {"Geomitra One"}
              </Typography>
            </Grid>

            {username && (
              <Fragment>
                <Button
                  size={"small"}
                  variant="contained"
                  color="inherit"
                  className={classes.button}
                  endIcon={<ExpandMore />}
                  onClick={allServicesButtonClick("NEW_ISSUE_TAB")}
                >
                  {`All Services`}
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openAllServices}
                  onClose={closeAllServices}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {roles && (roles.includes("MANAGER") || roles.includes("DEVELOPER") || roles.includes("REPORTER")) && (
                    <MenuItem
                      divider
                      onClick={() => CreateIssue("NEW_ISSUE_TAB")}
                    >
                      <i className="fas fa-bug dark" />
                      &nbsp; Create New Issue
                    </MenuItem>
                  )}

                  {roles && (roles.includes("MANAGER")) && (
                    <MenuItem
                      divider
                      onClick={onTabButtonClick("CRUD_OPERATION_TAB")}
                    >
                      <span className="material-icons dark">vpn_key</span>
                      &nbsp; Auth User
                    </MenuItem>
                  )}

                  {roles && (roles.includes("MANAGER")) && (
                    <MenuItem
                      divider
                      onClick={onTabButtonClick("USER_STATUS_LOG_TAB")}
                    >
                      <span className="material-icons dark">person_search</span>
                      &nbsp; Auth User Status Log
                    </MenuItem>
                  )}

                  {roles && (roles.includes("MANAGER") || roles.includes("DEVELOPER") || roles.includes("REPORTER")) && (
                    <MenuItem
                      divider
                      onClick={onTabButtonClick("ALL_ISSUES_TAB")}
                    >
                      <span className="material-icons dark">view_list</span>
                      &nbsp; All Issues
                    </MenuItem>
                  )}

                  {roles && (roles.includes("MANAGER") || roles.includes("REPORTER")) && (
                    <MenuItem
                      divider
                      onClick={onTabButtonClick("MILESTONE_TAB")}
                    >
                      <span className="material-icons dark">flag</span>
                      &nbsp; Milestone
                    </MenuItem>
                  )}

                  {roles  && (roles.includes("MANAGER") || roles.includes("REPORTER")) && (
                    <MenuItem divider onClick={onTabButtonClick("LABEL_TAB")}>
                      <span className="material-icons dark">local_offer</span>
                      &nbsp; Label
                    </MenuItem>
                  )}

                  {roles && (roles.includes("DEVELOPER") || roles.includes("REPORTER")) && (
                    <MenuItem
                      divider
                      onClick={userAttendance("USER_ATTENDANCE_TAB")}
                    >
                      <span className="material-icons dark">person</span>
                      &nbsp; User Attendance
                    </MenuItem>
                  )}

                  {roles && (roles.includes("MANAGER")) && (
                    <MenuItem
                      divider
                      onClick={TeamAttendance("TEAM_ATTENDANCE_TAB")}
                    >
                      <span className="material-icons dark">people_alt</span>
                      &nbsp; Team Attendance
                    </MenuItem>
                  )}

                  {roles && (roles.includes("MANAGER")) && (
                    <MenuItem
                      divider
                      onClick={userActivity("USER_ACTIVITY_TAB")}
                    >
                      <span className="material-icons dark">work_history</span>
                      &nbsp; User Activity
                    </MenuItem>
                  )}

{roles && (roles.includes("MANAGER")) && (
                  <MenuItem divider onClick={teamPerformance("TEAM_PERFORMANCE_TAB")}>
                    <FeedbackIcon className="dark" />
                    &nbsp; Team Performance
                  </MenuItem>
)}
                </Menu>
              </Fragment>
            )}
            <div className="m2 p2">&nbsp;</div>
            <div>
              <Button
                variant="contained"
                size="small"
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                endIcon={
                  <span className="material-icons">{`power_settings_new`}</span>
                }
                aria-haspopup="true"
                onClick={
                  username
                    ? handleToggle
                    : onButtonClick(username ? "logout" : "login")
                }
                className={classes.headerButton}
              >
                {username ? username : "Login"}
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem onClick={handleChangePassword}>
                            Change Password
                          </MenuItem>
                          <MenuItem
                            onClick={onButtonClick(
                              username ? "logout" : "login"
                            )}
                          >
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Toolbar>
          {openModal && (
            <ChangePassword
              open={openModal}
              handleClose={handleClose}
              username={username}
            />
          )}
        </AppBar>
      </Grid>
    </>
  );
};

export default Header;
