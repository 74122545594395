
// TAB VALUES
export enum TAB_VALUE {
    DASHBOARD_TAB = 'DASHBOARD_TAB',
    NEW_ISSUE_TAB = 'NEW_ISSUE_TAB',
    MILESTONE_TAB = 'MILESTONE_TAB',
    LABEL_TAB = 'LABEL_TAB',
    VIEW_ISSUE_INFO_TAB = 'VIEW_ISSUE_INFO_TAB',
    ALL_ISSUES_TAB = 'ALL_ISSUES_TAB',
    CRUD_OPERATION_TAB = 'CRUD_OPERATION_TAB',
    ISSUE_ACTIVITY_LOG_TAB = 'ISSUE_ACTIVITY_LOG_TAB',
    USER_STATUS_LOG_TAB = 'USER_STATUS_LOG_TAB',
      USER_ATTENDANCE_TAB = 'USER_ATTENDANCE_TAB',
    TEAM_ATTENDANCE_TAB = 'TEAM_ATTENDANCE_TAB',// NEW LINE ADDED
    USER_ACTIVITY_TAB =  'USER_ACTIVITY_TAB'// NEW LINE ADDED
   
};

export enum ALERT {
    SUCCESS = 'SUCCESS',
    DANGER = 'DANGER',
    WARNING = 'WARNING',
    INFO = 'INFO'
}

export enum ALERT_BUTTON_TYPE { BUTTON_PRIMARY = 'BUTTON_PRIMARY' }

export type TAB_TYPE = 'DASHBOARD_TAB' | 'NEW_ISSUE_TAB' | 'MILESTONE_TAB' | 'LABEL_TAB' | 'VIEW_ISSUE_INFO_TAB' | 'ALL_ISSUES_TAB' | 'CRUD_OPERATION_TAB'
    | 'ISSUE_ACTIVITY_LOG_TAB' | 'USER_STATUS_LOG_TAB' | 'USER_ATTENDANCE_TAB'  | 'TEAM_ATTENDANCE_TAB' | 'USER_ACTIVITY_TAB' | 'TEAM_PERFORMANCE_TAB'
    