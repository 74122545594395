import moment from "moment";

export const formatToDefault = (date: string) => {
    return moment(date).format("DD MMM, YYYY");
};

export const formatToDefaultWithTime = (date: string) => {
    return moment(date).format("DD MMM, YYYY HH:mm:ss a");
};

export const formatToFromNow = (date: string) => {
    return moment(date).fromNow();
};



