import { Button, Container, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, Fragment, useEffect, useState } from "react";
import { getLabels } from "../../apis/label-api";
import { PageRequest } from "../../interface/common-interface";
import { LabelInfo } from "../../interface/label-interface";
import { CreateLabel, EditLabel } from "../label/create-label-drawer";
import LabelTable from "../label/label-table";

const style = makeStyles({
    root: {
        display: 'contents'
    },
    milestoneContainer: {
        margin: 'auto'
    },
    milestoneTable: {
        display: 'block'
    },
    createLabel: {
        flex: 'auto'
    },
    shiftTableLeft: {
        marginRight: 350
    },
    shiftTableRight: {
        marginRight: '0px',
    }
});


const Label: FC = () => {

    const classes = style();

    const [state, setState] = useState({
        labels: [] as LabelInfo[],
        pageRequest: {
            page: 0,
            size: 20
        } as PageRequest,
        totalElements: 0,
        waitForLabels: true,
        errorMessage: ''
    });

    const [action, setAction] = useState('');
    const [labelInfo, setLabelInfo] = useState({} as any);

    useEffect(() => {
        fetchLabelData(state.pageRequest);
    }, []);

    const fetchLabelData = (pageRequest: PageRequest) => {

        getLabels({ ...pageRequest, page: pageRequest.page }).then((res: AxiosResponse<any>) => {
            setState({
                ...state,
                labels: res.data.content,
                pageRequest: {
                    page: res.data.number,
                    size: res.data.size
                },
                totalElements: res.data.totalElements,
                waitForLabels: false
            });
        }).catch((err: any) => {
            setState({
                ...state,
                waitForLabels: false,
                errorMessage: 'Error while getting labels!'
            });
        });
    }

    const onActionClick = (action: string, labelNumber: string | null, event: any) => {
        setAction(action);
        setLabelInfo({
            labelNumber
        });
    }

    const handlePagination = (pageRequest: PageRequest, event: any) => {
        fetchLabelData(pageRequest);
    }

    const toggleDrawer = () => {
        setAction('');
    }

    return (
        <Container id='label-wrapper' maxWidth='xl'>
            <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.root}>
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
                        <Typography variant="h4">{"Label"}</Typography>
                    </Grid>
                    <Grid container spacing={1} item xl={2} lg={4} md={4} sm={6} xs={12} direction='row-reverse' alignContent='flex-end'>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<span className="material-icons">add</span>}
                                size='small'
                                id='create-label-button'
                                onClick={(event: any) => onActionClick('CREATE', null, event)}
                            >
                                {'Create Label'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />
                            
                <Fragment>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.createLabel}>
                        <div className={action !== '' ? classes.shiftTableLeft : classes.shiftTableRight}>
                            <LabelTable
                                labels={state.labels}
                                waitForLabels={state.waitForLabels}
                                pageRequest={state.pageRequest}
                                totalElements={state.totalElements}
                                selectedLabel={labelInfo.labelNumber}
                                handlePagination={handlePagination}
                                onActionClick={onActionClick}
                                _fetchLabels={(event: any) => fetchLabelData(state.pageRequest)}
                            />
                        </div>
                    </Grid>
                    <div>
                        {action === 'CREATE' && <CreateLabel
                            drawerButton={action}
                            closeDialog={toggleDrawer}
                            _fetchLabels={(event: any) => fetchLabelData(state.pageRequest)}
                        />
                        }
                        {action === 'EDIT' && <EditLabel
                            drawerButton={action}
                            closeDialog={toggleDrawer}
                            _fetchLabels={(event: any) => fetchLabelData(state.pageRequest)}
                            labelNumber={labelInfo?.labelNumber}
                        />
                        }
                    </div>
                </Fragment>
            </Grid>
        </Container> 
    )

}

export default Label;