/**
 * To check field errors
 * @param {fields: any, values: any}
 * @returns {ErrorCheckResponse} - response
 * @author Aman Harde
 * @since 1.0
 */

const matchExpression = {
    email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
};

interface ErrorCheckResponse {
    errors: any,
    haError: boolean
}

export const checkFieldErrors = (fields: any, values: any, password?: any): ErrorCheckResponse => {

    const response: ErrorCheckResponse = {
        errors: {},
        haError: false
    };
    for (let field in fields) {
        var error = field === "confirmPassword" ? validateField(field, values["password"], password) : validateField(field, values[field], password);
        console.log(values,'hello',field)
        response.errors[field] = error;

        if (error !== '') {
            console.log(response,'response')
            response.haError = true
        }
    }


    return response;
}

export const validateField = (field: string, value: string, password: string) => {

    switch (field) {
        /* Login Fields */
        case 'username': return validateUserName(value);
        case 'password': return validatePassword(value);

        /* Milestone Fields */
        case 'codeName': return validateCodeName(value);
        case 'majorVersion': return validateMajorVersion(value);
        case 'minorVersion': return validateMinorVersion(value);
        case 'patchVersion': return validatePatchVersion(value);
        case 'projectRef': return validateProjectRef(value);
        case 'endDate': return validateEndDate(value);
        case 'startDate': return validateStartDate(value);
        case 'milestoneType': return validateMilestoneType(value);

        /* Label Fields */
        case 'value': return validateLabelValue(value);
        case 'name': return validateLabelName(value);

        /* Issues Fields */
        case 'title': return validateIssueTitle(value);
        case 'desc': return validateIssuedesc(value);

        /* Comment Fields */
        case 'text': return validateIssueCommentText(value);

        /* Auth User */
        case 'firstName': return validateFirstName(value);
        case 'lastName': return validateLastName(value);
        case 'authorities': return validateAuthorities(value);
        case 'confirmPassword': return validateConfirmPassword(value, password);
        case 'emailId': return validateEmailId(value);
        case 'phoneNo': return validatePhoneNumber(value);

        default: return '';
    }
}

export const validateFirstName = (value: string) => {
    if (value === "") {
        return 'Please insert first name'
    }
    return '';
}

export const validateLastName = (value: string) => {
    if (value === "") {
        return 'Please insert last name'
    }
    return '';
}

export const validateAuthorities = (value: string) => {
    if (value.length === 0) {
        return 'Please insert roles'
    }
    return '';
}

export const validateUserName = (value: string) => {
    if (value === "") {
        return 'Please insert user name'
    }
    return '';
}

export const validatePassword = (value: string) => {
    if (value === "") {
        return 'Please insert password'
    }
    return '';
}

export const validateConfirmPassword = (value: string, password: string) => {
    if (value !== password) {
        return 'Password needs to match'
    }
    return '';
}

export const validateCodeName = (value: string) => {
    if (value === "") {
        return 'Please insert code name'
    }
    return '';
}

export const validateMajorVersion = (value: string) => {
    if (value === "") {
        return 'Please insert major version'
    }
    return '';
}

export const validateMinorVersion = (value: string) => {
    if (value === "") {
        return 'Please insert minor version'
    }
    return '';
}
export const validatePatchVersion = (value: string) => {
    if (value === "") {
        return 'Please insert patch version'
    }
    return '';
}
export const validateProjectRef = (value: string) => {
    if (Object.keys(value).length === 0) {
        return 'Please select project reference'
    }
    return '';
}

export const validateStartDate = (value: string | null) => {
    if (!value) {
        return 'Please insert start date';
    }
    return '';
}

export const validateEndDate = (value: string | null) => {
    // End Date is optional, so no validation needed if it's not provided.
    return '';
}
export const validateLabelValue = (value: string) => {
    if (value === "") {
        return 'Please insert label value'
    }
    return '';
}
export const validateLabelName = (value: string) => {
    if (value === "") {
        return 'Please insert label name'
    }
    return '';
}
export const validateIssueTitle = (value: string) => {
    if (value === "") {
        return 'Please insert issue title'
    }
    return '';
}
export const validateIssuedesc = (value: string) => {
    if (value === "") {
        return 'Please insert issue desc'
    }
    return '';
}
export const validateIssueCommentText = (value: string) => {
    if (value === "") {
        return 'Please insert comment'
    }
    return '';
}
export const validateEmailId = (value: string) => {
    if (value) {
        if (!matchExpression.email.test(value)) {
            return 'Please insert valid email id'
        }
        return '';
    }

    return '';
}
export const validatePhoneNumber = (value: string) => {
    if (value === "") {
        return 'Please insert phone number'
    }
    return '';
}

export const validateMilestoneType = (value: string) => {
    if (value === "") {
        return 'Please Milestone Type'
    }
    return '';
}


