// /**
//  * This is view and update issue
//  * @author Aman Harde
//  * @since 1.0
//  */

import {
  Button,
  Chip,
  colors,
  FormHelperText,
  Grid,
  ListItemSecondaryAction,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import MDEditor from "@uiw/react-md-editor";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  FC,
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  deleteIssueFile,
  editIssue,
  editIssueAssignee,
  editIssueLabel,
  editIssueMilestone,
  fetchIssue,
  fetchIssuesRef,
  getIssueFiles,
  removeAllIssueLabel,
  removeIssueAssignee,
  removeIssueLabel,
  removeIssuesLinks,
  updateIssuesLinks,
  uploadIssueFiles,
  getFileUrl,
  copyIssue,
} from "../../apis/issue-api";
import { fetchLabels } from "../../apis/label-api";
import { fetchMileStonesByPatternAndStatus } from "../../apis/milestone-api";
import { getUsersByPattern } from "../../apis/user-api";
import theme from "../../assets/theme/theme";
import { Comment } from "../../interface/common-interface";
import { IssueInfo, IssueRef } from "../../interface/issue-inteface";
import { LabelInfo, LabelRef } from "../../interface/label-interface";
import { MilestoneRef } from "../../interface/milestone-interface";
import { AuthUser } from "../../interface/user-interface";
import { formatToFromNow } from "../../utils/date-formater";
import CommentHandler from "../comment/comment-handler";
import {
  FileInfo,
  FileUploadHandler,
  FileViwer,
  UPLOAD_STATUS,
} from "../../components/files";
import { ISSUE_STATE } from ".";
import { checkFieldErrors } from "../../utils/field-error-checker";
import { useDispatch } from "react-redux";
import { showTab } from "../../store/actions/ui-actions";
import {
  hideAlertModal,
  showAlertModal,
} from "../../../src/store/actions/ui-actions";
import { connect } from "react-redux";
import { ALERT } from "../../utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Container } from "@material-ui/core";

const style = makeStyles({
  root: {
    display: "contents",
  },
  milestoneContainer: {
    margin: "auto",
  },
  milestoneTable: {
    display: "block",
  },
  formWrapper: {
    marginTop: 0,
    marginRight: 0,
    margin: "auto",
  },
  popover: {
    marginTop: theme.spacing(0.5),
  },
  expandMore: {
    fontSize: `${theme.spacing(2)}px !important`,
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  expandLess: {
    fontSize: `${theme.spacing(2)}px !important`,
    transform: "rotate(180deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  buttonCopy: {
    fontSize: `${theme.spacing(2)}px !important`,
    marginRight: "10px",
  },
  issueInfoSubHead: {
    "& #issue-sub-info": {
      verticalAlign: "middle",
    },
    "& #chip-OPEN": {
      backgroundColor: "#2263dd",
      color: theme.palette.common.white,
    },
    "& #chip-CLOSED": {
      backgroundColor: colors.pink[100],
      color: theme.palette.common.white,
    },
    "& #chip-NEW": {
      backgroundColor: colors.blue[400],
      color: theme.palette.common.white,
    },
    "& #chip-ON_HOLD": {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.common.white,
    },
    "& #chip-RESOLVED": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.common.white,
    },
  },
  renderOption: {
    margin: 0,
    padding: 0,
  },
  list: {
    width: `100%`,
  },
  realtedLinksListItem: {
    backgroundColor: colors.grey[100],
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  secondaryAction: {
    right: 0,
  },
  buttonOpen: {
    backgroundColor: "#2263dd",
    color: "white",
    "&:hover": {
      backgroundColor: "#2263dd",
      color: "white",
    },
  },
  buttonClose: {
    backgroundColor: colors.pink[100],
    color: "white",
    "&:hover": {
      backgroundColor: colors.pink[100],
      color: "white",
    },
  },
  buttonResolve: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: "white",
    },
  },
  buttonOnHold: {
    backgroundColor: theme.palette.warning.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
      color: "white",
    },
  },
});

const drawerWidth = 550;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: "1000",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  menuButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    // marginTop: theme.spacing(1), // Adjust this value as needed
    // paddingRight: theme.spacing(1), // Adjust this value as needed
  },
}));

interface ViewIssueProps {
  readonly issueNumber: string;
  showAlertModal: (alertData: any) => void;
  hideAlertModal: () => void;
}

const ViewIssue: FC<ViewIssueProps> = (props) => {
  const classes1 = useStyles();
  const classes = style();
  const theme = useTheme();
  const dispatch = useDispatch();
  const cancelSource: MutableRefObject<any> = useRef(null);

  const [openDrawer, setDrawerOpen] = React.useState(false);

  const [issueInfo, setIssueInfo] = useState({
    data: {
      assignees: [] as AuthUser[],
      assignor: {} as AuthUser,
      closeDate: "",
      comments: [] as Comment[],
      createdBy: {} as AuthUser,
      createdDate: "",
      dependsOnLinks: [] as IssueRef[],
      desc: "",
      files: [] as FileInfo[],
      issueNumber: "",
      milestone: {} as MilestoneRef,
      relatedLinks: [] as IssueRef[],
      state: "",
      labels: [] as LabelRef[],
      title: "",
    } as IssueInfo,
    wait: true,
  });

  const [edit, setEdit] = useState({
    desc: false,
    assignees: false,
    milestone: false,
    labels: false,
    relatedLinks: false,
    dependsOnLinks: false,
  });

  const [errors, setErrors] = useState({
    title: "",
    desc: "",
    assignees: "",
    milestone: "",
    labels: "",
    descEdit: "",
    common: "",
  });

  const [editContent, setEditContent] = useState({
    title: "",
    desc: "",
  });

  const [isCommentBoxEnabled, setCommentBoxEnabled] = useState(false);

  const [displayStateOptions, setDisplayStateOptions] = useState(null);

  const [uploadingInfo, setUploadingInfo] = useState({
    files: [] as any[],
    progress: 0 as number,
    status: "" as UPLOAD_STATUS,
    errorMessage: "",
  });

  const [authUsers, setAuthUsers] = useState({
    data: [] as AuthUser[],
    wait: false,
  });
  const [milestones, setMilestones] = useState({
    data: [] as MilestoneRef[],
    wait: false,
  });
  const [_labels, setLabels] = useState({
    data: [] as LabelRef[],
    wait: false,
  });
  const [issuesRef, setIssuesRef] = useState({
    data: [] as IssueRef[],
    wait: false,
  });
  const MILESTONE_STATUS = ["OPEN"];

  useEffect(() => {
    _fetchIssue(props.issueNumber);
  }, [props.issueNumber]);

  const _fetchIssue = (issueNumber: string) => {
    fetchIssue(issueNumber)
      .then((res: AxiosResponse<any>) => {
        setIssueInfo({
          ...issueInfo,
          data: res.data,
          wait: false,
        });
        setEditContent(res.data);
      })
      .catch((err: any) => {
        setIssueInfo({
          ...issueInfo,
          wait: false,
        });
        setErrors({
          ...errors,
          common: "Error while getting issue. Please try again",
        });
      });
  };

  const onEditClick = (name: string) => (event: any) => {
    setEdit({
      ...edit,
      [name]: true,
    });
  };

  const onAutoCompleteBlur = (field: string) => (event: any) => {
    setEdit({
      ...edit,
      [field]: false,
    });
  };

  const handelEdit = (name: string) => (event: any) => {
    switch (name.split("-")[0]) {
      case "cancel":
        setEdit({
          ...edit,
          [name.split("-")[1]]: false,
        });
        setEditContent(issueInfo.data);
        setErrors({
          ...errors,
          title: "",
          desc: "",
        });
        break;
      case "save":
        saveChanges(name.split("-")[1]);
        break;
      case "state":
        changeIssueState(name.split("-")[1]);
        setDisplayStateOptions(null);
        break;
      default:
        break;
    }
  };

  const fetchAuthUsers = (event: any) => {
    setAuthUsers({
      ...authUsers,
      wait: true,
    });

    getUsersByPattern({ authUser: event.target.value })
      .then((res: AxiosResponse<any>) => {
        setAuthUsers({
          ...authUsers,
          data: res.data,
          wait: false,
        });
      })
      .catch((err: any) => {
        setAuthUsers({
          ...authUsers,
          wait: false,
        });
      });
  };

  const fetchMilestones = (event: any) => {
    setMilestones({
      ...milestones,
      wait: true,
    });

    fetchMileStonesByPatternAndStatus({
      pattern: event.target.value,
      status: MILESTONE_STATUS.reduce((f, s) => `${f},${s}`),
    })
      .then((res: AxiosResponse<any>) => {
        setMilestones({
          ...milestones,
          data: res.data,
          wait: false,
        });
      })
      .catch((err: any) => {
        setMilestones({
          ...milestones,
          wait: false,
        });
      });
  };

  const _fetchLabels = (event: any) => {
    setLabels({
      ..._labels,
      wait: true,
    });

    fetchLabels({ value: event.target.value })
      .then((res: AxiosResponse<any>) => {
        setLabels({
          ..._labels,
          data: res.data.map((label: LabelInfo) => {
            return { value: label.value } as LabelRef;
          }),
          wait: false,
        });
      })
      .catch((err: any) => {
        setLabels({
          ..._labels,
          wait: false,
        });
      });
  };

  const _fetchIssuesRef = (event: any) => {
    setIssuesRef({
      ...issuesRef,
      wait: true,
    });

    fetchIssuesRef({ pattern: event.target.value })
      .then((res: AxiosResponse<any>) => {
        setIssuesRef({
          ...issuesRef,
          data: res.data,
          wait: false,
        });
      })
      .catch((err: any) => {
        setIssuesRef({
          ...issuesRef,
          wait: false,
        });
      });
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCopyIssueDialogClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    props.showAlertModal({
      heading: "Confirmation",
      description: `Do you want to create copy issue?`,
      actionButtons: [
        {
          title: "Yes",
          callback: (event: any) => {
            _copyIssue(event);
            props.hideAlertModal();
            handleClose();
          },
        },

        {
          title: "No",
          callback: () => {
            props.hideAlertModal();
            handleClose();
          },
        },
      ],
      variant: ALERT.WARNING,
    });
  };

  const _copyIssue = (event: any) => {
    const newissueNumber = issueInfo.data.issueNumber;

    setIssueInfo({
      ...issueInfo,
      wait: false,
    });

    copyIssue({ issueNumber: newissueNumber })
      .then((res: AxiosResponse<any>) => {
        const newissueNumber = issueInfo.data.issueNumber;
        setIssueInfo({
          ...issueInfo,
          data: {
            ...issueInfo.data,
            issueNumber: newissueNumber,
          },
          wait: false,
        });
        if (res.status == 201) {
          props.showAlertModal({
            heading: "Success",
            description: `Issue Copied Sucessfully!`,
            actionButtons: [
              {
                title: "Close",
                callback: (event: any) => {
                  props.hideAlertModal();
                  handleClose();
                },
              },
            ],
            variant: ALERT.SUCCESS,
          });
        } else {
          props.showAlertModal({
            heading: "Error",
            description: "Could not copy issue",
            actionButtons: [
              {
                title: "Close",
                callback: (event: any) => {
                  props.hideAlertModal();
                },
              },
            ],
            variant: ALERT.DANGER,
          });
        }
      })
      .catch((err: any) => {
        props.showAlertModal({
          heading: "Error",
          description: "Could not copy issue",
          actionButtons: [
            {
              title: "Close",
              callback: (event: any) => props.hideAlertModal(),
            },
          ],
          variant: ALERT.DANGER,
        });
        setIssueInfo({
          ...issueInfo,
          wait: false,
        });
      });
  };

  const uploadFiles = (files: any[]) => {
    cancelSource.current = axios.CancelToken.source();

    setUploadingInfo({
      ...uploadingInfo,
      files: Array.from(files),
    });

    const config: AxiosRequestConfig = {
      ...(uploadIssueFiles(props.issueNumber, files) as AxiosRequestConfig),
      onUploadProgress: (progress: any) => {
        setUploadingInfo({
          ...uploadingInfo,
          status: UPLOAD_STATUS.IN_PROGRESS,
          progress: Math.floor((progress.loaded / progress.total) * 100),
        });
      },
      cancelToken: cancelSource.current.token,
    };

    axios
      .request(config)
      .then((response: AxiosResponse<any>) => {
        _fetchIssue(props.issueNumber);
        setUploadingInfo({
          ...uploadingInfo,
          progress: 0,
          files: [],
          status: UPLOAD_STATUS.SUCCESS,
        });
      })
      .catch((error) => {
        setUploadingInfo({
          ...uploadingInfo,
          errorMessage: "Server error while uploading file... Please try again",
          status: UPLOAD_STATUS.CANCELLED,
        });
      });
  };

  const onCancelUpload = (event: any) => {
    cancelSource.current.cancel("User has canceled the file upload.");

    setUploadingInfo({
      ...uploadingInfo,
      status: UPLOAD_STATUS.CANCELLED,
      progress: 0,
    });
  };

  const onInputChange = (name: string) => (event: any) => {
    switch (name) {
      case "assignees":
        fetchAuthUsers(event);
        break;

      case "milestone":
        fetchMilestones(event);
        break;

      case "labels":
        _fetchLabels(event);
        break;

      case "relatedLinks":
      case "dependsOnLinks":
        _fetchIssuesRef(event);
        break;

      case "files":
        uploadFiles(event.target.files);
        break;

      default:
        setEditContent({
          ...editContent,
          [name]: event.target.value,
        });
        setErrors({
          ...errors,
          [name]: "",
          descEdit: "",
        });

        break;
    }
  };

  const onAutoCompleteSelect =
    (field: string) =>
    (event: any, newValue: any, reason: any, details: any) => {
      switch (field) {
        case "assignees":
          handelAssigness(event, reason, details);
          break;
        case "milestone":
          handelMilestone(event, reason, details);
          break;
        case "labels":
          handelLabel(event, reason, details);
          break;
        case "relatedLinks":
          handelRelatedLinks(event, reason, { relatedLink: details.option });
          break;
        case "dependsOnLinks":
          handelRelatedLinks(event, reason, { dependsOnLink: details.option });
          break;
      }
    };

  const onDeleteChip = (field: string, value: any) => (event: any) => {
    event.stopPropagation();

    switch (field) {
      case "assignees":
        handelAssigness(event, "remove-option", value);
        break;

      case "milestone":
        handelMilestone(event, "remove-option", value);
        break;

      case "labels":
        handelLabel(event, "remove-option", value);
        break;

      case "relatedLinks":
        handelRelatedLinks(event, "remove-option", { relatedLink: value });
        break;

      case "dependsOnLinks":
        handelRelatedLinks(event, "remove-option", { dependsOnLink: value });
        break;
    }
  };

  const handelAssigness = (event: any, reason: any, details: any) => {
    switch (reason) {
      case "select-option":
        editIssueAssignee(props.issueNumber, { authUser: details.option })
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;

      case "remove-option":
        removeIssueAssignee(props.issueNumber, { authUser: details })
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;
    }
  };

  const handelMilestone = (event: any, reason: any, details: any) => {
    switch (reason) {
      case "select-option":
        editIssueMilestone(props.issueNumber, { milestoneRef: details.option })
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;

      case "remove-option":
      case "clear":
        editIssueMilestone(props.issueNumber, { milestoneRef: null })
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;
    }
  };

  const handelLabel = (event: any, reason: any, details: any) => {
    switch (reason) {
      case "select-option":
        editIssueLabel(props.issueNumber, { label: details.option })
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;

      case "remove-option":
        removeIssueLabel(props.issueNumber, { label: details })
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;

      case "clear":
        removeAllIssueLabel(props.issueNumber)
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;
    }
  };

  const handelRelatedLinks = (event: any, reason: any, details: any) => {
    switch (reason) {
      case "select-option":
        updateIssuesLinks(props.issueNumber, details)
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;

      case "remove-option":
        removeIssuesLinks(props.issueNumber, details)
          .then((res: AxiosResponse<any>) => {
            _fetchIssue(props.issueNumber);
          })
          .catch((err: any) => {});
        break;
    }
  };

  const changeIssueState = (state: string) => {
    editIssue(props.issueNumber, { state })
      .then((res: AxiosResponse<any>) => {
        _fetchIssue(props.issueNumber);
      })
      .catch((err: any) => {});
  };

  const deleteFile = (fileInfo: FileInfo, event: any) => {
    deleteIssueFile(props.issueNumber, { filename: fileInfo.internalName })
      .then((res: AxiosResponse<any>) => {
        _fetchIssue(props.issueNumber);
      })
      .catch((err: any) => {
        window.alert("Error while deleting file. Please try again.");
      });
  };

  const downloadFile = (fileInfo: FileInfo, event: any) => {
    getFileUrl(props.issueNumber, fileInfo.internalName)
      .then((res: AxiosResponse<any>) => {
        const fileUrl = res.data;

        // Calculate the center position
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const popupWidth = 1000; // Adjust this width as needed
        const popupHeight = 600; // Adjust this height as needed
        const left = (screenWidth - popupWidth) / 2;
        const top = (screenHeight - popupHeight) / 2;

        const popupWindow = window.open(
          fileUrl,
          "_blank",
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        if (!popupWindow) {
          // Handle popup blocker
          window.alert(
            "The popup window was blocked by the browser. Please allow popups for this site."
          );
        } else {
          popupWindow.focus();
          _fetchIssue(props.issueNumber);
        }
      })
      .catch((err: any) => {
        window.alert("Error while downloading file. Please try again.");
      });
  };
  //In this code, we calculate the left and top positions based on the screen dimensions and the desired dimensions of the popup window. Adjust the popupWidth and popupHeight variables to set the size of the popup window as needed. The left and top values are used in the window.open method to position the popup window in the center of the screen.

  const validateFields = (): boolean => {
    let fieldError = checkFieldErrors(errors, editContent);
    setErrors({
      ...errors,
      ...fieldError.errors,
    });

    return fieldError.haError;
  };

  const saveChanges = (name: string) => {
    switch (name) {
      case "desc":
        if (!validateFields()) {
          editIssue(props.issueNumber, { ...editContent })
            .then((res: AxiosResponse<any>) => {
              _fetchIssue(props.issueNumber);
              setEdit({
                ...edit,
                [name]: false,
              });
              setErrors({
                ...errors,
                descEdit: "",
              });
            })
            .catch((err: any) => {
              setErrors({
                ...errors,
                descEdit: "Error while edit issue. Please try again.",
              });
            });
        }

        break;
    }
  };

  const showStateOptions = (show: boolean) => (event: any) => {
    if (show) {
      setDisplayStateOptions(event.currentTarget);
    } else {
      setDisplayStateOptions(null);
    }
  };

  const onIssueClick = (issue: IssueRef) => (event: any) => {
    dispatch(
      showTab("VIEW_ISSUE_INFO_TAB", { issueNumber: issue.issueNumber })
    );
  };

  const onActivityStatusLogClick = (issueNumber: any) => (event: any) => {
    dispatch(showTab("ISSUE_ACTIVITY_LOG_TAB", { issueNumber: issueNumber }));
  };

  const handleAddComment = () => {
    setCommentBoxEnabled(true);
  };

  const StateOptions = () => {
    return (
      <Popover
        open={displayStateOptions !== null}
        anchorEl={displayStateOptions}
        onClose={showStateOptions(false)}
        className={classes.popover}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List dense disablePadding={true}>
          {issueInfo.data.state === ISSUE_STATE.OPEN && (
            <Fragment>
              <ListItem
                className={classes.buttonResolve}
                button
                onClick={handelEdit(`state-${ISSUE_STATE.RESOLVED}`)}
                id="resolve-issue-state"
              >
                <ListItemText primary="Resolved Issue" />
              </ListItem>
              <ListItem
                className={classes.buttonOnHold}
                button
                onClick={handelEdit(`state-${ISSUE_STATE.ON_HOLD}`)}
                id="on-hold-issue-state"
              >
                <ListItemText primary="On-hold Issue" />
              </ListItem>
            </Fragment>
          )}
          {(issueInfo.data.state === ISSUE_STATE.RESOLVED ||
            issueInfo.data.state === ISSUE_STATE.ON_HOLD) && (
            <ListItem
              className={classes.buttonOpen}
              button
              onClick={handelEdit(`state-${ISSUE_STATE.OPEN}`)}
              id="open-issue-state"
            >
              <ListItemText primary="Open Issue" />
            </ListItem>
          )}
          {(issueInfo.data.state === ISSUE_STATE.RESOLVED ||
            issueInfo.data.state === ISSUE_STATE.ON_HOLD) && (
            <ListItem
              className={classes.buttonClose}
              button
              onClick={handelEdit(`state-${ISSUE_STATE.CLOSED}`)}
              id="close-issue-state"
            >
              <ListItemText primary="Close Issue" />
            </ListItem>
          )}
        </List>
      </Popover>
    );
  };

  const Buttons = (name: string) => {
    return (
      <Grid
        container
        spacing={1}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction="row-reverse"
        alignContent="flex-end"
      >
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            name={`save-${name}`}
            id="save-change-button"
            onClick={handelEdit(`save-${name}`)}
            /*   disabled={state.wait.forSubmit}
                            endIcon={state.wait.forSubmit && <CircularProgress color='primary' size={20} />} */
          >
            {"Save"}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="small"
            id="cancel-change-button"
            name={`cancel-${name}`}
            onClick={handelEdit(`cancel-${name}`)}
            //   disabled={state.wait.forSubmit}
          >
            {"Cancel"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  // const handleDrawerOpen = () => {
  //   setDrawerOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setDrawerOpen(false);
  // };

  const toggleDrawer = () => {
    setDrawerOpen(!openDrawer);
  };

  return (
    <Fragment>
      {errors.common === "" ? (
        <Container id="issue-wrapper" maxWidth="xl">
          <Grid
            container
            spacing={3}
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.root}
          >
           

            <div className={classes1.root}>
              <CssBaseline />

              <main
                className={clsx(classes1.content, {
                  [classes1.contentShift]: openDrawer,
                })}
              >
                <div
                  className={classes1.drawerHeader}
                  style={{ marginTop: "-80px" }}
                />
                 <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              id="issue-head"
            >
              <Grid
                container
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    display="inline"
                    variant="h4"
                    id="issue-number"
                  >{`${issueInfo.data.issueNumber}`}</Typography>
                </Grid>
                <Grid
                  item
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <IconButton
                    size="small"
                    color="primary"
                    title="View Activity Info"
                    onClick={onActivityStatusLogClick(
                      `${issueInfo.data.issueNumber}`
                    )}
                  >
                    <span className="material-icons">info</span>
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.buttonCopy}
                    onClick={handleClickOpen}
                    id="issue-state-list"
                  >
                    {`Copy Issue`}
                  </Button>
                  {(issueInfo.data.state === ISSUE_STATE.NEW ||
                    issueInfo.data.state === ISSUE_STATE.CLOSED) && (
                    <Button
                      variant="contained"
                      className={classes.buttonOpen}
                      size="small"
                      onClick={handelEdit(`state-${ISSUE_STATE.OPEN}`)}
                      id="open-issue-state"
                    >
                      {`Open Issue`}
                    </Button>
                  )}
                  {(issueInfo.data.state === ISSUE_STATE.RESOLVED ||
                    issueInfo.data.state === ISSUE_STATE.OPEN ||
                    issueInfo.data.state === ISSUE_STATE.ON_HOLD) && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      endIcon={
                        <i
                          className={clsx(
                            displayStateOptions !== null
                              ? classes.expandMore
                              : classes.expandLess,
                            "fas fa-chevron-down"
                          )}
                        />
                      }
                      onClick={showStateOptions(true)}
                      id="issue-state-list"
                      // style={{ marginLeft: '10px' }}
                    >
                      {`Status`}
                    </Button>
                  )}
                  &nbsp;
                  &nbsp;
                  
                  <div className={classes1.menuButtonContainer}>
                    <Tooltip title="Open Drawer" arrow>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        size="small"
                      >
                        <MenuIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.issueInfoSubHead}
              >
                <Chip
                  variant="default"
                  color="primary"
                  size="small"
                  label={<strong>{issueInfo.data.state}</strong>}
                  id={`chip-${issueInfo.data.state}`}
                />
                <Typography
                  display="inline"
                  variant="subtitle1"
                  align="center"
                  id="issue-sub-info"
                >
                  {` Created ${formatToFromNow(
                    issueInfo.data.createdDate
                  )} by `}{" "}
                  <strong>{issueInfo.data.createdBy.username}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Divider />

                <div>
                  <Container maxWidth="xl" id="issue-info-wrapper">
                    <Grid
                      container
                      spacing={3}
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.milestoneContainer}
                    >
                      <Grid
                        container
                        spacing={2}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.milestoneTable}
                      >
                        {!edit.desc ? (
                          <Grid
                            container
                            spacing={2}
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Grid
                              container
                              spacing={0}
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              id="issue-title"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography variant="h4" id="title">
                                  {issueInfo.data.title}
                                </Typography>
                              </Grid>

                              <Grid item>
                                <IconButton
                                  edge="end"
                                  id="edit-desc-button"
                                  name="desc"
                                  onClick={onEditClick("desc")}
                                  disabled={
                                    issueInfo.data.state ===
                                      ISSUE_STATE.CLOSED || edit.desc
                                  }
                                >
                                  <span className="material-icons">edit</span>
                                </IconButton>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              id="description"
                              style={{ overflow: 'auto', maxHeight: '400px' }}
                            >
                              <div id="description-view">
                                <MDEditor.Markdown
                                  source={issueInfo.data.desc}
                                  style={{ wordBreak: 'break-word' }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            spacing={2}
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <TextField
                                variant="outlined"
                                size="small"
                                disabled={
                                  issueInfo.data.state === ISSUE_STATE.CLOSED
                                }
                                fullWidth
                                label="Issue Title"
                                placeholder="Insert issue title"
                                id="edit-issue-title"
                                name="title"
                                value={editContent.title}
                                onChange={onInputChange("title")}
                                error={errors.title !== ""}
                                helperText={errors.title}
                              />
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <MDEditor
                                value={editContent.desc}
                                onChangeCapture={onInputChange("desc")}
                                preview={"edit"}
                                toolbarHeight={40}
                                minHeight={200}
                                maxHeight={600}
                                placeholder="Insert issue description"
                                id="edit-issue-desc"
                                style={
                                  errors.desc !== ""
                                    ? { borderColor: theme.palette.error.main }
                                    : undefined
                                }
                              />

                              <FormHelperText error={errors.desc !== ""}>
                                {errors.desc}
                              </FormHelperText>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <FormHelperText error={errors.descEdit !== ""}>
                                {errors.descEdit}
                              </FormHelperText>
                            </Grid>
                            {Buttons("desc")}
                          </Grid>
                        )}

                        <Divider />

                        <Divider />

                        {/* <Grid
                    container
                    spacing={2}
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    id="comment-wrapper"
                  >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="h5">{`Comments`}</Typography>
                    </Grid>
                    <CommentHandler
                      issueNumber={props.issueNumber}
                      disableComment={issueInfo.data.state === "CLOSED"}
                      comments={issueInfo.data.comments}
                      wait={issueInfo.wait}
                      _fetchIssue={(event: any) =>
                        _fetchIssue(props.issueNumber)
                      }
                    />
                  </Grid> */}

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="h5">
                            {`Comments`}{" "}
                            <Button
                              size="small"
                              color="primary"
                              title="Add Comment"
                              variant="contained"
                              // className={classes.addCommentButton}
                              onClick={handleAddComment}
                            >
                              <span className="material-icons">add</span>{" "}
                            </Button>
                          </Typography>
                        </Grid>

                        <CommentHandler
                          issueNumber={props.issueNumber}
                         // disableComment={issueInfo.data.state === "CLOSED"}
                          comments={issueInfo.data.comments}
                          wait={issueInfo.wait}
                          _fetchIssue={(event: any) =>
                            _fetchIssue(props.issueNumber)
                          }
                          onClose={() => setCommentBoxEnabled(false)}
                          openCommentBox={isCommentBoxEnabled}
                          handleAddComment={handleAddComment}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </main>

              <Drawer
                className={classes1.drawer}
                variant="persistent"
                anchor="right"
                open={openDrawer}
                classes={{
                  paper: classes1.drawerPaper,
                }}
              >
                <Divider />

                <List style={{ marginTop: "50px" }}>
                  {/* Adjust the margin-top value as needed */}
                  <div className={classes1.drawerHeader}>
                     
                  </div>
                  
                  <ListItem>
                    <Grid
                      container
                      spacing={2}
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={0}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        id="issue-title"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="subtitle1" id="assignees">
                            <strong>{`Assignees (${issueInfo.data.assignees.length}):`}</strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            id="edit-assignees-button"
                            name="assignees"
                            onClick={onEditClick("assignees")}
                            disabled={
                              issueInfo.data.state === ISSUE_STATE.CLOSED ||
                              edit.assignees
                            }
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>
                        </Grid>
                      </Grid>
                      {!edit.assignees ? (
                        <Grid item>
                          {issueInfo.data.assignees.length !== 0 ? (
                            issueInfo.data.assignees.map((user, index) => (
                              <Chip
                                variant={
                                  issueInfo.data.state !== ISSUE_STATE.CLOSED
                                    ? "outlined"
                                    : "default"
                                }
                                color={
                                  issueInfo.data.state !== ISSUE_STATE.CLOSED
                                    ? "primary"
                                    : undefined
                                }
                                key={index}
                                label={user.username}
                                onDelete={
                                  issueInfo.data.state !== ISSUE_STATE.CLOSED
                                    ? onDeleteChip("assignees", user)
                                    : undefined
                                }
                              />
                            ))
                          ) : (
                            <Typography variant="caption">{`None`}</Typography>
                          )}
                        </Grid>
                      ) : (
                        <Fragment>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple
                              limitTags={4}
                              disableClearable
                              id="edit-issue-assignees"
                              options={authUsers.data}
                              value={issueInfo.data.assignees}
                              disabled={
                                issueInfo.data.state === ISSUE_STATE.CLOSED
                              }
                              onChange={onAutoCompleteSelect("assignees")}
                              onBlur={onAutoCompleteBlur("assignees")}
                              getOptionLabel={(option: AuthUser) =>
                                option.username
                              }
                              noOptionsText={"Users not found"}
                              loading={authUsers.wait}
                              loadingText={
                                <Grid container spacing={2}>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                </Grid>
                              }
                              renderTags={(value) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={index}
                                    variant="default"
                                    label={option.username}
                                    size="small"
                                    onDelete={() => {
                                      issueInfo.data.state !==
                                        ISSUE_STATE.CLOSED &&
                                        onDeleteChip("assignees", option);
                                    }}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  disabled={
                                    issueInfo.data.state === ISSUE_STATE.CLOSED
                                  }
                                  variant="outlined"
                                  label="Assignees"
                                  placeholder="Select Assignees"
                                  name="assignees"
                                  onChange={onInputChange("assignees")}
                                />
                              )}
                            />
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid
                      item
                      container
                      spacing={2}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={0}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        id="issue-title"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="subtitle1" id="milestone">
                            <strong>{`Milestone`}</strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            id="edit-milestone-button"
                            name="milestone"
                            onClick={onEditClick("milestone")}
                            disabled={
                              issueInfo.data.state === ISSUE_STATE.CLOSED ||
                              edit.milestone
                            }
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>
                        </Grid>
                      </Grid>
                      {!edit.milestone ? (
                        issueInfo.data.milestone !== null && (
                          <Grid item>
                            <Chip
                              variant={
                                issueInfo.data.state !== ISSUE_STATE.CLOSED
                                  ? "outlined"
                                  : "default"
                              }
                              color={
                                issueInfo.data.state !== ISSUE_STATE.CLOSED
                                  ? "primary"
                                  : undefined
                              }
                              label={`${issueInfo.data.milestone?.projectRef?.value} : [ ${issueInfo.data.milestone.version} ]`}
                              onDelete={
                                issueInfo.data.state !== ISSUE_STATE.CLOSED
                                  ? onDeleteChip(
                                      "milestone",
                                      issueInfo.data.milestone
                                    )
                                  : undefined
                              }
                            />
                          </Grid>
                        )
                      ) : (
                        <Fragment>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple={false}
                              disabled={
                                issueInfo.data.state === ISSUE_STATE.CLOSED
                              }
                              id="edit-issue-milestone"
                              options={milestones.data}
                              value={issueInfo.data.milestone}
                              onChange={onAutoCompleteSelect("milestone")}
                              onBlur={onAutoCompleteBlur("milestone")}
                              getOptionLabel={(option: MilestoneRef) =>
                                `${option.projectRef.value} : [ ${option.version} ]`
                              }
                              noOptionsText={"Milestones not found"}
                              loading={milestones.wait}
                              loadingText={
                                <Grid container spacing={2}>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                </Grid>
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  disabled={
                                    issueInfo.data.state === ISSUE_STATE.CLOSED
                                  }
                                  label="Milestone"
                                  placeholder="Insert milestone"
                                  name="milestone"
                                  onChange={onInputChange("milestone")}
                                />
                              )}
                            />
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid
                      item
                      container
                      spacing={2}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={0}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        id="issue-title"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="subtitle1" id="labels">
                            <strong>{`Labels (${issueInfo.data.labels.length}):`}</strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            id="edit-labels-button"
                            name="labels"
                            onClick={onEditClick("labels")}
                            disabled={
                              issueInfo.data.state === ISSUE_STATE.CLOSED ||
                              edit.labels
                            }
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>
                        </Grid>
                      </Grid>
                      {!edit.labels ? (
                        <Grid item>
                          {issueInfo.data.labels.length !== 0 ? (
                            issueInfo.data.labels.map((label, index) => (
                              <Chip
                                variant={
                                  issueInfo.data.state !== ISSUE_STATE.CLOSED
                                    ? "outlined"
                                    : "default"
                                }
                                color={
                                  issueInfo.data.state !== ISSUE_STATE.CLOSED
                                    ? "primary"
                                    : undefined
                                }
                                key={index}
                                label={label.value}
                                onDelete={
                                  issueInfo.data.state !== ISSUE_STATE.CLOSED
                                    ? onDeleteChip("labels", label)
                                    : undefined
                                }
                              />
                            ))
                          ) : (
                            <Typography variant="caption">{`None`}</Typography>
                          )}
                        </Grid>
                      ) : (
                        <Fragment>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple
                              limitTags={4}
                              id="edit-issue-labels"
                              options={_labels.data}
                              value={issueInfo.data.labels}
                              disabled={
                                issueInfo.data.state === ISSUE_STATE.CLOSED
                              }
                              onChange={onAutoCompleteSelect("labels")}
                              onBlur={onAutoCompleteBlur("labels")}
                              getOptionLabel={(option: LabelRef) =>
                                option.value
                              }
                              noOptionsText={"Labels not found"}
                              loading={_labels.wait}
                              loadingText={
                                <Grid container spacing={2}>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                </Grid>
                              }
                              renderTags={(value) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={index}
                                    variant="default"
                                    label={option.value}
                                    size="small"
                                    onDelete={onDeleteChip("labels", option)}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  disabled={
                                    issueInfo.data.state === ISSUE_STATE.CLOSED
                                  }
                                  label="Labels"
                                  placeholder="Select labels"
                                  name="labels"
                                  onChange={onInputChange("labels")}
                                />
                              )}
                            />
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid
                      item
                      container
                      spacing={2}
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={0}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        id="files-title"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="subtitle1" id="files">
                            <strong>{`Files (${issueInfo.data.files.length}):`}</strong>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FileViwer
                          files={issueInfo.data.files}
                          deleteFile={deleteFile}
                          downloadFile={downloadFile}
                        />
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FileUploadHandler
                          onChange={onInputChange("files")}
                          disableFileUpload={issueInfo.data.state === "CLOSED"}
                          onCancelUpload={onCancelUpload}
                          files={uploadingInfo.files}
                          progress={uploadingInfo.progress}
                          errorMessage={uploadingInfo.errorMessage}
                          status={uploadingInfo.status}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid
                      container
                      spacing={2}
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={0}
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        id="issue-title"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography variant="subtitle1" id="related-links">
                            <strong>{`Related Issue (${issueInfo.data.relatedLinks.length}):`}</strong>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            id="edit-related-links-button"
                            name="relatedLinks"
                            onClick={onEditClick("relatedLinks")}
                            disabled={
                              issueInfo.data.state === ISSUE_STATE.CLOSED ||
                              edit.relatedLinks
                            }
                          >
                            <span className="material-icons">edit</span>
                          </IconButton>
                        </Grid>
                      </Grid>
                      {!edit.relatedLinks ? (
                        <List className={classes.list}>
                          {issueInfo.data.relatedLinks.length !== 0 ? (
                            issueInfo.data.relatedLinks.map((issue, index) => (
                              <ListItem
                                dense
                                button
                                className={classes.realtedLinksListItem}
                                key={index}
                                onClick={onIssueClick(issue)}
                              >
                                <ListItemText
                                  primary={issue.issueNumber}
                                  primaryTypographyProps={{
                                    variant: "caption",
                                  }}
                                  secondary={issue.title}
                                  secondaryTypographyProps={{
                                    variant: "subtitle1",
                                    color: "initial",
                                  }}
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    size="medium"
                                    color="secondary"
                                    onClick={onDeleteChip(
                                      "relatedLinks",
                                      issue
                                    )}
                                  >
                                    <span className="material-icons danger">
                                      delete
                                    </span>
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))
                          ) : (
                            <Typography variant="caption">{}</Typography>
                          )}
                        </List>
                      ) : (
                        <Fragment>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple
                              disableClearable
                              limitTags={4}
                              id="edit-issue-related-links"
                              options={issuesRef.data}
                              value={issueInfo.data.relatedLinks}
                              onChange={onAutoCompleteSelect("relatedLinks")}
                              onBlur={onAutoCompleteBlur("relatedLinks")}
                              getOptionLabel={(option) =>
                                option.issueNumber && option.title
                                  ? `${option.issueNumber} - ${option.title}`
                                  : option.issueNumber || option.title
                              }
                              getOptionSelected={(option, value) =>
                                (option.title === value.title &&
                                  option.issueNumber === value.issueNumber) ||
                                option.issueNumber === value.issueNumber
                              }
                              noOptionsText={"Issues not found"}
                              loading={issuesRef.wait}
                              loadingText={
                                <Grid container spacing={2}>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                  <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                    <Skeleton
                                      variant="rect"
                                      animation="wave"
                                      width={`90%`}
                                    />
                                  </Grid>
                                </Grid>
                              }
                              renderTags={(value) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={index}
                                    variant="default"
                                    label={option.title}
                                    size="small"
                                    onDelete={onDeleteChip(
                                      "relatedLinks",
                                      option
                                    )}
                                  />
                                ))
                              }
                              renderOption={(option) => (
                                <ListItem
                                  disableGutters
                                  dense
                                  className={classes.renderOption}
                                >
                                  <ListItemText
                                    primary={option.issueNumber}
                                    primaryTypographyProps={{
                                      variant: "caption",
                                    }}
                                    secondary={option.title}
                                    secondaryTypographyProps={{
                                      variant: "subtitle1",
                                      color: "initial",
                                    }}
                                    className={classes.renderOption}
                                  />
                                </ListItem>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  disabled={
                                    issueInfo.data.state === ISSUE_STATE.CLOSED
                                  }
                                  variant="outlined"
                                  label="Link issues"
                                  placeholder="Link Issues by issue number or title"
                                  name="relatedLinks"
                                  onChange={onInputChange("relatedLinks")}
                                />
                              )}
                            />
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                  </ListItem>
                 
                </List>
              </Drawer>
            </div>
          </Grid>
          <StateOptions />
        </Container>
      ) : (
        <Container id="issue-wrapper" maxWidth="xl">
          <Grid
            container
            spacing={3}
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.root}
          >
            <Grid
              container
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              id="issue-error"
              justifyContent="center"
            >
              <Typography variant="h5" color="error">
                {errors.common}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(null, mapDispatchToProps)(ViewIssue);
