import FileUploadHandler from './upload-handler';
import FileViwer from './view-file';


export interface FileInfo {
    readonly path: string,
    readonly internalName: string,
    readonly originalName: string,
    readonly ext: string

}

// UPLOAD STATUS
enum UPLOAD_STATUS {
    IN_PROGRESS = 'IN_PROGRESS',
    CANCELLED = 'CANCELLED',
    SUCCESS = 'SUCCESS'
};
export { FileUploadHandler, FileViwer, UPLOAD_STATUS }