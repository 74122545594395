/**
 * To configure axios
 * @param {string} - token
 * @author Aman Harde
 * @since 1.0
 */


import axios from 'axios';

export const setJWTToken = (token: string | null) => {
    if (token !== null) {
        console.log("Perfect")
        axios.defaults.headers.common.authorization = `Bearer ${token}`;
    }
    else {
        delete axios.defaults.headers.common.authorization;
    }
}

