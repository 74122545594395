import MomentUtils from "@date-io/moment";
import {
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import downloadFile from "../../_utils/downloadFile";
import { downloadAttendance, findAttendance } from "../../apis/attendance-api";
import theme from "../../assects/theme";
import AttendanceTable from "./attendance-table";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createHoliday, findByMonth } from "../../apis/holiday-api";
import { hideAlertModal, showAlertModal } from "../../store/actions/ui-actions";
import { connect } from "react-redux";
import { ALERT } from "../../utils/constants";

const style = () => ({
  root: {
    display: "flex",
  },
  refresh: {
    position: "relative",
    bottom: 5,
  },
  rotateIcon: {
    animation: "rotation 2s infinite linear",
  },
  buttonCopy: {
    fontSize: `${theme.spacing(2)}px !important`,
    marginRight: "10px",
  },
});

class Teamattendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHolidayMarked: false,
      attendanceList: {
        content: [],
      },

      month: moment(new Date()).format("YYYY-MM"),
      days: [],
      newMonth: "",
      isDataLoaded: false,
      name: "",
      openCalendar: false,
      holiday: moment(new Date()).format("YYYY-MM-DD"),
      holidayName: "",
      holidayData: [],
      holidayNameError: "",
    };
  }

  handleCalendarOpen = () => {
    this.setState({ openCalendar: true });
  };

  handleCalendarClose = () => {
    this.setState({ openCalendar: false });
  };

  handleClickOpen() {
    this.setState({
      ...this.state,
      openCalendar: true,
    });
  }

  handleHolidayDateChange = (day) => {
    this.setState({
      ...this.state,
      holiday: moment(day).format("YYYY-MM-DD"),
    });
  };

  handleHolidayNameChange = () => {
    this.setState({
      ...this.state,
      holidayName: this.state.holidayName,
    });
  };

  handleOkButtonClick = () => {
    const markHolidayRequest = {
      startDate: this.state.holiday,
      name: this.state.holidayName,
    };

    createHoliday(markHolidayRequest)
      .then((response) => {
        console.log("Holiday marked successfully:", response.data);

        this.setState({
          openCalendar: false,
          selectedDate: this.state.startDate,
          holidayName: "",
          isHolidayMarked: true, // Flag to indicate that holiday is marked
        });

        const updatedHolidayData = [...this.state.holidayData, response.data];
        this.setState({ holidayData: updatedHolidayData });

        // Show success alert
        // this.props.showAlertModal({
        //   heading: "Success",
        //   description: `Holiday marked successfully!`,
        //   actionButtons: [
        //     { title: "Close", callback: () => this.props.hideAlertModal() },
        //   ],
        //   variant: ALERT.SUCCESS,
        // });

        this.handleCalendarClose();
      })
      .catch((error) => {
        // if (error.response && error.response.status === 400 && error.response.data === "Holiday already marked") {
        //   this.props.showAlertModal({
        //     heading: "Error",
        //     description: "Holiday is already marked.",
        //     actionButtons: [
        //       { title: "Close", callback: () => this.props.hideAlertModal() },
        //     ],
        //     variant: ALERT.WARNING,
        //   });
        // } else {
        console.error("Error marking holiday:", error);
        this.props.showAlertModal({
          heading: "Error",
          description: "Holiday is already marked.",
          actionButtons: [
            { title: "Close", callback: () => this.props.hideAlertModal() },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  handleFindHoliday = () => {
    const month = { month: this.state.month };

    findByMonth(month)
      .then((response) => {
        this.setState({
          holidayData: response.data,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  updatePage = () => {
    const { month } = this.state;
    this.findAttendance(month);
  };

  componentDidMount() {
    if (this.props.history.location.search !== "") {
      this.setState({
        ...this.state,
        month: this.props.history.location.search,
      });
      this.findAttendance(this.props.history.location.search);
      this.handleFindHoliday(this.state.month);
    } else {
      this.findAttendance(this.state.month);
      this.handleFindHoliday(this.state.month);
    }

    this.updatePage();
    this.handleFindHoliday();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.month !== prevState.month) {
      this.handleFindHoliday();
    }
  }

  findAttendance = (month) => {
    const filterRequest = {};
    filterRequest["month"] = moment(month).format("YYYY-MM");

    let dates = [];

    let day = moment(month, "YYYY-MM").daysInMonth();
    for (var i = 1; i <= day; i++) {
      dates.push(moment(month).format("YYYY-MM") + "-" + i);
    }

    this.setState({
      ...this.state,
      isDataLoaded: true,
    });

    findAttendance(filterRequest)
      .then((response) => {
        const data = response.data;
        const sorted = data.sort((a, b) =>
          a.authUserName.localeCompare(b.authUserName)
        );
        this.setState({
          ...this.state,
          attendanceList: {
            content: data,
          },
          days: dates,
          newMonth: moment(this.state.month).format("YYYY-MM"),
          isDataLoaded: false,
          name: data,
        });
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          attendanceList: {
            content: [],
          },
          isDataLoaded: false,
        });
      });
  };

  handleDateChange = (month) => {
    this.setState({
      ...this.state,
      month: moment(month).format("YYYY-MM"),
    });
  };

  onSearchClick = (event) => {
    this.findAttendance(this.state.month);
    this.handleFindHoliday(this.state.month);
    this.props.history.push(`?${this.state.month}`);
  };

  DownloadList = (event) => {
    downloadAttendance({ month: this.state.month })
      .then((res) => {
        downloadFile(res);
      })
      .catch((err) => {});
  };

  render() {
    const { classes } = this.props;
    const { attendanceList, days, isDataLoaded, name } = this.state;
    const { openCalendar } = this.state;

    return (
      <Container maxWidth="xl" disableGutters={false} className={classes.root}>
        <CssBaseline />

        <Grid container spacing={2}>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Typography variant="h5" className={classes.heading}>
                {"Team Attendance"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Grid item>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  size="small"
                  variant="inline"
                  inputVariant="outlined"
                  label="Select month"
                  id="attendance-month"
                  format="MMMM, YYYY"
                  openTo="month"
                  views={["year", "month"]}
                  autoOk={true}
                  value={this.state.month}
                  onChange={this.handleDateChange}
                />
              </MuiPickersUtilsProvider>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                id="search-job-summary"
                onClick={this.onSearchClick}
              >
                Search
              </Button>
            </Grid>

            <Grid item>
              <IconButton
                disabled={isDataLoaded}
                className={clsx(classes.refresh, {
                  [classes.rotateIcon]: isDataLoaded,
                })}
                title="Refresh"
                onClick={this.onSearchClick}
              >
                <RefreshIcon
                  fill={theme.palette.common.black}
                  height={20}
                  width={20}
                />
              </IconButton>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                id="search-job-summary"
                onClick={this.DownloadList}
                startIcon={<GetAppIcon />}
              >
                Download
              </Button>
              &nbsp;
              <Button
                variant="contained"
                size="medium"
                color="primary"
                id="search-job-summary"
                onClick={this.handleCalendarOpen}
              >
                Add Holiday
              </Button>
              <Dialog
                open={openCalendar}
                maxWidth="sm"
                fullWidth={true}
                id="holiday-modal"
              >
                <DialogTitle id="holiday-modal-title">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                      <Typography variant="h6"> {"Add Holiday"} </Typography>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent dividers id="holiday-modal-description">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <FormControl></FormControl>
                    </Grid>

                    {
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FormControl>
                          <FormGroup
                            defaultValue="End"
                            // onChange={onHandleFrom}
                            id="holiday-is-form"
                          >
                            <FormControlLabel
                              id="attendance-on-leave"
                              value={"onLeave"}
                              control={
                                <Grid
                                  container
                                  spacing={2}
                                  direction="row"
                                  justifyContent="flex-end"
                                  item
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                >
                                  <Grid item>
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <DatePicker
                                        size="small"
                                        variant="inline"
                                        inputVariant="outlined"
                                        label="Select date"
                                        id="attendance-month"
                                        format="YYYY-MM-DD"
                                        openTo="date"
                                        views={["date"]}
                                        autoOk={true}
                                        value={this.state.holiday}
                                        onChange={this.handleHolidayDateChange}
                                      />
                                      &nbsp;
                                      {
                                        <TextField
                                          placeholder="Enter Holiday Name"
                                          variant="outlined"
                                          label="Name"
                                          size="medium"
                                          multiline
                                          fullWidth
                                          helperText={"Enter Holiday Name "}
                                          value={this.state.holidayName}
                                          // onChange={this.handleHolidayNameChange}
                                          onChange={(e) => {
                                            const { value } = e.target;
                                            // const holidayName =
                                            //   typeof value === "string"
                                            //     ? value
                                            //     : "";
                                            this.setState({
                                              holidayName: value,
                                            });
                                          }}
                                        />
                                      }
                                    </MuiPickersUtilsProvider>
                                  </Grid>
                                  <Grid item></Grid>
                                </Grid>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    }
                  </Grid>
                </DialogContent>

                <DialogActions id="attendance-modal-action">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    id="attendance-cancel-button"
                    onClick={this.handleCalendarClose}
                  >
                    {"Cancel"}
                  </Button>

                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    id="attendance-save-button"
                    onClick={this.handleOkButtonClick}
                  >
                    {"Save"}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography color="primary" variant="h5">
              Month: {moment(this.state.newMonth).format("MMMM YYYY")}
            </Typography>
            <AttendanceTable
              holidayData={this.state.holidayData}
              data={attendanceList.content}
              days={days}
              fetchAttendance={(event) =>
                this.findAttendance(this.state.month, event)
              }
              name={this.state.name}
              handleClearHoliday={this.handleClearHoliday}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  hideAlertModal: (payload) => dispatch(hideAlertModal(payload)),
  showAlertModal: (payload) => dispatch(showAlertModal(payload)),
});

console.log("alertbox", hideAlertModal);

// export default withStyles(style)(withRouter(Teamattendance));
export default withStyles(style)(
  withRouter(connect(null, mapDispatchToProps)(Teamattendance))
);
