import React, { FC, useState } from 'react';
import { Dialog, DialogContent, Button, Typography, DialogTitle, DialogActions, Grid, makeStyles, IconButton } from '@material-ui/core';
import JsonFormatter from 'react-json-formatter';






const useStyles = makeStyles(theme => ({
    skeleton: {
        padding: theme.spacing(2)
    }
}));


interface ViewIssueDiffProps {

    readonly buttonValue: string,
    readonly logInfo: string,
    readonly handleClose: (event: any) => void,
    readonly jsonStyle: any,

}


const ViewIssueDiff: FC<ViewIssueDiffProps> = (props) => {


    const classes = useStyles();

    let { buttonValue, logInfo } = props;


    const handleClose = (event: any) => {
        props.handleClose(event);
    }
    console.log("logInfo", logInfo)
    var obj = JSON.parse(logInfo);

    return (
        <Dialog
            open={buttonValue === "view-diff"}
            maxWidth="md"
            onClose={handleClose}
            fullWidth
            disableBackdropClick
            id='view-diff'
        >
            <DialogTitle id="view-diff-title" disableTypography>
                <Grid container alignItems='center'>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                        <span className="material-icons">search</span>
                    </Grid>
                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                        <Typography variant='h6' >{`View Chnage Log`}</Typography>
                    </Grid>
                </Grid>

            </DialogTitle>
            <DialogContent dividers id="view-diff-description">
                <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} sm={11} xs={12}>
                        <code>
                            <JsonFormatter
                                json={JSON.stringify(obj)}
                                tabWith={4}
                                jsonStyle={props.jsonStyle}
                            />
                        </code>
                    </Grid>
                </Grid>
            </DialogContent >
            <DialogActions id='view-diff-action'>
                <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    id='view-diff-close'
                    onClick={handleClose}

                >
                    {'Close'}
                </Button>
            </DialogActions>
        </Dialog >

    )

}




export default ViewIssueDiff;