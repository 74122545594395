import React, { useEffect, useState } from 'react';
import { ReportApi } from '../../apis/teamPerformance-api';
import moment from 'moment';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Grid,
    Button,
    Typography
  } from '@material-ui/core';
  import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';

  
const TeamPerformancePage = () => {
    const [teamData, setTeamData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(moment().startOf('month'));
    const [dialogOpen, setDialogOpen] = useState(false);

  
    useEffect(() => {
        fetchData(selectedMonth.format('YYYY-MM'));
      }, [selectedMonth]);
  
    const fetchData = async (month) => {
      try {
        const response = await ReportApi(month);
        setTeamData(response.data);
      } catch (error) {
        console.error('Error fetching  data:', error);
        setTeamData([]);
        setDialogOpen(true);
      }
    };
  

    const handleDateChange = (date) => {
        setSelectedMonth(date);
      };

    const onSearchClick = () => {
        fetchData(selectedMonth.format('YYYY-MM'));
      };
  
return (
    <div>
      <h1>Team Performance</h1>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              size="small"
              variant="inline"
              inputVariant="outlined"
              label="Select month"
              id="attendance-month"
              format="MMMM, YYYY"
              openTo="month"
              views={['year', 'month']}
              autoOk={true}
              value={selectedMonth}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            id="search-job-summary"
            onClick={onSearchClick}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      &nbsp;
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Name</TableCell>
              <TableCell>Number of working days</TableCell>
              <TableCell>Number of total issues</TableCell>
              <TableCell>FbUp</TableCell>
              <TableCell>FbDown</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {teamData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="h5">Data Not Available</Typography>
                </TableCell>
              </TableRow>
            ) : (
              teamData.map((teamMember, index) => (
                <TableRow key={index}>
                  <TableCell align='left'>{teamMember.userName}</TableCell>
                  <TableCell align='left'>{teamMember.totalWorkingDays}</TableCell>
                  <TableCell align='left'>{teamMember.totalIssues}</TableCell>
                  <TableCell align='left'>{teamMember.fbUp}</TableCell>
                  <TableCell align='left'>{teamMember.fbDown}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TeamPerformancePage;  