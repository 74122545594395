import { FC, Fragment, useState, useEffect, useRef } from 'react'

import clsx from 'clsx';

import { Drawer, Toolbar, IconButton, ListItemSecondaryAction, ListItem, ListItemIcon, Divider, makeStyles, Grid, List, Typography, ListItemText } from '@material-ui/core';
import theme from '../../assets/theme/theme';
import { getSavedFilters, deleteIcon } from "../../apis/issue-api";
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilter } from '../../store/actions/ui-actions';
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { RootState } from '../../store/reducers';
import { useHistory } from 'react-router-dom';
import searchFilterGenerator from '../search-bar/searchFilterGenerator';
import { UIReducerInitalState } from '../../interface/reducer-interface';
import { FetchType } from '../../store/type';
import { SaveFilter } from '../search-bar/intefaces';
const drawerWidth = 240;

const useStyles = makeStyles({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: theme.palette.primary.main,
        borderRight: `1px solid rgb(255 87 34 / 50%) !important`
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        minHeight: theme.spacing(6)
    },
    headList: {
        paddingTop: theme.spacing(1) / 2,
        paddingBottom: theme.spacing(1) / 2
    },
    divider: {
        backgroundColor: `rgb(255 87 34 / 50%) !important`
    },
    headerButton: {
        color: theme.palette.common.white,
        textTransform: 'none',
    },
    filterMargin: {
        marginLeft: "20px"
    }
});

interface Filters {
    readonly filters: SaveFilter[],
    readonly index: number,
    readonly title: string
} 

const LeftDrawer: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { fetchStatus } = useSelector((state: RootState) => state.uiReducer as UIReducerInitalState);

    const [state, setState] = useState({
        open: false,
        openTicketCount: 0,
        assignedTicketCount: 0,
        saveFilter: [] as Filters[]
    });

    const mounted = useRef(false);
      useEffect(() => {
       if (!mounted.current) {
        fetchSavedFilter();
          mounted.current = true;
        } else {
         fetchStatus === "FETCH_START" && fetchSavedFilter();
        }
       });
    
      const fetchSavedFilter = () =>{
        getSavedFilters().then((res)=>{
           setState({
               ...state,
               saveFilter: res.data
           })
          dispatch(fetchFilter(FetchType.FETCH_END));
        }).catch(()=>{
        })
    }

    const deleteFunction = (index: number, title: string) => {
        if (window.confirm(`Do you want to delete ${title} filter?`)) {
            deleteIcon(index).then(()=>{
                fetchSavedFilter();
           })
        } 
    } 
    const buttonOpenClose =(e:any)=>{
        return(
            setState({
                ...state,
                open: !state.open 
            })    
        )}
    const selectFilter = (filters:SaveFilter[])=> (e:any) => {
        history.push({
            search: searchFilterGenerator(filters)
        });
    }
    return (
        <Fragment>
            <Drawer
                variant="permanent"

                open={state.open}
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: state.open,
                    [classes.drawerClose]: !state.open,

                })}
                classes={{
                    paper: clsx(classes.drawer,
                        {
                            [classes.drawerOpen]: state.open,
                            [classes.drawerClose]: !state.open,
                        }),
                }}
            >
                <Toolbar className={classes.toolbar} />
                <Grid> 
                    <List disablePadding className={classes.headList}>
                        <ListItem id='left-drawer-item'>
                            <ListItemIcon title={!state.open ? 'Open' : 'Close'} id='left-drawer-item-opener'>
                                <IconButton
                                    color='secondary'
                                    onClick={(e) =>{buttonOpenClose(e)}}>
                                    <span className="material-icons light">
                                        {`menu`}
                                    </span>
                                </IconButton>
                            </ListItemIcon>
                        </ListItem>
                    </List>
                    <Divider className={classes.divider} />
                    <List>
                        {     
                            state.saveFilter.map((filter: Filters, index: number) => (
                                <ListItem
                                    button
                                    dense
                                    title={filter.title}
                                    id={`item-${index}`}
                                    key={index}
                                    onClick={selectFilter(filter.filters)}
                                >  
                                    <ListItemText disableTypography className={classes.headerButton} >
                                     <IconButton  edge="end" aria-label="filter">
                                        <Filter fill={theme.palette.common.white} height={25} width={25}  />
                                        </IconButton>
                                        {state.open===true &&
                                        <Typography display="inline" className={classes.filterMargin}>
                                            {filter.title}
                                        </Typography>}
                                </ListItemText>
                                  
                                    {state.open === true &&
                                    <ListItemSecondaryAction >
                                        <IconButton size='small' color='secondary' title='Delete filter' onClick={() =>{deleteFunction(filter.index, filter.title)}}>
                                                <span className="material-icons">delete</span>
                                        </IconButton>
                                    </ListItemSecondaryAction>}
                                </ListItem>
                            ))
                        }
                    </List>

                </Grid>
            </Drawer>
        </Fragment>
    )
}

export default LeftDrawer;
  