import { Button, CircularProgress, FormHelperText, Grid, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, useState } from "react";
import { createLabel } from "../../apis/label-api";
import { checkFieldErrors } from "../../utils/field-error-checker";


const style = makeStyles({
    formWrapper: {
        marginTop: 0,
        margin: 'auto'
    }
});


interface LabelCreateProps {
    readonly _fetchLabels: (event: any) => void
}

const LabelCreate: FC<LabelCreateProps> = (props) => {
    const classes = style();

    const [state, setState] = useState({
        formData: {
            name: '',
            value: ''
        },
        fieldError: {
            name: '',
            value: ''
        },
        wait: {
            forSubmit: false
        },
        errorMessage: ''
    });

    const resetState = (event: any) => {
        setState({
            formData: {
                name: '',
                value: ''
            },
            fieldError: {
                name: '',
                value: ''
            },
            wait: {
                forSubmit: false
            },
            errorMessage: ''
        });
    }

    const onInputChange = (field: string) => (event: any) => {
        setState({
            ...state,
            formData: {
                ...state.formData,
                [field]: event.target.value
            },
            fieldError: {
                ...state.fieldError,
                [field]: ''
            },
            errorMessage: ''
        });
    }

    const onButtonClick = (value: string) => (event: any) => {

        switch (value) {
            case 'reset':
                resetState(event);
                break;

            case 'create':
                submitForm(event);
                break;

            default:
                break;
        }
    }

    const validateFields = (): boolean => {
        let fieldError = checkFieldErrors(state.fieldError, state.formData);

        setState({
            ...state,
            fieldError: fieldError.errors
        });

        return fieldError.haError;
    }

    const submitForm = (event: any) => {

        if (!validateFields()) {

            setState({
                ...state,
                wait: {
                    ...state.wait,
                    forSubmit: true
                }
            });

            createLabel(state.formData).then((res: AxiosResponse<any>) => {
                props._fetchLabels(event);
                resetState(event);
            }).catch((err: any) => {
                setState({
                    ...state,
                    errorMessage: 'Error while creating label. Please try again.'
                });
            });
        }
    }

    return (
        <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formWrapper}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5">{"Create Label"}</Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                     variant='outlined'
                     size='small'
                     fullWidth
                     label='Label Name'
                     id='create-label-name'
                     select
                     required
                     value={state.formData.name}
                     onChange={onInputChange('name')}
                     error={state.fieldError.name !== ''}
                     helperText={state.fieldError.name}
                     disabled={state.wait.forSubmit}
                    >
                    <MenuItem value={'Project'}>Project</MenuItem>
                    <MenuItem value={'Module'}>Module</MenuItem>
                    <MenuItem value={'Process'}>Process</MenuItem>
                    <MenuItem value={'Issue Type'}>Issue Type</MenuItem>
                    <MenuItem value={'Technology'}>Technology</MenuItem>
                </TextField>                        
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <TextField
                    variant='outlined'
                    size='small'
                    fullWidth
                    required
                    label='Label Value'
                    placeholder='Insert label value'
                    id='create-label-value'
                    value={state.formData.value}
                    onChange={onInputChange('value')}
                    error={state.fieldError.value !== ''}
                    helperText={state.fieldError.value}
                    disabled={state.wait.forSubmit}
                />
            </Grid>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormHelperText error={state.errorMessage !== ''}>
                    {state.errorMessage}
                </FormHelperText>
            </Grid>

            <Grid container spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12} direction='row-reverse' alignContent='flex-end'>
                <Grid item>
                    <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        id='create-label-button'
                        onClick={onButtonClick('create')}
                        disabled={state.wait.forSubmit}
                        endIcon={state.wait.forSubmit && <CircularProgress color='primary' size={20} />}
                    >
                        {'Create Label'}
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        variant="contained"
                        color='primary'
                        size='small'
                        id='reset-label-button'
                        onClick={onButtonClick('reset')}
                        disabled={state.wait.forSubmit}
                    >
                        {'Reset'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}



export default LabelCreate;