import { colors, lighten } from '@material-ui/core';

export default {

  root: {
    '&$selected, &$selected:hover': {
      backgroundColor: lighten(colors.teal[500], 0.8, 5)
    },
    '&$hover': {
      cursor: 'pointer'
    }
  }
};
