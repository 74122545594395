/**
 * This is create auth user form
 * @author Rahul Bhati
 * @since 1.0
 */

import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
import { checkFieldErrors } from "../../utils/field-error-checker";
import { ALERT } from "../../utils/ui-constants";
import { showAlertModal, hideAlertModal } from "../../store/actions/ui-actions";
import { connect } from "react-redux";
import { createAuthUser } from "../../apis/user-api";

const style = makeStyles({
  formWrapper: {
    marginTop: 0,
    margin: "auto",
  },
});

const roles = [
  {
    value: "DEVELOPER",
    label: "Developer",
  },
  {
    value: "MANAGER",
    label: "Manager",
  },
  {
    value:"REPORTER",
    label:"Reporter",
  }
];

const CreateCrudeOperation = (props) => {
  const classes = style();

  const [state, setState] = useState({
    formData: {
      userName: "",
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNo: "",
      authorities: [],
      password: "",
      confirmPassword: "",
    },
    fieldError: {
      userName: "",
      firstName: "",
      lastName: "",
      emailId: "",
      phoneNo: "",
      authorities: "",
      password: "",
      confirmPassword: "",
    },
    wait: {
      forSubmit: false,
    },
    errorMessage: "",
  });

  const resetState = (event) => {
    setState({
      formData: {
        userName: "",
        firstName: "",
        lastName: "",
        emailId: "",
        phoneNo: "",
        authorities: [],
        password: "",
        confirmPassword: "",
      },
      fieldError: {
        userName: "",
        firstName: "",
        lastName: "",
        emailId: "",
        phoneNo: "",
        authorities: "",
        password: "",
        confirmPassword: "",
      },
      wait: {
        forSubmit: false,
      },
      errorMessage: "",
    });
  };

  const onInputChange = (field) => (event) => {
    if (field === "authorities") {
      setState({
        ...state,
        formData: {
          ...state.formData,
          [field]: [event.target.value],
        },
        fieldError: {
          ...state.fieldError,
          [field]: "",
        },
        errorMessage: "",
      });
    } else {
      if (field === "phoneNo") {
        if (event.target.value.length <= 10) {
          setState({
            ...state,
            formData: {
              ...state.formData,
              [field]: event.target.value,
            },
            fieldError: {
              ...state.fieldError,
              [field]: "",
            },
            errorMessage: "",
          });
        }
      } else {
        setState({
          ...state,
          formData: {
            ...state.formData,
            [field]: event.target.value,
          },
          fieldError: {
            ...state.fieldError,
            [field]: "",
          },
          errorMessage: "",
        });
      }
    }
  };

  const onButtonClick = (value) => (event) => {
    switch (value) {
      case "reset":
        resetState(event);
        break;

      case "create":
        submitForm(event);
        break;

      default:
        break;
    }
  };

  const validateFields = () => {
    let fieldError = checkFieldErrors(
      state.fieldError,
      state.formData,
      state.formData.confirmPassword
    );

    setState({
      ...state,
      fieldError: fieldError.errors,
    });

    return fieldError.haError;
  };

  const submitForm = (event) => {
    if (!validateFields()) {
      delete state.formData.confirmPassword;
      createAuthUser(state.formData)
        .then((res) => {
          props._fetchAuthUserData(event);
          resetState(event);
          props.closeDialog(event);
          props.showAlertModal({
            heading: "Success",
            description: `Auth User created successfully.`,
            actionButtons: [
              {
                title: "Close",
                callback: (event) => {
                  props.hideAlertModal(event);
                },
              },
            ],
            variant: ALERT.SUCCESS,
          });
        })
        .catch((err) => {
          props.showAlertModal({
            heading: "Error",
            description: `Error`,
            actionButtons: [
              {
                title: "Close",
                callback: (event) => {
                  props.hideAlertModal(event);
                },
              },
            ],
            variant: ALERT.DANGER,
          });
          setState({
            ...state,
            errorMessage: "Error while creating auth user. Please try again.",
          });
        });
    }
  };
  return (
    <Grid
      container
      spacing={3}
      item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.formWrapper}
    >
      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          label="First Name"
          placeholder="Insert first name"
          id="create-first-name"
          value={state.formData.firstName}
          onChange={onInputChange("firstName")}
          error={state.fieldError.firstName !== ""}
          helperText={state.fieldError.firstName}
          disabled={state.wait.forSubmit}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          label="Last Name"
          placeholder="Insert last name"
          id="create-last-name"
          value={state.formData.lastName}
          onChange={onInputChange("lastName")}
          error={state.fieldError.lastName !== ""}
          helperText={state.fieldError.lastName}
          disabled={state.wait.forSubmit}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          label="User Name"
          placeholder="Insert user name"
          id="create-user-name"
          value={state.formData.userName}
          onChange={onInputChange("userName")}
          error={state.fieldError.userName !== ""}
          helperText={state.fieldError.userName}
          disabled={state.wait.forSubmit}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          type="emailId"
          label="Email-id"
          placeholder="Insert email-id"
          id="insert-email-id"
          value={state.formData.emailId}
          onChange={onInputChange("emailId")}
          error={state.fieldError.emailId !== ""}
          helperText={state.fieldError.emailId}
          disabled={state.wait.forSubmit}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          type="phoneNo"
          label="Phone Number"
          placeholder="Insert phone number"
          id="phone-number"
          value={state.formData.phoneNo}
          onChange={onInputChange("phoneNo")}
          error={state.fieldError.phoneNo !== ""}
          helperText={state.fieldError.phoneNo}
          disabled={state.wait.forSubmit}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          type="password"
          label="Password"
          placeholder="Insert password"
          id="password"
          value={state.formData.password}
          onChange={onInputChange("password")}
          error={state.fieldError.password !== ""}
          helperText={state.fieldError.password}
          disabled={state.wait.forSubmit}
        />
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          type="password"
          label="Confirm Password"
          placeholder="Insert confirm password"
          id="confirm-password"
          value={state.formData.confirmPassword}
          onChange={onInputChange("confirmPassword")}
          error={state.fieldError.confirmPassword !== ""}
          helperText={state.fieldError.confirmPassword}
          disabled={state.wait.forSubmit}
        />
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="standard-select-currency"
          select
          label="Roles"
          fullWidth
          value={state.formData.authorities}
          onChange={onInputChange("authorities")}
          helperText={state.fieldError.authorities}
          error={state.fieldError.authorities !== ""}
          disabled={state.wait.forSubmit}
        >
          {roles.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <FormHelperText error={state.errorMessage !== ""}>
          {state.errorMessage}
        </FormHelperText>
      </Grid>

      <Grid
        container
        spacing={1}
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction="row-reverse"
        alignContent="flex-end"
      >
        <Grid item>
          <Button
            variant="contained"
            size="small"
            color="primary"
            id="create-authUser-button"
            onClick={onButtonClick("create")}
            disabled={state.wait.forSubmit}
            endIcon={
              state.wait.forSubmit && (
                <CircularProgress color="primary" size={20} />
              )
            }
          >
            {"Create"}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="small"
            id="reset-milestone-button"
            onClick={onButtonClick("reset")}
            disabled={state.wait.forSubmit}
          >
            {"Reset"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(null, mapDispatchToProps)(CreateCrudeOperation);
