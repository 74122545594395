import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { Skeleton } from "@material-ui/lab";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DialogActions } from "@mui/material";
import { DatePicker } from "antd";
import "antd/dist/reset.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  cancelLeave,
  findByDate,
  markManagerAttendance,
  updateAttendance,
} from "../../apis/attendance-api";
import { removeHoliday } from "../../apis/holiday-api";
import Transition from "../../components/template/transition";
import { hideAlertModal, showAlertModal } from "../../store/actions/ui-actions";
import { ALERT, ATTENDANCE } from "../../utils/constants";
import { localDateFormatConverter3 } from "../../utils/dateFormates";
import {
  ValidateAttendance,
  ValidateReason,
} from "../../validations/console-validation";

const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  skeleton: {
    padding: theme.spacing(2),
  },
  bold: { fontWeight: "bold" },
  heading: {
    padding: "inherit",
  },
}));

function MarkTeamAttendance(props) {
  const { holidayData } = props;
  const holidayDates =
    holidayData && holidayData
      ? holidayData.map((holiday) => holiday.date)
      : [];

  const holidayName =
    holidayData && holidayData
      ? holidayData.map((holiday) => holiday.name)
      : [];

  const classes = useStyles();

  const [state, setState] = useState({
    markRequest: {
      attendanceDate: null,
      attendance: null,
      inTime: null,
      outTime: null,
      id: null,
      recordedBy: null,
      name: null,
    },
    markLeaveRequest: {
      leaveReason: "",
      fromDate: "",
      toDate: "",
    },
    markCancelRequest: {
      fromDate: "",
      toDate: "",
    },
    fieldError: {
      attendance: "",
      leaveReason: "",
    },
  });

  const [response, setResponse] = useState(null);

  const [dates, setDates] = useState([]);

  const dateFormat = "DD/MM/YYYY";
  const {
    buttonValue,
    userName,
    selectedAttendanceDate,
    name,
    data,
    recordedBy,
  } = props;

  const {
    markRequest,
    markLeaveRequest,
    markCancelRequest,
    fieldError,
  } = state;
  const [waiting, setWaiting] = useState({
    forSubmit: false,
  });

  const [loading, setLoading] = useState({
    submit: false,
  });

  const [markOutTime, setMarkOutTime] = useState(false);
  const [intime, setInTime] = useState();
  const [manualOutTime, setManualOutTime] = useState("00:00");
  const [manualInTime, setManualInTime] = useState("00:00");



  useEffect(() => {
    if (state.markRequest) {
      const initialInTime = state.markRequest.inTime
        ? moment(state.markRequest.inTime).format("HH:mm")
        : "00:00";
      const initialOutTime = state.markRequest.outTime
        ? moment(state.markRequest.outTime).format("HH:mm")
        : "00:00";

      setManualInTime(initialInTime);
      setManualOutTime(initialOutTime);
    }
  }, [state.markRequest]);

  const handleManualOutTimeChange = (event) => {
    setManualOutTime(event.target.value);
  };

  const handleManualInTimeChange = (event) => {
    setManualInTime(event.target.value);
  };

  const formatTime = (timeString) => {
    const formattedTime = moment(timeString, "YYYY-MM-DD HH:mm:ss").format(
      "hh:mm A"
    );
    return formattedTime;
  };
  const isMarkAttendance = buttonValue === "Attendance";

  useEffect(() => {
    if (buttonValue === "Attendance") {
      setWaiting({
        ...waiting,
        forSubmit: true,
      });
      console.log(
        "Formatted selected attendance date:",
        moment(selectedAttendanceDate).format("YYYY-MM-DD")
      );
      findByDate(moment(selectedAttendanceDate).format("YYYY-MM-DD"), userName)
        .then((response) => {
          const attendanceValue = response.data.attendance;
          setResponse(attendanceValue);

          const fullName = response.data.name;

          setState({
            ...state,
            markRequest: response.data,
            name: name,
          });
          setWaiting({
            ...waiting,
            forSubmit: false,
          });
        })
        .catch((error) => {
          setState({
            ...state,
            markRequest: {
              ...state.markRequest,
              attendance: null,
            },
          });
          setWaiting({
            ...waiting,
            forSubmit: false,
          });
        });
    }
  }, [buttonValue]);

  const onHandleFrom = (event) => {
    const { value } = event.target;
    setState({
      ...state,
      markRequest: {
        ...state.markRequest,
        [event.target.value]: event.target.checked,
      },
      markLeaveRequest: {
        ...state.markLeaveRequest,
        leaveReason: value,
      },
    });
  };

  const handleOnChange = (values) => {
    const fromTime = values[0].$d;
    const toTime = values[1].$d;

    setDates(
      values.map((item) => {
        return moment(item).format("DD-MM-YYYY");
      })
    );

    setState((prev) => ({
      ...prev,
      ...markLeaveRequest,
      markLeaveRequest: {
        fromDate: fromTime,
        toDate: toTime,
      },
    }));
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      markLeaveRequest: {
        ...prev.markLeaveRequest,
        leaveReason: value,
      },
    }));
  };

  const handleCancelOnChange = (values) => {


    const fromTime = values[0].$d;
    const toTime = values[1].$d;

    setDates(
      values.map((item) => {
        return moment(item).format("DD-MM-YYYY");
      })
    );

    setState((prev) => ({
      ...prev,
      ...markCancelRequest,
      markCancelRequest: {
        fromDate: fromTime,
        toDate: toTime,
      },
    }));
  };

  const inTime = (event) => {
    let a = { currentdate };
    setInTime(event.target.checked);

    setState({
      ...state,
      markRequest: {
        ...state.markRequest,
        inTime: event.target.checked ? currentdate : null,
      },
    });
  };

  const outTime = (event) => {
    setState({
      ...state,
      markRequest: {
        ...state.markRequest,
        outTime: event.target.checked ? currentdate : null,
      },
    });
  };

  const _markAttendance = async (event) => {
    if (!(await hasErrorInForm())) {
      const inTime = (event) => {
        let a = { currentdate };
        setInTime(event.target.checked);

        if (state.markRequest.inTime && !state.markRequest.outTime) {
          setState({
            ...state,
            markRequest: {
              ...state.markRequest,
              outTime: currentdate,
            },
          });
        };
      }

      const outTime = (event) => {
        setState({
          ...state,
          markRequest: {
            ...state.markRequest,
            [event.target.value]: event.target.checked,
          },
        });
      };

      const _markRequest = {
        ...markRequest,
        ...markLeaveRequest,
        userName: props.userName,
        attendanceDate: moment(props.selectedAttendanceDate).format(
          "yyyy-MM-DD"
        ),
        attendance: state.markRequest.attendance,
        name: props.name,
        inTime: intime,
        outTime: state.markRequest.outTime,

      };
      markManagerAttendance(_markRequest)
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            props.fetchAttendance();
            props.showAlertModal({
              heading: "Success",
              description: `Your attendance marked Sucessfully!`,
              actionButtons: [
                {
                  title: "Close",
                  callback: (event) => props.hideAlertModal(),
                },
              ],
              variant: ALERT.SUCCESS,
            });
          } else {
            props.showAlertModal({
              heading: "Error",
              description: "Server Error! Please Try Again Later.",
              actionButtons: [
                {
                  title: "Close",
                  callback: (event) => props.hideAlertModal(),
                },
              ],
              variant: ALERT.DANGER,
            });
          }
        })
        .catch((err) => {
          props.showAlertModal({
            heading: "Error",
            description: "Server Error! Please Try Again Later.",
            actionButtons: [
              { title: "Close", callback: (event) => props.hideAlertModal() },
            ],
            variant: ALERT.DANGER,
          });
        });
    }
  };

  const _updateAttendance = async (event) => {

    if (!(await hasErrorInForm())) {

      // if (!state.markRequest.outTime) {
      //   console.log("Cannot update attendance without marking out time.");
      //   return;
      // }


      const formattedOutTime = moment(manualOutTime, "HH:mm").format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      const formattedInTime = moment(manualInTime, "HH:mm").format(
        "YYYY-MM-DDTHH:mm:ss"
      );

      const isOutTimeMarked = state.markRequest.outTime !== null;


      const updateRequest = {
        userName: props.userName,
        attendanceDate: moment(props.selectedAttendanceDate).format(
          "yyyy-MM-DD"
        ),
        attendance:
          state.markRequest.attendance === "OUTTIME"
            ? ATTENDANCE.PRESENT
            : state.markRequest.attendance,
        name: state.markRequest.name,
        inTime: formattedInTime,
        // outTime: formattedOutTime,
        ...(isOutTimeMarked && { outTime: formattedOutTime }),
      };

      updateAttendance(updateRequest)
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            props.fetchAttendance();
            props.showAlertModal({
              heading: "Success",
              description: `Your attendance updated Sucessfully!`,
              actionButtons: [
                { title: "Close", callback: (event) => props.hideAlertModal() },
              ],
              variant: ALERT.SUCCESS,
            });
          } else {
            props.showAlertModal({
              heading: "Error",
              description: "Server Error! Please Try Again Later.",
              actionButtons: [
                { title: "Close", callback: (event) => props.hideAlertModal() },
              ],
              variant: ALERT.DANGER,
            });
          }
        })
        .catch((error) => {
        });
    }

  };

  const _cancelLeave = async (event) => {
    const cancelRequest = {
      ...markCancelRequest,
      userName: props.userName,
      attendanceDate: moment(props.selectedAttendanceDate).format("yyyy-MM-DD"),
      attendance: response,
    };

    cancelLeave(cancelRequest)
      .then((res) => {
        if (res.status === 200) {
          handleClose();
          props.fetchAttendance();
          props.showAlertModal({
            heading: "Success",
            description: JSON.stringify(res.data.message),
            actionButtons: [
              { title: "Close", callback: (event) => props.hideAlertModal() },
            ],
            variant: ALERT.SUCCESS,
          });
        } else {
          props.showAlertModal({
            heading: "Error",
            description: "Server Error! Please Try Again Later.",
            actionButtons: [
              { title: "Close", callback: (event) => props.hideAlertModal() },
            ],
            variant: ALERT.DANGER,
          });
        }
      })
      .catch((error) => {
        var oldText = error?.response?.data?.message;
        var test = oldText.replace("Internal Server Error:", "");
        props.showAlertModal({
          heading: "Error",
          description: test,
          actionButtons: [
            { title: "Close", callback: (event) => props.hideAlertModal() },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  const hasErrorInForm = async () => {
    let hasError = false;
    let errorObj = {};
    for (let field in state.fieldError) {
      var error = validateField(field, markRequest[field]);
      errorObj[field] = error;
      if (error !== "") {
        hasError = true;
      }
    }
    await setState({
      ...state,
      fieldError: errorObj,
    });
    return hasError;
  };

  const validateField = (field, value) => {
    switch (field) {
      case "attendance":
        return ValidateAttendance(value);
      case "leaveReason":
        return ValidateReason(value);
      default:
        return "";
    }
  };

  const onSaveClick = (event) => {
    setLoading({
      ...loading,
      submit: true,
    });

    if (
      state.markRequest.id === null &&
      (state.markRequest.attendance !== "CANCELLEAVE" ||
        state.markRequest.attendance !== "OUTTIME")
    ) {
      _markAttendance(event);
    } else if (state.markRequest.attendance === "CANCELLEAVE") {
      _cancelLeave(event);
    } else {
      _updateAttendance(event);
    }
  };

  const onAttendanceSelect = (event) => {
    const selectedDate = moment(props.selectedAttendanceDate).startOf("day");
    const currentDate = moment().startOf("day");

    let attendanceValue = event.target.value;
    if (selectedDate.isAfter(currentDate)) {
      setState({
        ...state,
        markRequest: {
          ...state.markRequest,
          attendance:
            attendanceValue === "CANCELLEAVE"
              ? ATTENDANCE.CANCELLEAVE
              : ATTENDANCE.ONLEAVE,
        },
        fieldError: {
          attendance: "",
        },
      });
    } else {
      setState({
        ...state,
        markRequest: {
          ...state.markRequest,
          attendance: attendanceValue,
        },
        fieldError: {
          attendance: "",
        },
      });
    }
    if (attendanceValue !== ATTENDANCE.OUTTIME) {
      setMarkOutTime(false);
    }
  };

  const onHandelExtra = (event) => {
    setState({
      ...state,
      markRequest: {
        ...state.markRequest,
        inTime: event.target.value,
      },
    });
  };
  const handleClose = async (event) => {
    props.handleCloseItem();
    setState({
      ...state,
      markRequest: {
        attendanceDate: null,
        attendance: null,
        inTime: null,
        outTime: null,
        id: null,
      },
      fieldError: {
        attendance: "",
      },
    });
  };

  const onCancelClick = (event) => {
    handleClose();
    setState({
      ...state,
      markRequest: {
        attendanceDate: null,
        attendance: null,
        inTime: false,
      },
    });
  };
  const onMarkOutTimeSelect = (event) => {
    setState((prevState) => ({
      ...prevState,
      markRequest: {
        ...prevState.markRequest,
        attendance: ATTENDANCE.PRESENT,
        inTime: true,
      },
    }));
    setMarkOutTime(true);
  };

  const currentdate = new Date();

  const selectedDate = moment(props.selectedAttendanceDate).startOf("day");
  const currentDate = moment().startOf("day");

  const isLeaveMarked = response === "ONLEAVE" ? true : false;

  const handleClearHoliday = () => {
    const date = { date: moment(selectedAttendanceDate).format("YYYY-MM-DD") };

    removeHoliday(date)
      .then((response) => {
        if (response.status === 200) {
          handleClose();
          props.showAlertModal({
            heading: "Success",
            description: `Holiday cleared Sucessfully!`,
            actionButtons: [
              {
                title: "Close",
                callback: (event) => {
                  props.hideAlertModal();
                  window.location.reload();
                },
              },
            ],
            variant: ALERT.SUCCESS,
          });
        } else {
          props.showAlertModal({
            heading: "Error",
            description: "Server Error! Please Try Again Later.",
            actionButtons: [
              { title: "Close", callback: (event) => props.hideAlertModal() },
            ],

            variant: ALERT.DANGER,
          });
        }
      })
      .catch((error) => {
        console.error("Error clearing holidays:", error);

        window.location.reload();

        props.showAlertModal({
          heading: "Error",
          description: "Failed to clear holiday. Please try again later.",
          actionButtons: [
            {
              title: "Close",
              callback: (event) => props.hideAlertModal(),
            },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  const getHolidayName = (selectedDate, holidayData) => {
    const selectedDateFormat = moment(selectedDate).format("YYYY-MM-DD");
    if (holidayData) {
      const holiday = holidayData.find(
        (holiday) => holiday.date === selectedDateFormat
      );
      return holiday ? holiday.name : "Unknown Holiday";
    }
    return "Unknown Holiday";
  };

  const holidayNames = getHolidayName(selectedAttendanceDate, holidayData);

  return (
    <Dialog
      open={buttonValue === "Attendance"}
      maxWidth="sm"
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth={true}
      id="add-user-box"
    >
      <DialogTitle id="add-user-title" disableTypography>
        {holidayDates.includes(
          moment(selectedAttendanceDate).format("YYYY-MM-DD")
        ) && holidayData ? (
          <>
            <Grid container spacing={1} alignItems="center">
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <AssignmentTurnedInIcon fill="white" height={35} width={35} />
              </Grid>
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                <Typography color="inherit" variant="h6">
                  {"Holiday"}
                </Typography>

              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container spacing={1} alignItems="center">
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
              <AssignmentTurnedInIcon fill="white" height={35} width={35} />
            </Grid>
            <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
              <Typography color="inherit" variant="h6">
                {"Mark Attendance"}
              </Typography>
              <Typography color="inherit" variant="subtitle2">
                Name: {props.name}
              </Typography>
              <Typography color="inherit" variant="subtitle2">
                Marked By: {state.markRequest.recordedBy?.username}
              </Typography>
              <Typography color="inherit" variant="subtitle2">
                Date: {localDateFormatConverter3(props.selectedAttendanceDate)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogTitle>

      <DialogContent dividers id="attendance-modal-description">
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {waiting.forSubmit ? (
              <List dense disablePadding>
                {Array(3)
                  .fill()
                  .map((skeleton, index) => (
                    <ListItem disableGutters dense key={index}>
                      <Skeleton
                        variant="rect"
                        width="70%"
                        height="50%"
                        animation="wave"
                        className={classes.skeleton}
                      />
                    </ListItem>
                  ))}
              </List>
            ) : (
              <FormControl error={fieldError.attendance !== ""}>
                {!holidayDates.includes(
                  moment(selectedAttendanceDate).format("YYYY-MM-DD")
                ) && (
                    <RadioGroup
                      row
                      defaultValue="End"
                      onChange={onAttendanceSelect}
                      id="attendance-presenty-form"
                    >
                      {selectedDate.isAfter(currentDate) ? null : (
                        <>
                          {response !== ATTENDANCE.ONLEAVE && (
                            <FormControlLabel
                              id="attendance-present"
                              value={ATTENDANCE.PRESENT}
                              control={
                                <Radio
                                  color="primary"
                                  checked={
                                    state.markRequest.attendance ===
                                    ATTENDANCE.PRESENT
                                  }
                                />
                              }
                              label="Present"
                            />
                          )}
                          {response === ATTENDANCE.PRESENT && (
                            <FormControlLabel
                              id="attendance-outTime"
                              value={ATTENDANCE.OUTTIME}
                              control={
                                <Radio
                                  color="primary"
                                  checked={
                                    state.markRequest.attendance ===
                                    ATTENDANCE.OUTTIME
                                  }
                                />
                              }
                              label="Mark Time"
                              onChange={onMarkOutTimeSelect}
                            />
                          )}
                          {response !== ATTENDANCE.ONLEAVE && (
                            <FormControlLabel
                              id="attendance-absent"
                              value={ATTENDANCE.ABSENT}
                              control={
                                <Radio
                                  color="primary"
                                  checked={
                                    state.markRequest.attendance ===
                                    ATTENDANCE.ABSENT
                                  }
                                />
                              }
                              label="Absent"
                            />
                          )}
                        </>
                      )}

                      <FormControlLabel
                        id="attendance-on-leave"
                        value={ATTENDANCE.ONLEAVE}
                        control={
                          <Radio
                            color="primary"
                            checked={
                              state.markRequest.attendance === ATTENDANCE.ONLEAVE
                            }
                          />
                        }
                        label="On Leave"
                      />
                      {isLeaveMarked && (
                        <FormControlLabel
                          id="attendance-cancel-leave"
                          value={ATTENDANCE.CANCELLEAVE}
                          control={
                            <Radio
                              color="primary"
                              checked={
                                state.markRequest.attendance ===
                                ATTENDANCE.CANCELLEAVE
                              }
                            />
                          }
                          label="Cancel Leave"
                        />
                      )}
                    </RadioGroup>
                  )}
                <FormHelperText> {fieldError.attendance} </FormHelperText>
              </FormControl>
            )}
            {holidayDates.includes(
              moment(selectedAttendanceDate).format("YYYY-MM-DD")
            ) && (
                <>
                  <div style={{ padding: "8px" }}>
                    <Typography color="inherit" variant="subtitle2">
                      <span style={{ fontWeight: "bold" }}>Date:</span>{" "}
                      {moment(selectedAttendanceDate).format("YYYY-MM-DD")}
                    </Typography>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <Typography color="inherit" variant="subtitle2">
                      <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                      {holidayNames}
                    </Typography>
                  </div>
                </>
              )}
          </Grid>

          {state.markRequest.attendance === ATTENDANCE.ONLEAVE &&
            !holidayDates.includes(
              moment(selectedAttendanceDate).format("YYYY-MM-DD")
            ) && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormControl>
                  <FormGroup
                    defaultValue="End"
                    onChange={onHandleFrom}
                    id="attendance-is-form"
                  >
                    <FormControlLabel
                      id="attendance-on-leave"
                      value={"onLeave"}
                      control={
                        <Grid
                          container
                          spacing={1}
                          direction="row"
                          justifyContent="flex-end"
                          item
                          xl={12}
                          lg={8}
                          md={6}
                          sm={12}
                          xs={12}
                        >
                          <Grid item>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <RangePicker
                                style={{ marginBottom: 20 }}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                                popupStyle={{
                                  position: "fixed",
                                }}
                                format={dateFormat}
                                disablePast
                                onChange={handleOnChange}
                              />
                              &nbsp;
                              <TextField
                                placeholder="Enter Leave Reason"
                                variant="outlined"
                                label="Reason"
                                size="medium"
                                multiline
                                fullWidth
                                onChange={handleChange}
                                value={state.markLeaveRequest.leaveReason}
                                error={
                                  state.markLeaveRequest.leaveReason === ""
                                }
                                helperText={"Enter Leave Reason"}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            )}

          {state.markRequest.attendance === ATTENDANCE.CANCELLEAVE && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FormControl>
                <FormGroup
                  defaultValue="End"
                  onChange={onHandleFrom}
                  id="attendance-is-form"
                >
                  <FormControlLabel
                    id="attendance-on-leave"
                    value={"cancelLeave"}
                    control={
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-end"
                        item
                        xl={12}
                        lg={8}
                        md={6}
                        sm={12}
                        xs={12}
                      >
                        <Grid item>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <RangePicker
                              style={{ marginBottom: 20 }}
                              getPopupContainer={(triggerNode) => {
                                return triggerNode.parentNode;
                              }}
                              popupStyle={{
                                position: "fixed",
                              }}
                              format={dateFormat}
                              disablePast
                              onChange={handleCancelOnChange}
                            />
                            &nbsp;
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}

          {markOutTime &&
            response !== ATTENDANCE.ONLEAVE &&
            !holidayDates.includes(
              moment(selectedAttendanceDate).format("YYYY-MM-DD")
            ) && (
              <>
                <form className={classes.container} noValidate>
                  <TextField
                    id="time"
                    label="IN"
                    type="time"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                      min: "00:00",
                      max: "24:00",
                    }}
                    value={manualInTime}
                    onChange={handleManualInTimeChange}
                    margin="normal"
                    fullWidth
                  />
                </form>
                &nbsp;
                <form className={classes.container} noValidate>
                  <TextField
                    id="time"
                    label="OUT"
                    type="time"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                      min: "00:00",
                      max: "24:00",
                    }}
                    value={manualOutTime}
                    onChange={handleManualOutTimeChange}
                    margin="normal"
                    fullWidth
                  />
                </form>
                <br />

                <FormHelperText>{fieldError.attendance}</FormHelperText>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl>
                    <FormGroup
                      defaultValue="End"
                      onChange={(e) => {
                        inTime(e);
                        outTime(e);
                      }}
                      id="attendance-is-form"
                    >
                      <FormControlLabel
                        id="attendance-in-time"
                        value={Object.freeze(currentdate)}
                        control={
                          <Checkbox
                            color="primary"
                            checked={inTime}
                            onChange={inTime}
                            disabled={state.markRequest.outTime}
                          />
                        }
                        label={
                          <div>
                            <div>
                              <b>In Time:</b>{" "}
                              {formatTime(state.markRequest.inTime)}
                            </div>
                          </div>
                        }
                      />

                      <FormControlLabel
                        id="attendance-out-time"
                        value={"outTime"}
                        control={
                          <Checkbox
                            color="primary"
                            checked={state.markRequest.outTime !== null} // Automatically checked if outTime is set
                            disabled={state.markRequest.outTime}

                            onChange={(e) => {
                              setState({
                                ...state,
                                markRequest: {
                                  ...state.markRequest,
                                  outTime: e.target.checked ? currentdate : null,
                                },
                              });
                            }}
                          />
                        }
                        label={
                          <div>
                            <div>
                              <b>Out Time:</b>{" "}
                              {formatTime(state.markRequest.outTime)}
                            </div>
                          </div>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </>
            )}
        </Grid>
      </DialogContent>

      <DialogActions id="comment-list">
        {holidayDates.includes(
          moment(selectedAttendanceDate).format("YYYY-MM-DD")
        ) ? (
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              id="cancel-button"
              onClick={handleClearHoliday}
            >
              {"Clear All"}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              id="cancel-button"
              onClick={handleClose}
            >
              {"Close"}
            </Button>
          </>
        ) : (
          <>
            <Button
              size="small"
              variant="contained"
              color="primary"
              id="cancel-button"
              onClick={handleClose}
            >
              {"Close"}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              id="cancel-button"
              onClick={onSaveClick}
            >
              {"Save"}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(null, mapDispatchToProps)(MarkTeamAttendance);
