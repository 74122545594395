/**
 * This is date formater util to convert date in various format
 * 
 * @author Aman Harde
 * 
 */
 import moment from "moment";

//  const DATE_TIME_FORMAT_1 = "DD MMM, YYYY / HH:mm:ss";
 const DATE_TIME_FORMAT_2 = "DD/MMM/YYYY HH:mm:ss";
 const DATE_TIME_FORMAT_3 = "DD MMM YYYY HH:mm:ss";
//  const DATE_FORMAT_1 = "DD/MMM/YYYY";
 const DATE_FORMAT_2 = "YYYY-MM-DD";
 const DATE_FORMAT_3 = "DD MMM, YYYY";
 const MONTH_FORMAT_4 = "DD MMM YYYY";
 const MONTH_FORMAT_1 = "YYYY-MM";
 
 export const localDateFormatConverter2 = (date) => {
   return moment(moment.utc(date).toDate()).format(DATE_FORMAT_2);
 };
 
 export const dateFormatConverter2 = (date) => {
   return moment(moment.utc(date).toDate()).format(DATE_FORMAT_2);
 };
 
 
 export const dateFormatConverter3 = (date) => {
   return moment(moment.utc(date).toDate()).format(DATE_FORMAT_3);
 };

 export const localDateFormatConverter3 = (date) => {
  return moment(date).format(DATE_FORMAT_3); //new line added
};
 
 export const dateFormatConverter4 = (date) => {
   return moment(moment.utc(date).toDate()).format(MONTH_FORMAT_4);
 };
 
 export const dateTimeFormatConverter2 = (date) => {
   return moment.utc(date).utcOffset("-04:00").format(DATE_TIME_FORMAT_2);
 };
 
 export const dateTimeFormatConverter3 = (date) => {
   return moment.utc(date).utcOffset("-04:00").format(DATE_TIME_FORMAT_3);
 };
 
 export const localDateTimeFormatConverter2 = (date) => {
   return  moment.utc(date).utcOffset("-04:00").format(DATE_TIME_FORMAT_2);
 };
 
 export const localMonthFormatConverter3 = (date) => {
   return moment(moment.utc(date).toDate()).format(MONTH_FORMAT_1);
 };
 
 export const localDateTimeFormatConverter3 = (date) => {
   return moment.utc(date).utcOffset("-04:00").format(DATE_TIME_FORMAT_3);
 };
 
 export const dateTimeFormatConverter4 = (date) => {
   return moment.utc(date).local().format(DATE_TIME_FORMAT_3);
 };