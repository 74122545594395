const matchExpression = {

    email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    phoneno: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
};


const ValidatePassword = value => {

    if (value === "") {
        return 'Please insert password'
    }
    return '';

}

const ValidateConformPassword = (value_1, value_2) => {

    if (value_1 === "") {
        return 'Please insert conform password'
    } else if (value_1 !== value_2) {
        return 'Make sure Password and Conform Password same'
    }
    return '';

}

const ValidateComment = value => {

    if (value === "") {
        return 'Please insert comment'
    }
    return '';

}

const ValidateReason = value => {

    if (value === "") {
        return 'Please insert reason'
    }
    return '';

}

const ValidateFile = value => {

    if (value.length === 0) {
        return 'Please attached zip file'
    }
    return '';

}

const ValidateTargetTime = (value) => {
    if (value === "") {
        return 'Please Enter Valid Number'
    }
    if (!/[1-9]/.test(value)) {
        return 'Target Time Must Be Greater Than Zero';
    }
    return '';
}

const ValidateSuspendJobReason = (value) => {
    if (value === "") {
        return 'Please select suspend reason'
    }
    return '';
}

const ValidateRemodelJobMoSelectionType = (value) => {
    if (value.length === 0) {
        return 'Please select modelling type'
    }
    return '';
}

const ValidateRemodelJobQaSelectionType = (value) => {
    if (value.length === 0) {
        return 'Please select quality assurance type'
    }
    return '';
}

const ValidateAssignJobUserAssignTo = (value) => {
    if (value.name.length === 0 || !value.hasOwnProperty('consoleUserNumber')) {
        return 'Please select user'
    }
    return '';
}

const ValidateAttendance = (value) => {
    if (value === null) {
        return 'Please select attendance'
    }
    return '';
}

const ValidateEmailId = (value) => {
    if (value === '') {
        return 'Please insert email id'
    } else {
        if (!matchExpression.email.test(value)) {
            return 'Please insert valid email id'
        }
        return '';
    }
}

const ValidatePhoneNumber = (value) => {
    if (value === '') {
        return 'Please insert conatct number'
    } else {
        if (!matchExpression.phoneno.test(value)) {
            return 'Please insert valid conatct number'
        }
        return '';
    }
}

const ValidateName = value => {
    if (value === "") {
        return 'Please insert name'
    }
    return '';
}

const ValidateAddress = value => {
    if (value === "") {
        return 'Please insert address'
    }
    return '';
}



export {
    ValidatePassword, ValidateConformPassword,
    ValidateComment,ValidateReason, ValidateFile, ValidateTargetTime, ValidateSuspendJobReason,
    ValidateRemodelJobMoSelectionType, ValidateRemodelJobQaSelectionType,
    ValidateAssignJobUserAssignTo, ValidateAttendance,
    ValidateEmailId, ValidatePhoneNumber, ValidateName, ValidateAddress
}