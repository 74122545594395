/**
 * This is edit milestone form
 * @author Aman Harde
 * @since 1.0
 */

import MomentUtils from "@date-io/moment";
import { Button, CircularProgress, FormHelperText, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AxiosResponse } from "axios";
import moment from "moment";
import { FC, Fragment, useEffect, useState } from "react";
import { fetchLabels } from "../../apis/label-api";
import { editMileStone, getMileStone } from "../../apis/milestone-api";
import { LabelInfo, LabelRef } from "../../interface/label-interface";
import { checkFieldErrors } from "../../utils/field-error-checker";
import { ALERT } from "../../utils/ui-constants";
import { showAlertModal, hideAlertModal } from "../../store/actions/ui-actions"
import { connect } from 'react-redux'

const style = makeStyles({
    formWrapper: {
        marginTop: 0,
        margin: 'auto'
    }
});

interface MilestoneEditProps {
    readonly milestoneInfo: { milestoneNumber: string, version: string },
    readonly _fetchMilestones: (event: any) => void,
    readonly closeDialog: (event: any) => void,
    readonly showAlertModal: (event: any) => void,
    readonly hideAlertModal: (event: any) => void
}

const MilestoneEdit: FC<MilestoneEditProps> = (props) => {
    const classes = style();

    const [state, setState] = useState({
        formData: {
            codeName: '',
            milestoneType: '',
            majorVersion: '',
            minorVersion: '',
            patchVersion: '',
            projectRef: {} as LabelRef,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(new Date()).format("YYYY-MM-DD"),
        },
        fieldError: {
            codeName: '',
            milestoneType: '',
            majorVersion: '',
            minorVersion: '',
            patchVersion: '',
            projectRef: '',
            startDate: '',
            endDate: ''
        },
        wait: {
            forSubmit: false
        },
        errorMessage: "",
        fetchMilestoneError: ''
    });

    const milestone = [
        'MILESTONE', 'HOTFIX'
    ]

    const [_labels, setLabels] = useState({
        data: [] as LabelRef[],
        wait: false
    });

    const resetState = (event: any) => {
        setState({
            formData: {
                codeName: '',
                milestoneType: '',
                majorVersion: '',
                minorVersion: '',
                patchVersion: '',
                projectRef: {} as LabelRef,
                startDate: '',
                endDate: ''
            },
            fieldError: {
                codeName: '',
                milestoneType: '',
                majorVersion: '',
                minorVersion: '',
                patchVersion: '',
                projectRef: '',
                startDate: '',
                endDate: ''
            },
            wait: {
                forSubmit: false
            },
            errorMessage: '',
            fetchMilestoneError: ''
        });
    }

    useEffect(() => {
        getMilestoneInfo(props.milestoneInfo.milestoneNumber);
    }, [props.milestoneInfo.milestoneNumber]);

    const getMilestoneInfo = (milestoneNumber: string) => {
        getMileStone(milestoneNumber).then((res: AxiosResponse<any>) => {
            setState({
                ...state,
                formData: res.data,
                fetchMilestoneError: ""
            });
        }).catch((err: any) => {
            setState({
                ...state,
                fetchMilestoneError: 'Error while getting milestone!'
            });
        });
    }

    const _fetchLabels = (event: any) => {
        setLabels({
            ..._labels,
            wait: true
        });

        fetchLabels({ value: event.target.value }).then((res: AxiosResponse<any>) => {
            setLabels({
                ..._labels,
                data: res.data.map((label: LabelInfo) => { return { value: label.value } as LabelRef }),
                wait: false
            });
        }).catch((err: any) => {
            setLabels({
                ..._labels,
                wait: false
            });
        });
    }

    const onInputChange = (field: string) => (event: any) => {

        switch (field) {
            case 'projectRef':
                _fetchLabels(event);
                break;
            case 'startDate':
            case 'endDate':
                setState({
                    ...state,
                    formData: {
                        ...state.formData,
                        [field]: moment(event).format("YYYY-MM-DD")
                    },
                    fieldError: {
                        ...state.fieldError,
                        [field]: ''
                    },
                    errorMessage: ''
                });
                break;

            default:
                setState({
                    ...state,
                    formData: {
                        ...state.formData,
                        [field]: event.target.value
                    },
                    fieldError: {
                        ...state.fieldError,
                        [field]: ''
                    },
                    errorMessage: ''
                });
                break;
        }
    }

    const onButtonClick = (value: string) => (event: any) => {

        switch (value) {
            case 'reset':
                resetState(event);
                break;

            case 'save':
                submitForm(event);
                break;

            default:
                break;
        }
    }

    const validateFields = (): boolean => {
        let fieldError = checkFieldErrors(state.fieldError, state.formData);

        setState({
            ...state,
            fieldError: fieldError.errors
        });

        return fieldError.haError;
    }

    const submitForm = (event: any) => {
        if (!validateFields()) {

            setState({
                ...state,
                wait: {
                    ...state.wait,
                    forSubmit: true
                }
            });
            editMileStone(props.milestoneInfo.milestoneNumber, state.formData).then((res: AxiosResponse<any>) => {
                props._fetchMilestones(event);
                resetState(event);
                props.closeDialog(event)
                props.showAlertModal({
                    heading: "Success",
                    description: `Milestone edited successfully.`,
                    actionButtons: [
                        {
                            title: "Close",
                            callback: (event: any) => { props.hideAlertModal(event) }
                        },
                    ],
                    variant: ALERT.SUCCESS,
                });
                setState({
                    ...state,
                    wait: {
                        ...state.wait,
                        forSubmit: false
                    },
                    errorMessage: ''
                });
            }).catch((err: any) => {
                props.showAlertModal({
                    heading: "Error",
                    description: `Error`,
                    actionButtons: [
                        {
                            title: "Close",
                            callback: (event: any) => { props.hideAlertModal(event) }
                        },
                    ],
                    variant: ALERT.DANGER,
                });
                setState({
                    ...state,
                    wait: {
                        ...state.wait,
                        forSubmit: false
                    },
                    errorMessage: 'Error while updating milestone.Please try again.'
                });
            });
        }
    }

    const onAutoCompleteSelect = (field: string) => (event: any, newValue: any) => {

        setState({
            ...state,
            formData: {
                ...state.formData,
                [field]: newValue
            },
            fieldError: {
                ...state.fieldError,
                [field]: ""
            },
            errorMessage: ''
        });
    }

    return (
        <Grid container spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formWrapper}>
            {
                state.fetchMilestoneError === '' ?
                    <Fragment>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                label='Name'
                                placeholder='Insert code name'
                                id='edit-code-name'
                                value={state.formData.codeName}
                                onChange={onInputChange('codeName')}
                                error={state.fieldError.codeName !== ''}
                                helperText={state.fieldError.codeName}
                                disabled={state.wait.forSubmit}
                            />
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                            <Autocomplete
                                size='small'
                                fullWidth
                                id='edit-project-ref'
                                options={_labels.data}
                                onChange={onAutoCompleteSelect('projectRef')}
                                getOptionLabel={(option) => option.value}
                                value={state.formData.projectRef}
                                noOptionsText={'Project reference not found'}
                                loading={_labels.wait}
                                loadingText={
                                    <Grid container spacing={2}>
                                        <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                            <Skeleton
                                                variant="rect"
                                                animation='wave'
                                                width={`90%`}
                                            />
                                        </Grid>
                                        <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                            <Skeleton
                                                variant="rect"
                                                animation='wave'
                                                width={`90%`}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label='Project Reference'
                                        placeholder='Insert project reference'
                                        onChange={onInputChange('projectRef')}
                                        error={state.fieldError.projectRef !== ''}
                                        helperText={state.fieldError.projectRef}
                                        disabled={state.wait.forSubmit}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Autocomplete
                                size='small'
                                fullWidth
                                id='milestone-type'
                                options={milestone}
                                value={state.formData.milestoneType}
                                onChange={onAutoCompleteSelect('milestoneType')}
                                //   loading={_labels.wait}
                                loadingText={
                                    <Grid container spacing={2}>
                                        <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                            <Skeleton
                                                variant="rect"
                                                animation='wave'
                                                width={`90%`}
                                            />
                                        </Grid>
                                        <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                            <Skeleton
                                                variant="rect"
                                                animation='wave'
                                                width={`90%`}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        required
                                        label='Milestone Type'
                                        placeholder='Insert Milestone Type'
                                        onChange={onInputChange('milestoneType')}
                                        error={state.fieldError.milestoneType !== ''}
                                        helperText={state.fieldError.milestoneType}
                                        disabled={state.wait.forSubmit}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                            <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                required
                                label='Major'
                                placeholder='Insert major version'
                                id='edit-major-version'
                                value={state.formData.majorVersion}
                                onChange={onInputChange('majorVersion')}
                                error={state.fieldError.majorVersion !== ''}
                                helperText={state.fieldError.majorVersion}
                                disabled={state.wait.forSubmit}
                            />
                        </Grid>

                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                            <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                required
                                label='Minor'
                                placeholder='Insert minor version'
                                id='edit-minor-version'
                                value={state.formData.minorVersion}
                                onChange={onInputChange('minorVersion')}
                                error={state.fieldError.minorVersion !== ''}
                                helperText={state.fieldError.minorVersion}
                                disabled={state.wait.forSubmit}
                            />
                        </Grid>

                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                            <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                required
                                label='Patch'
                                placeholder='Insert patch version'
                                id='edit-patch-version'
                                value={state.formData.patchVersion}
                                onChange={onInputChange('patchVersion')}
                                error={state.fieldError.patchVersion !== ''}
                                helperText={state.fieldError.patchVersion}
                                disabled={state.wait.forSubmit}
                            />
                        </Grid>



                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    size="small"
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    required
                                    label='Start Date'
                                    placeholder='Insert start date'
                                    id='edit-start-date'
                                    format="DD MMM, YYYY"
                                    autoOk={true}
                                    value={state.formData.startDate}
                                    onChange={onInputChange('startDate')}
                                    error={state.fieldError.startDate !== ''}
                                    helperText={state.fieldError.startDate}
                                    disabled={state.wait.forSubmit}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    size="small"
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    label='End Date'
                                    placeholder='Insert end date'
                                    id='edit-end-date'
                                    format="DD MMM, YYYY"
                                    autoOk={true}
                                    minDate={state.formData.startDate}
                                    value={state.formData.endDate}
                                    onChange={onInputChange('endDate')}
                                    disabled={state.wait.forSubmit}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <FormHelperText error={state.errorMessage !== ''}>
                                {state.errorMessage}
                            </FormHelperText>
                        </Grid>

                        <Grid container spacing={1} item xl={12} lg={12} md={12} sm={12} xs={12} direction='row-reverse' alignContent='flex-end'>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    size='small'
                                    color='primary'
                                    id='edit-milestone-button'
                                    onClick={onButtonClick('save')}
                                    disabled={state.wait.forSubmit}
                                    endIcon={state.wait.forSubmit && <CircularProgress color='primary' size={20} />}
                                >
                                    {'Save'}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    color='primary'
                                    size='small'
                                    id='reset-milestone-button'
                                    onClick={onButtonClick('reset')}
                                    disabled={state.wait.forSubmit}
                                >
                                    {'Reset'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Fragment>
                    : <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="subtitle2" color='secondary'>{state.fetchMilestoneError}</Typography>
                    </Grid>
            }
        </Grid>
    )
}

const mapDispatchToProps = {
    showAlertModal,
    hideAlertModal
}

export default connect(null, mapDispatchToProps)(MilestoneEdit);
