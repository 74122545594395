import { colors, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { FileInfo } from ".";
import theme from "../../assets/theme/theme";
import IconSelector from "./icon-selector";



interface FileViwerProps {
    files: FileInfo[],
    deleteFile?: (file: FileInfo, event: any) => void
    downloadFile: (file: FileInfo, event: any) => void
}

const style = makeStyles({

    list: {
        width: `100%`
    },
    listItem: {
        backgroundColor: colors.grey[100],
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5)
    },
    secondaryAction: {
        right: 0
    }
})

const FileViwer: FC<FileViwerProps> = (props) => {
    const classes = style();

    const downloadFile = (fileInfo: FileInfo) => (event: any) => {
        props.downloadFile(fileInfo, event);
    }

    const deleteFile = (fileInfo: FileInfo) => (event: any) => {
        if (window.confirm(`Do you want to delete file ${fileInfo.originalName}?`)) {
            props.deleteFile !== undefined && props.deleteFile(fileInfo, event);
        }
    }


    return (

        <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12}>

            <Grid item container spacing={2} xl={12} lg={12} md={12} sm={12} xs={12}>
                <List className={classes.list}>
                    {
                        props.files.map((file, index) => (
                            <ListItem disableGutters key={index} className={classes.listItem}>
                                <ListItemIcon>
                                    <IconSelector extention={file.ext} />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'subtitle2'
                                    }}
                                    primary={file.originalName}>
                                </ListItemText>
                                <ListItemSecondaryAction className={classes.secondaryAction} >
                                    <IconButton size='small' color='primary' onClick={downloadFile(file)}>
                                        <span className="material-icons">download_for_offline</span>
                                    </IconButton>

                                    {
                                        props.deleteFile !== undefined &&
                                        <IconButton size='small' color='secondary' onClick={deleteFile(file)}>
                                            <span className="material-icons danger">delete</span>
                                        </IconButton>
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))

                    }

                </List>

            </Grid>
        </Grid >

    )

}

export default FileViwer;