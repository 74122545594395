import { Badge, CircularProgress, Chip, colors, Divider, Grid, List, ListItem, makeStyles, Paper, Typography } from "@material-ui/core";
import { FC, Fragment, useRef } from "react";
import theme from "../../assets/theme/theme";
import { IssueInfo } from "../../interface/issue-inteface";
import { formatToDefault } from "../../utils/date-formater";
import { ISSUE_STATE } from "../issue";

const style = makeStyles({
    boardPaper: {
        height: `calc(100vh - 270px)`,
        background: theme.palette.grey[50]
    },
    issueWrapper: {
        '& #open-issue-board': {
            border: `1px solid ${'#2263dd'}`,
            '& #board-head': {
                backgroundColor: '#2263dd',
                color: theme.palette.common.white
            }
        },
        '& #closed-issue-board': {
            border: `1px solid ${colors.pink[100]}`,
            '& #board-head': {
                backgroundColor: colors.pink[100],
                color: theme.palette.common.white
            }
        },
        '& #new-issue-board': {
            border: `1px solid ${colors.blue[400]}`,
            '& #board-head': {
                backgroundColor: colors.blue[400],
                color: theme.palette.common.white
            }
        },
        '& #on-hold-issue-board': {
            border: `1px solid ${theme.palette.warning.main}`,
            '& #board-head': {
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.common.white
            }
        },
        '& #resolved-issue-board': {
            border: `1px solid ${theme.palette.success.dark}`,
            '& #board-head': {
                backgroundColor: theme.palette.success.dark,
                color: theme.palette.common.white
            }
        }
    },
    boardContent: {
        display: 'contents !important'
    },
    badge: {
        top: theme.spacing(1) / 2,
        left: theme.spacing(4)
    },
    issueList: {
        height: `calc(100vh - 324px)`,
        overflowY: "auto",
        overflowX: "hidden",
        '&::-webkit-scrollbar': {
            background: `transparent`,
            height: `8px`,
            width: `8px`,
        },
        '&:hover::-webkit-scrollbar-thumb': {
            background: colors.blueGrey[900]
        },
        '&::-webkit-scrollbar-thumb': {
            border: 'none',
            'box-shadow': 'none',
            'background': colors.blueGrey[300],
            'border-radius': '4px',
            'min-height': '40px',
        },
        '&::-webkit-scrollbar-track': {
            margin: '3px 0px'
        },
        scrollbarWidth: 'thin',
        scrollbarColor: `${colors.blueGrey[900]} transparent`
    },
    issue: {
        padding: theme.spacing(1),
        cursor: 'pointer'
    },
    progress: {
        color: theme.palette.common.white
    }
});

interface IssueBoardProps {
    readonly openIssues: {
        readonly data: IssueInfo[],
        readonly wait?: boolean
        readonly totalElements: number;
    },
    readonly newIssues: {
        readonly data: IssueInfo[],
        readonly wait?: boolean
        readonly totalElements: number;
    }
    readonly onHoldIssues: {
        readonly data: IssueInfo[],
        readonly wait?: boolean
        readonly totalElements: number;
    }
    readonly resolvedIssues: {
        readonly data: IssueInfo[],
        readonly wait?: boolean
        readonly totalElements: number;
    }
    onIssueClick(issueNumber: string, event: any): void,
    fetchIssuesOnScroll(state: string): void
}

const IssueBoard: FC<IssueBoardProps> = (props) => {

    const classes = style();

    const onIssueClick = (issueNumber: string) => (event: any) => {
        props.onIssueClick(issueNumber, event);
    }


    const onScroll = (state: string) => (event: any) => {

        if ((event.target.clientHeight + event.target.scrollTop) >= event.target.scrollHeight) {
            props.fetchIssuesOnScroll(state);
        }

    }
    return (
        <Grid container spacing={3} id='issue-board-wrapper'>
            {/* NEW ISSUE */}
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className={classes.issueWrapper}>

                <Paper className={classes.boardPaper} elevation={8} id='new-issue-board'>

                    <Grid container spacing={2} id='board-content-wrapper' className={classes.boardContent}>
                        <Grid container spacing={0} item id='board-head'>
                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                <Typography variant='subtitle1'><strong>{`New`}</strong></Typography>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}> {
                                props.newIssues.wait &&
                                <CircularProgress color="primary" size={24} className={classes.progress} />
                            }
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Badge badgeContent={props.newIssues.totalElements} color='primary' className={classes.badge} />
                            </Grid>
                        </Grid>

                        <Divider />
                        <Grid container spacing={2} item id='board-content' className={classes.boardContent} >
                            {
                                props.newIssues.data.length !== 0 ?
                                    <List id='issue-content-wrapper' className={classes.issueList} disablePadding onScrollCapture={onScroll(ISSUE_STATE.NEW)}>
                                        {
                                            props.newIssues.data.map((info, index) => (
                                                <ListItem key={index}  >
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                        component={Paper} elevation={4}
                                                        id='issue'
                                                        className={classes.issue}
                                                        onClick={onIssueClick(info?.issueNumber)}
                                                    >
                                                        <Typography variant='subtitle2'><strong>{`#${info.issueNumber}`}</strong></Typography>
                                                        <Divider />
                                                        <Typography variant='subtitle2'><strong>{info.title}</strong></Typography>
                                                        <Typography variant='caption'>{formatToDefault(info.createdDate)}</Typography>
                                                        {
                                                            info.assignees.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2'><strong>{`Assignees:`}</strong></Typography>
                                                                {
                                                                    info.assignees.map((assignee, _assignee) => (
                                                                        <Chip
                                                                            key={_assignee}
                                                                            variant="default"
                                                                            label={assignee.username}
                                                                            size="small"
                                                                        />
                                                                    ))
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            info.labels.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2'><strong>{`Labels:`}</strong></Typography>
                                                                {
                                                                    info.labels.map((label, _label) => (
                                                                        <Chip
                                                                            key={_label}
                                                                            variant="default"
                                                                            label={label.value}
                                                                            size="small"
                                                                        />
                                                                    ))
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            info.milestone !== null &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2'><strong>{`Milestone:`}</strong></Typography>
                                                                <Chip
                                                                    variant="default"
                                                                    label={`${info.milestone.codeName} - [ ${info.milestone.projectRef?.value} ]`}
                                                                    size="small"
                                                                />
                                                            </Fragment>
                                                        }
                                                    </Grid>

                                                </ListItem>

                                            ))
                                        }
                                    </List>
                                    :
                                    <Grid item id='issue-content-message'>
                                        <Typography variant='h5' align='center'>{`Issues not available`}</Typography>
                                    </Grid>
                            }
                        </Grid>

                    </Grid>

                </Paper>

            </Grid>

            {/* OPEN ISSUE  */}
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className={classes.issueWrapper}>

                <Paper className={classes.boardPaper} elevation={8} id='open-issue-board'>

                    <Grid container spacing={2} id='board-content-wrapper' className={classes.boardContent}>
                        <Grid container spacing={0} item id='board-head' >
                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                <Typography variant='subtitle1'><strong>{`Open`}</strong></Typography>
                            </Grid>

                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>  {
                                props.openIssues.wait &&
                                <CircularProgress color="primary" size={24} className={classes.progress} />
                            }
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Badge badgeContent={props.openIssues.totalElements} color='primary' className={classes.badge} />
                            </Grid>
                        </Grid>

                        <Divider />
                        <Grid container spacing={2} item id='board-content' className={classes.boardContent} >
                            {
                                props.openIssues.data.length !== 0 ?
                                    <List id='issue-content-wrapper' className={classes.issueList} disablePadding onScrollCapture={onScroll(ISSUE_STATE.OPEN)}>
                                        {
                                            props.openIssues.data.map((info, index: number) => (

                                                <ListItem key={index}>
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                        component={Paper} elevation={4}
                                                        id='issue'
                                                        className={classes.issue}
                                                        onClick={onIssueClick(info?.issueNumber)}
                                                    >
                                                        <Typography variant='subtitle2'><strong>{`#${info.issueNumber}`}</strong></Typography>
                                                        <Divider />
                                                        <Typography variant='subtitle2'><strong>{info.title}</strong></Typography>
                                                        <Typography variant='caption'>{formatToDefault(info.createdDate)}</Typography>
                                                        {
                                                            info.assignees.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2' ><strong>Assignees</strong></Typography>
                                                                {
                                                                    info.assignees.map((assignee, _assignee) => (
                                                                        <Chip
                                                                            key={_assignee}
                                                                            variant="default"
                                                                            label={assignee.username}
                                                                            size="small"
                                                                        />
                                                                    ))
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            info.labels.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2'><strong>Labels</strong></Typography>
                                                                {
                                                                    info.labels.map((label, _label) => (
                                                                        <Chip
                                                                            key={_label}
                                                                            variant="default"
                                                                            label={label.value}
                                                                            size="small"
                                                                        />
                                                                    ))
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            info.milestone !== null && <Fragment>
                                                                <Typography variant='subtitle2'><strong>Milestone</strong></Typography>
                                                                <Chip
                                                                    key={index}
                                                                    variant="default"
                                                                    label={`${info.milestone.codeName} - [ ${info.milestone.projectRef?.value} ]`}
                                                                    size="small"
                                                                />
                                                            </Fragment>
                                                        }
                                                    </Grid>

                                                </ListItem>

                                            ))
                                        }
                                    </List>
                                    :
                                    <Grid item id='issue-content-message'>
                                        <Typography variant='h5' align='center'>{`Issues not available`}</Typography>
                                    </Grid>
                            }
                        </Grid>

                    </Grid>

                </Paper>

            </Grid>

            {/* ON_RESOLVED ISSUE */}
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className={classes.issueWrapper}>

                <Paper className={classes.boardPaper} elevation={8} id='resolved-issue-board'>

                    <Grid container spacing={2} id='board-content-wrapper' className={classes.boardContent}>
                        <Grid container spacing={0} item id='board-head'>
                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                <Typography variant='subtitle1'><strong>{`Resolved`}</strong></Typography>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                {
                                    props.resolvedIssues.wait &&
                                    <CircularProgress color="primary" size={24} className={classes.progress} />
                                }
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Badge badgeContent={props.resolvedIssues.totalElements} color='primary' className={classes.badge} />
                            </Grid>
                        </Grid>

                        <Divider />
                        <Grid container spacing={2} item id='board-content' className={classes.boardContent} >
                            {
                                props.resolvedIssues.data.length !== 0 ?

                                    <List id='issue-content-wrapper' className={classes.issueList} disablePadding onScrollCapture={onScroll(ISSUE_STATE.RESOLVED)}>
                                        {
                                            props.resolvedIssues.data.map((info, index) => (

                                                <ListItem key={index}>
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                        component={Paper} elevation={4}
                                                        id='issue'
                                                        className={classes.issue}
                                                        onClick={onIssueClick(info?.issueNumber)}
                                                    >
                                                        <Typography variant='subtitle2'><strong>{`#${info.issueNumber}`}</strong></Typography>
                                                        <Divider />
                                                        <Typography variant='subtitle2'><strong>{info.title}</strong></Typography>
                                                        <Typography variant='caption'>{formatToDefault(info.createdDate)}</Typography>
                                                        {
                                                            info.assignees.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2' ><strong>{`Assignees:`}</strong></Typography>
                                                                {
                                                                    info.assignees.map((assignee, _assignee) => (
                                                                        <Chip
                                                                            key={_assignee}
                                                                            variant="default"
                                                                            label={assignee.username}
                                                                            size="small"
                                                                        />
                                                                    ))
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            info.labels.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2'><strong>{`Labels:`}</strong></Typography>
                                                                {
                                                                    info.labels.map((label, _label) => (<Chip
                                                                        key={_label}
                                                                        variant="default"
                                                                        label={label.value}
                                                                        size="small"
                                                                    />
                                                                    ))
                                                                }

                                                            </Fragment>
                                                        }
                                                        {
                                                            info.milestone !== null && <Fragment>
                                                                <Typography variant='subtitle2'><strong>{`Milestone:`}</strong></Typography>
                                                                <Chip
                                                                    key={index}
                                                                    variant="default"
                                                                    label={`${info.milestone.codeName} - [ ${info.milestone.projectRef?.value} ]`}
                                                                    size="small"
                                                                />
                                                            </Fragment>
                                                        }

                                                    </Grid>

                                                </ListItem>

                                            ))
                                        }
                                    </List>
                                    :
                                    <Grid item id='issue-content-message'>
                                        <Typography variant='h5' align='center'>{`Issues not available`}</Typography>
                                    </Grid>
                            }
                        </Grid>

                    </Grid>

                </Paper>

            </Grid>

            {/* ON_HOLD ISSUE */}
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className={classes.issueWrapper}>

                <Paper className={classes.boardPaper} elevation={8} id='on-hold-issue-board'>

                    <Grid container spacing={2} id='board-content-wrapper' className={classes.boardContent}>
                        <Grid container spacing={0} item id='board-head'>
                            <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                <Typography variant='subtitle1'><strong>{`On Hold`}</strong></Typography>
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                                {
                                    props.onHoldIssues.wait &&
                                    <CircularProgress color="primary" size={24} className={classes.progress} />
                                }
                            </Grid>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Badge badgeContent={props.onHoldIssues.totalElements} color='primary' className={classes.badge} />
                            </Grid>
                        </Grid>

                        <Divider />
                        <Grid container spacing={2} item id='board-content' className={classes.boardContent} >
                            {
                                props.onHoldIssues.data.length !== 0 ?

                                    <List id='issue-content-wrapper' className={classes.issueList} disablePadding onScrollCapture={onScroll(ISSUE_STATE.ON_HOLD)}>
                                        {
                                            props.onHoldIssues.data.map((info, index) => (

                                                <ListItem key={index}>
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}
                                                        component={Paper} elevation={4}
                                                        id='issue'
                                                        className={classes.issue}
                                                        onClick={onIssueClick(info?.issueNumber)}
                                                    >
                                                        <Typography variant='subtitle2'><strong>{`#${info.issueNumber}`}</strong></Typography>
                                                        <Divider />
                                                        <Typography variant='subtitle2'><strong>{info.title}</strong></Typography>
                                                        <Typography variant='caption'>{formatToDefault(info.createdDate)}</Typography>
                                                        {
                                                            info.assignees.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2' ><strong>{`Assignees:`}</strong></Typography>
                                                                {
                                                                    info.assignees.map((assignee, _assignee) => (
                                                                        <Chip
                                                                            key={_assignee}
                                                                            variant="default"
                                                                            label={assignee.username}
                                                                            size="small"
                                                                        />
                                                                    ))
                                                                }
                                                            </Fragment>
                                                        }
                                                        {
                                                            info.labels.length !== 0 &&
                                                            <Fragment>
                                                                <Typography variant='subtitle2'><strong>{`Labels:`}</strong></Typography>
                                                                {
                                                                    info.labels.map((label, _label) => (<Chip
                                                                        key={_label}
                                                                        variant="default"
                                                                        label={label.value}
                                                                        size="small"
                                                                    />
                                                                    ))
                                                                }

                                                            </Fragment>
                                                        }
                                                        {
                                                            info.milestone !== null && <Fragment>
                                                                <Typography variant='subtitle2'><strong>{`Milestone:`}</strong></Typography>
                                                                <Chip
                                                                    key={index}
                                                                    variant="default"
                                                                    label={`${info.milestone.codeName} - [ ${info.milestone.projectRef?.value} ]`}
                                                                    size="small"
                                                                />
                                                            </Fragment>
                                                        }

                                                    </Grid>

                                                </ListItem>

                                            ))
                                        }
                                    </List>
                                    :
                                    <Grid item id='issue-content-message'>
                                        <Typography variant='h5' align='center'>{`Issues not available`}</Typography>
                                    </Grid>
                            }
                        </Grid>

                    </Grid>

                </Paper>

            </Grid>

        </Grid >

    )
}


export default IssueBoard;