/**
 * 
 * This is modal for show alert
 * 
 * @function
 * @param isShowAlertModal - to show alert modal
 * @param alertModalData - to display data
 * 
 * alertModalData={ heading, subHeading, description, actionButtons, variant }
 * 
 * @author Rahul Bhati
 * 
 */
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { ReactComponent as Checkbox } from "../../assets/icons/checkbox-outline.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error_icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info_icon.svg";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning_icon.svg";
import theme from '../../assets/theme/theme';
import { hideAlertModal } from '../../store/actions/ui-actions';
import { ALERT, ALERT_BUTTON_TYPE } from '../../utils/ui-constants';

const useStyles = makeStyles({

    [ALERT.SUCCESS]: {
        backgroundColor: theme.palette.success.dark
    },
    [ALERT.DANGER]: {
        backgroundColor: theme.palette.error.dark
    },
    [ALERT.WARNING]: {
        backgroundColor: theme.palette.warning.dark
    }

});

function Alert(props) {

    const classes = useStyles();
    const { isShowAlertModal, alertModalData } = props;
    const onCancelClick = event => {
        props.hideAlertModal();
    };
    const iconsOnVariant = event => {
        switch (alertModalData?.variant) {
            case ALERT.SUCCESS:
                return <Checkbox fill={theme.palette.common.white} height={32} width={32}  />

            case ALERT.DANGER:
                return <ErrorIcon fill={theme.palette.common.white} height={28} width={30}  />

            case ALERT.WARNING:
                return <WarningIcon fill={theme.palette.common.white} height={28} width={30} />

            case ALERT.INFO:
                return <InfoIcon fill={theme.palette.common.white} height={28} width={30} />

            default:
                return <InfoIcon fill={theme.palette.common.white} height={28} width={30} />;


        }
    }

    return (

        <Dialog
            open={isShowAlertModal}
            maxWidth="sm"
            fullWidth={true}
            onClose={onCancelClick}
            disableBackdropClick={true}
            id='alert-modal'
        >

            <DialogTitle id='alert-modal-title' className={classes[alertModalData?.variant]} disableTypography>
                <Grid container spacing={1} alignItems={alertModalData?.subHeading && 'center'}>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                            {iconsOnVariant()}
                    </Grid>
                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                        {alertModalData?.heading && <Typography variant='h5' color='initial'>  {alertModalData?.heading}  </Typography>}
                        {alertModalData?.subHeading && <Typography color='initial' variant='subtitle2'>{alertModalData?.subHeading}</Typography>}
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent dividers={alertModalData?.actionButtons ? true : false} id="alert-modal-description">
                {alertModalData?.description &&
                    <Typography variant='subtitle1'>
                        {alertModalData?.description}
                    </Typography>}
            </DialogContent>


            <DialogActions id='alert-modal-action'>

                {alertModalData?.actionButtons &&
                    alertModalData.actionButtons.map((button, index) => (
                        <Button
                            key={index}
                            id={`alert-modal-${button.title}`}
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={button.callback}
                            disabled={button.waiting}
                            endIcon={button?.type === ALERT_BUTTON_TYPE.BUTTON_PRIMARY && button.waiting && <CircularProgress color={button.waiting ? 'primary' : 'inherit'} size={15} />}
                        >
                            {button.title}
                        </Button>
                    ))
                }

            </DialogActions>

        </Dialog >
    );

}

const mapStateToProps = state => {
    return {
        isShowAlertModal: state.uiReducer.isShowAlertModal,
        alertModalData: state.uiReducer.alertModalData,
    }
}

const mapDispatchToProps = {
    hideAlertModal
};


export default connect(mapStateToProps, mapDispatchToProps)(Alert);
