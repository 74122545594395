
import { Dispatch } from "redux";
import { UserType } from "../type";
import { Action } from '.';
import { UserInfo } from "../../interface/reducer-interface";
import { setJWTToken } from "../../utils/axios-config";


export const login = (userInfo: UserInfo ) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: UserType.LOGIN,
            payload: userInfo
        });
    }
}

export const logout = () => {
   // Clear access token from variable
   setJWTToken(null);
   // Clear refresh token from local storage
   localStorage.removeItem("refreshToken");
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: UserType.LOGOUT
        });
    }
}
