import MomentUtils from "@date-io/moment";
import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getAttendanceByMonth } from "../../apis/attendance-api";
import { findByMonth } from "../../apis/holiday-api";
import Calendar from "../../components/calendar";
import { hideAlertModal, showAlertModal } from "../../store/actions/ui-actions";
import {
  dateFormatConverter3,
  localMonthFormatConverter3,
} from "../../utils/dateFormates";
import Userattendance from "../attendance/user-attendance";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  userWrapper: {
    marginRight: 0,
  },
  heading: {
    padding: "inherit",
  },
  subContent: {
    paddingLeft: "inherit",
  },
  datePicker: {
    padding: "inherit",
  },
  bold: { fontWeight: "bold" },
}));

function Attendance(props) {
  const { username } = useSelector((state) => state.userReducer.userInfo);

  const classes = useStyles();
  const[reason,setReason] = useState();


console.log("xyz4151",reason);


  const [state, setState] = useState({
    selectedJobDate: new Date(),
    selectedAttendanceMonth: new Date(),
    appliedJobDate: new Date(),
    appliedAttendanceMonth: new Date(),
    selectedAttendanceDate: null,
    jobdata: {},
    summaryError: "",
  });

  const [attendanceResponse, setAttendanceResponse] = useState([]);
  const [holidayResponse, setHolidayResponse] = useState([]);

  const {
    selectedAttendanceMonth,
    appliedAttendanceMonth,
  
    
  } = state;

  useEffect(() => {
    getAttendance();
    getHolidays();
  }, [appliedAttendanceMonth]);

  const getHolidays = () => {
    const month = { month: moment(localMonthFormatConverter3(appliedAttendanceMonth)).format("YYYY-MM") } ;

    findByMonth(month)
      .then(async (response) => {
        await setHolidayResponse(response.data);

        // this.setState({
        //   holidayData: response.data,
        // });

      })
      .catch((error) => {
     
        console.error('Error:', error);
      });
    };

  const getAttendance = () => {

    const attendanceRequest = {
      month: localMonthFormatConverter3(appliedAttendanceMonth),

      userName: username,
    };

    getAttendanceByMonth(attendanceRequest)
      .then(async (response) => {
        await setAttendanceResponse(response.data);
       // console.log("xyz",response.data)
        setReason(response.data)
     
      })
      .catch((error) => {
        console.log("error", error.response);
      });
  };

  const handleDateChange = (field) => (newDate) => {
    let date = dateFormatConverter3(newDate);
    setState({
      ...state,
      [field]: date,
    });
  };
  

  const onApplyClick = (field) => (event) => {
    if (field === "appliedAttendanceMonth") {
      setState({
        ...state,
        appliedAttendanceMonth: state.selectedAttendanceMonth,
       

      });
    } else {
      setState({
        ...state,
        appliedJobDate: state.selectedJobDate,
      });
    }
  };
  const onDateClick = (event, date) => {
    setState({
      ...state,
      selectedAttendanceDate: date,
    });
  };

  const closeModal = (event) => {
    setState({
      ...state,
      selectedAttendanceDate: null,
    });
  };
  return (
    <Paper variant="outlined" className={classes.root}>
      <Container maxWidth="xl" disableGutters>
        <Grid container spacing={2}>
          <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Paper variant="outlined">
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="h5" className={classes.heading}>
                        User Attendance 
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <DatePicker
                            size="small"
                            variant="inline"
                            inputVariant="outlined"
                            label="Select month"
                            id="attendance-month"
                            format="MMMM, YYYY"
                            openTo="month"
                            views={["year", "month"]}
                            autoOk={true}
                            value={selectedAttendanceMonth}
                            onChange={handleDateChange(
                              "selectedAttendanceMonth"
                            )}
                            className={classes.datePicker}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          id="attendance-apply"
                          onClick={onApplyClick("appliedAttendanceMonth")}
                        >
                          {"Apply"}
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid
                      style={{ justifyContent: "center", alignItems: "center" }}
                      item
                      justifyContent="center"
                      alignItems="center"
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <Calendar
                        value={moment(appliedAttendanceMonth)}
                        attendanceData={attendanceResponse}
                        holidayData={holidayResponse}
                        onDateClick={(event, date) => onDateClick(event, date)}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Userattendance
          closeModal={(event) => {
            closeModal(event);
          }}
          selectedAttendanceDate={state.selectedAttendanceDate}
          attendanceData={attendanceResponse}
          reason={reason}
        />
       
      </Container>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userReducer.userDetails,
  };
};

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Attendance);
