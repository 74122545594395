/**
 *  This is component retuns calendar using month and year.
 *  This component includes functionality of:
 *      - Generate dates using month and year
 *      - Color Sunday dates
 *      - Highlight Current Date
 *
 *  @param {value} - value is moment(date())
 *
 *  @returns Calendar component
 *
 *  @author Aman Harde
 */

import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import moment from "moment";
import { connect } from "react-redux";
import { hideAlertModal, showAlertModal } from "../../store/actions/ui-actions";
//  import Header from "./header";
import {
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

const WEEKDAYS = moment.weekdaysShort();

const useStyles = makeStyles((theme) => {
  return {
    tableCell: {
      padding: theme.spacing(1),
    },
    dateInfo: {
      lineHeight: 0,
    },
    date: {
      height: theme.spacing(1) * 5,
      width: theme.spacing(1) * 5,
    },
    todaysDate: {
      borderBottomStyle: "solid",
      borderBottomWidth: theme.spacing(1) / 2,
      borderBottomColor: theme.palette.primary.main,
    },
    sundayDate: {
      color: theme.palette.error.main,
    },
    holiday: {
      color: theme.palette.error.main,
    },

    PRESENT: {
      "&:hover": {
        background: theme.palette.success.main,
        boxShadow: theme.shadows[5],
      },
      background: theme.palette.success.main,
      color: theme.palette.common.white,
      //   top: theme.spacing(3) / 2,
    },
    ABSENT: {
      "&:hover": {
        background: theme.palette.error.main,
        boxShadow: theme.shadows[5],
      },
      background: theme.palette.error.main,
      color: theme.palette.common.white,
      /* top: theme.spacing(3) / 2 */
    },
    ONLEAVE: {
      "&:hover": {
        background: theme.palette.info.main,
        boxShadow: theme.shadows[5],
      },
      background: theme.palette.info.main,
      color: theme.palette.common.white,
      /* top: theme.spacing(3) / 2 */
    },

    NOEXTRA: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: "50%",
      border: "1px solid",
      position: "relative",
      top: theme.spacing(1) / 2,
      marginLeft: theme.spacing(1) / 4,
      marginRight: theme.spacing(1) / 4,
      display: "inline-block",
    },
    NOTAVAILABLE: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      borderRadius: "50%",
      border: "1px solid",
      position: "relative",
      top: theme.spacing(1) / 2,
      marginLeft: theme.spacing(1) / 4,
      marginRight: theme.spacing(1) / 4,
      display: "inline-block",
      background: theme.palette.error.main,
    },
    indicatorText: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    presentIndicator: {
      height: theme.spacing(3) / 2,
      width: theme.spacing(3) / 2,
      marginRight: theme.spacing(1) / 2,
      borderRadius: "50%",
      display: "inline-block",
      verticalAlign: "text-top",
      background: theme.palette.success.main,
    },
    absentIndicator: {
      height: theme.spacing(3) / 2,
      width: theme.spacing(3) / 2,
      marginRight: theme.spacing(1) / 2,
      borderRadius: "50%",
      display: "inline-block",
      verticalAlign: "text-top",
      background: theme.palette.error.main,
    },
    onLeaveIndicator: {
      height: theme.spacing(3) / 2,
      width: theme.spacing(3) / 2,
      marginRight: theme.spacing(1) / 2,
      borderRadius: "50%",
      display: "inline-block",
      verticalAlign: "text-top",
      background: theme.palette.info.main,
    },
    noExtraIndicator: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      marginRight: theme.spacing(1) / 2,
      borderRadius: "50%",
      border: "1px solid",
      display: "inline-block",
    },
    notAvailableIndicator: {
      height: theme.spacing(1),
      width: theme.spacing(1),
      marginRight: theme.spacing(1) / 2,
      borderRadius: "50%",
      border: "1px solid",
      display: "inline-block",
      background: theme.palette.error.main,
    },
  };
});

function Calendar({ value, attendanceData, holidayData, onDateClick }) {
  //dummy data
  //   attendanceData = [
  //   {
  //     id: "642533de4b25150c84e25ac3",
  //     consoleUserNumber: "CU1012",
  //     consoleUserName: "Rizwan Ahmed",
  //     attendanceDate: "2023-03-31",
  //     attendance: "ABSENT",
  //     recordedBy: { name: "rizwan", consoleUserNumber: "CU1012" },
  //     recordedTime: "2023-03-30T07:01:50.626",
  //     noExtra: false,
  //     notAvailable: false,
  //   },
  //   {
  //     id: "642533de4b25150c84e25aw3",
  //     consoleUserNumber: "CU1012",
  //     consoleUserName: "Rizwan Ahmed",
  //     attendanceDate: "2023-04-01",
  //     attendance: "ONLEAVE",
  //     recordedBy: { name: "rizwan", consoleUserNumber: "CU1012" },
  //     recordedTime: "2023-03-30T07:01:50.626",
  //     noExtra: false,
  //     notAvailable: false,
  //   },
  //   {
  //     id: "642533de4b25150c84e25aw3",
  //     consoleUserNumber: "CU1012",
  //     consoleUserName: "Rizwan Ahmed",
  //     attendanceDate: "2023-04-03",
  //     attendance: "PRESENT",
  //     recordedBy: { name: "rizwan", consoleUserNumber: "CU1012" },
  //     recordedTime: "2023-03-30T07:01:50.626",
  //     noExtra: false,
  //     notAvailable: false,
  //   },
  // ];
  //

  const classes = useStyles();

  const [calendar, setCalendar] = useState([]);

  //  const dateFormat = (day) => {
  //      return moment(day).format('DD-MM-YYYY')
  //  }

  useEffect(() => {
    setCalendar(buildCalendar(value));
  }, [value]);

  function buildCalendar(month_year) {
    const calendar = [];

    const startDay = month_year.clone().startOf("month").startOf("week");

    const endDay = month_year.clone().endOf("month").endOf("week");

    const _date = startDay.clone().subtract(1, "day");

    while (_date.isBefore(endDay, "day")) {
      calendar.push(
        Array(WEEKDAYS.length)
          .fill(0)
          .map(() => {
            let day = _date.add(1, "day").clone();
            if (moment(day).isSame(month_year, "month")) {
              return day;
            }
            return null;
          })
      );
    }
    return calendar;
  }

  function isToday(day) {
    return moment(new Date()).isSame(day, "day");
  }

  function isSunday(day) {
    if (day.day() === 0) return true;
  }

  function todayStyle(day) {
    if (isToday(day)) return "todaysDate";
  }

  function dayStyles(day) {
    if (isSunday(day)) return "sundayDate";
    if (isHoliday(day)) return "sundayDate";
    return "";
  }

  function isHoliday(day) {
    for (let key in holidayData) {
      if (holidayData[key].date === moment(day).format("YYYY-MM-DD")) {
        return "sundayDate";
      }
    }
    return "";
  }

  function checkHoliday(day) {
    for (let key in holidayData) {
      if (holidayData[key].date === moment(day).format("YYYY-MM-DD")) {
        return true;
      }
    }
    return false;
  }

  function attendance(day) {
    for (let key in attendanceData) {
      if (
        attendanceData[key].attendanceDate === moment(day).format("YYYY-MM-DD")
      ) {
        return attendanceData[key].attendance;
      }
    }
    return "";
  }

  function noExtraStyle(day) {
    for (let key in attendanceData) {
      if (
        attendanceData[key].attendanceDate === moment(day).format("YYYY-MM-DD")
      ) {
        if (attendanceData[key].noExtra) return "NOEXTRA";
      }
    }
  }

  function notAvailableStyle(day) {
    for (let key in attendanceData) {
      if (
        attendanceData[key].attendanceDate === moment(day).format("YYYY-MM-DD")
      ) {
        if (attendanceData[key].notAvailable) return "NOTAVAILABLE";
      }
    }
  }

  return (
    <TableContainer id="calender-wrapper">
      <Grid container>
        {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                     <Header value={value} onChange={onChange} />
                 </Grid>
  */}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Table id="calender-wrapper">
            <TableHead id="calender-head-wrapper">
              <TableRow id="calender-head">
                {WEEKDAYS.map((day, index) => (
                  <TableCell
                    id={`calender-${day}-day`}
                    align="center"
                    variant="head"
                    key={index}
                  >
                    {day}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody id="calender-body-wrapper">
              {calendar.map((_week, weekIndex) => (
                <TableRow id={`calendar-week-${weekIndex + 1}`} key={weekIndex}>
                  {_week.map((day, dateIndex) => {
                    const _date =
                      day !== null ? day.format("D").toString() : null;
                    const dayStyle = day !== null ? dayStyles(day) : null;

                    return (
                      <TableCell
                        align="center"
                        variant="body"
                        key={dateIndex}
                        id={`calendar-date-wrapper`}
                        className={clsx(
                          classes.tableCell,
                          classes[todayStyle(day)]
                        )}
                      >
                        {_date !== null && (
                          <Fragment>
                            <Typography
                              variant="button"
                              color="inherit"
                              id={`calendar-date-${_date}`}
                              component={IconButton}
                              className={clsx(
                                classes.date,
                                classes[dayStyle],
                                classes[
                                  checkHoliday(day) &&
                                  attendance.attendance !== "ONLEAVE"
                                    ? isHoliday(day)
                                    : attendance(day)
                                ]
                              )}
                              onClick={(event) =>
                                dayStyle !== "sundayDate" &&
                                onDateClick(event, day)
                              }
                            >
                              {_date}
                            </Typography>

                            <Typography className={classes.dateInfo}>
                              <span
                                id={`calendar-date-no-extra-${_date}`}
                                className={classes[noExtraStyle(day)]}
                              />
                              <span
                                id={`calendar-date-no-available-${_date}`}
                                className={classes[notAvailableStyle(day)]}
                              />
                            </Typography>
                          </Fragment>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid
          container
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          align="right"
          justify="space-between"
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="caption" className={classes.indicatorText}>
              <span className={classes.presentIndicator} />
              {"Present"}
            </Typography>

            <Typography variant="caption" className={classes.indicatorText}>
              <span className={classes.absentIndicator} />
              {"Absent"}
            </Typography>

            <Typography variant="caption" className={classes.indicatorText}>
              <span className={classes.onLeaveIndicator} />
              {"On Leave"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

const mapStateToProps = (state) => {
  // console.log('Kumar shub',state.userReducer.userInfo.username)
  const userName = state.userReducer.userInfo.username;
  return {
    userInfo: state.userReducer.userDetails,
    userName,
  };
};

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

// export default Calendar;
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
