import axios from "axios";

const ROOT_URL = '/api/labels';


export const fetchLabels = request => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/filter/search`,
        params: request
    };

    return axios(options);
}

export const getLabels = request => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}`,
        params: request
    };

    return axios(options);
}

export const getLabel = labelNumber => {
    const options = {
        method: "GET",
        url: `${ROOT_URL}/${labelNumber}`
    };

    return axios(options);
}

export const createLabel = request => {
    const options = {
        method: "POST",
        url: `${ROOT_URL}`,
        data: request
    };

    return axios(options);
}

export const editLabel = (labelNumber, request) => {
    const options = {
        method: "PUT",
        url: `${ROOT_URL}/${labelNumber}`,
        data: request
    };

    return axios(options);
}

export const deleteLabel = (labelNumber) => {
    const options = {
        method: "DELETE",
        url: `${ROOT_URL}/${labelNumber}`
    };

    return axios(options);
}
