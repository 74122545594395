/**
 *  This is All issuse manage component
 *
 *  @author Aman Harde
 */

import { Button, Container, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ISSUE_STATE } from "../issue";
import { fetchLabels } from "../../apis/label-api";
import { fetchMileStonesByPattern } from "../../apis/milestone-api";
import { getUsersByPattern } from "../../apis/user-api";
import SearchBar from "../../components/search-bar";
import { FilterOption, Suggestion, SuggestionValues } from "../../components/search-bar/intefaces";
import searchRequestConvertor from "../../components/search-bar/searchRequestConvertor";
import { IssueInfo } from "../../interface/issue-inteface";
import { LabelInfo } from "../../interface/label-interface";
import { MilestoneRef } from "../../interface/milestone-interface";
import { AuthUser } from "../../interface/user-interface";
import { findAllIssues } from "../../apis/issue-api";
import IssuesList from "../issue/issues-list";
import { showTab, fetchFilter } from "../../store/actions/ui-actions";
import { TAB_TYPE } from "../../utils/ui-constants";
import { FetchType } from "../../store/type";
import { filterAllIssues } from "../../apis/issue-api";


const style = makeStyles({
    root: {
        display: 'contents'
    },
    refClick: {
        cursor: 'pointer',
        "&:hover": {
            textDecoration: 'underline'
        }
    }
});


interface InitialIssueState {
    data: IssueInfo[],
    totalPages: number,
    totalElements: number,
    numberOfElements: number,
    wait: boolean
}

const initialIssueState: InitialIssueState = {
    data: [],
    //size: 0,
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    wait: false
}

const filterOptions: FilterOption[] = [
    { label: "Title", value: "title", type: "string" },
    { label: "State", value: "state", type: "suggestion", suggestionType: "static" },
    { label: "Assignee", value: "assignee", type: "suggestion", suggestionType: "dynamic" },
    { label: "Label", value: "label", type: "suggestion", suggestionType: "dynamic" },
    { label: "Milestone", value: "milestone", type: "suggestion", suggestionType: "dynamic" },
    { label: "Issue number", value: "issueNumber", type: "string" },
    { label: "Date Range", value: "dateRange", type: "date_range" ,maxDays:31 },
    { label: "Milestone number", value: "milestoneNumber", type: "string" },
];

const assignee: Suggestion = {
    values: [],
    key: filterOptions[2].label,
    ref: filterOptions[2].value

};

const label: Suggestion = {
    values: [],
    key: filterOptions[3].label,
    ref: filterOptions[3].value

};

const milestone: Suggestion = {
    values: [],
    key: filterOptions[4].label,
    ref: filterOptions[4].value
};


const dateRage: Suggestion = {
    values: [{ displayMember: filterOptions[6].label, valueMember: { startDate: new Date(), endDate: new Date() } }],
    key: filterOptions[6].label,
    ref: filterOptions[6].value
};

const issue_state: Suggestion = {
    values: [
        { displayMember: 'Open', valueMember: { state: ISSUE_STATE.OPEN } },
        { displayMember: 'New', valueMember: { state: ISSUE_STATE.NEW } },
        { displayMember: 'Closed', valueMember: { state: ISSUE_STATE.CLOSED } },
        { displayMember: 'On hold', valueMember: { state: ISSUE_STATE.ON_HOLD } },
        { displayMember: 'Resolved', valueMember: { state: ISSUE_STATE.RESOLVED } }
    ],
    key: filterOptions[1].label,
    ref: filterOptions[1].value
};

const AllIssues: FC = () => {

    const classes = style();
    const dispatch = useDispatch();

    const [issues, setIssues] = useState<InitialIssueState>(initialIssueState);

    const onFilterChange = (appliedFilters: any, pageRequest: any) => {
        setIssues({
            ...issues,
            wait: true
        });

        return new Promise((resolve, reject) => {




            findAllIssues(searchRequestConvertor(appliedFilters, pageRequest)).then((res: AxiosResponse<any>) => {
                setIssues({
                    ...issues,
                    data: res.data.content,
                    numberOfElements: res.data.numberOfElements,
                    totalElements: res.data.totalElements,
                    totalPages: res.data.totalPages,
                    wait: false
                });

                resolve(res.status);
            }).catch((err: any) => {
                setIssues({
                    ...issues,
                    wait: false
                });
                reject()
            });
        });
    }

   

    const onSuggestionChange = (selectedKey: FilterOption, value: string) => {
        switch (selectedKey.value) {
            case 'assignee':
                return new Promise<SuggestionValues[]>((resolve, reject) => {
                    getUsersByPattern({ authUser: value }).then((res: AxiosResponse<any>) => {

                        let values: SuggestionValues[] = [];

                        res.data.map((user: AuthUser) => {
                            values.push({ displayMember: user.username, valueMember: { assignee: user.username } })
                        });
                        resolve(values);
                    });

                });

            case 'label':
                return new Promise((resolve, reject) => {
                    fetchLabels({ value: value }).then((res: AxiosResponse<any>) => {
                        let values: SuggestionValues[] = [];
                        res.data.map((_label: LabelInfo) => {
                            values.push({ displayMember: _label.value, valueMember: { label: _label.value } })
                        });

                        resolve(values);
                    });
                });

            case 'milestone':
                return new Promise((resolve, reject) => {
                    fetchMileStonesByPattern({ pattern: value }).then((res: AxiosResponse<any>) => {
                        let values: SuggestionValues[] = [];

                        res.data.map((_milestone: MilestoneRef) => {
                            values.push({
                              displayMember: `${_milestone.projectRef.value} : [ ${_milestone.version} ]`,
                              valueMember: {
                                label: `${_milestone.projectRef.value} : [ ${_milestone.version} ]`,
                              },
                            });
                        });
                        resolve(values);
                    });
                });
        }
    }

    const onIssueClick = (info: IssueInfo, event: any) => {
        dispatch(showTab('VIEW_ISSUE_INFO_TAB', { issueNumber: info.issueNumber }));
    }

    const onButtonClick = (tabValue: TAB_TYPE) => (event: any) => {
        dispatch(showTab(tabValue));
    }

    return (
        <Container id='all-issues-wrapper' maxWidth="xl">
            <Grid container spacing={2} className={classes.root}>
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} id='all-issues-head-wrapper'>
                    <Grid item xl={10} lg={8} md={8} sm={6} xs={12}>
                        <Typography variant="h4">{"All Issues"}</Typography>
                    </Grid>
                    <Grid container spacing={1} item xl={2} lg={4} md={4} sm={6} xs={12} direction='row-reverse' alignContent='flex-end'>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                startIcon={<i className="fas fa-bug" />}
                                size='small'
                                id='rise-issue-button'
                                onClick={onButtonClick("NEW_ISSUE_TAB")}
                            >
                                {'New Issue'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Divider />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} id='all-issues-search-bar-wrapper'>
                    <SearchBar
                        keys={filterOptions}
                        suggestions={[dateRage, issue_state, assignee, label, milestone]}
                        fetchSavedFilter={() => { dispatch(fetchFilter(FetchType.FETCH_START)) }}
                        collectionName={"searchbar"}
                        onFilterChange={onFilterChange}
                        onSuggestionChange={onSuggestionChange}
                        allowPagination
                        totalPages={issues.totalPages}
                        showingResults={{ totalElements: issues.totalElements, numberOfElements: issues.numberOfElements }}
                    />
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} id='all-issues-list-wrapper'>
                    <IssuesList
                        data={issues.data}
                        onIssueClick={onIssueClick}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default AllIssues;