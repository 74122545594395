import axios from 'axios';
const REPORT_URL = '/api/team-report'; 

export const ReportApi = month  => {
    const options = {
      method: "GET",
      url:  `${REPORT_URL}`,
      params: { month: month }
      
    };
  
    return axios(options);
  };