import { Grid } from "@material-ui/core";
import { FC } from "react";
import Body from "./body";
import Header from "./header";

const Main: FC = () => {

    return (
        <div id='layout-wrapper'>
            <Header />
            <Body />
        </div>
    )
}

export default Main;