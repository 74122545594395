import { IssueType } from "../type";
import { Action } from "../actions";
import { IssueReducerInitalState } from "../../interface/reducer-interface";
import { IssueCreateForm } from "../../interface/issue-inteface";

export const initialState: IssueReducerInitalState = {
    createIssueForm: {
        assignees: [],
        milestone: null,
        labels: [],
        title: '',
        desc: '',
        relatedLinks: [],
        dependsOnLinks: []
    } as IssueCreateForm
};

const issueReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case IssueType.SET_CREATE_ISSUE_DATA:
            return {
                ...state,
                createIssueForm: action.payload
            };

        default:
            return state
    }
}


export default issueReducer;