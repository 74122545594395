/**
 * This is create milestone tabel
 * @author Aman Harde
 * @since 1.0
 */

import {
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AxiosResponse } from "axios";
import { FC, useEffect, useState } from "react";
import {
  closeMilestoneApi,
  deleteMileStone,
  getMileStoneStatusCount,
} from "../../apis/milestone-api";
import { postCreateReleaseIssue } from "../../apis/issue-api";
import theme from "../../assets/theme/theme";
import { useHistory } from "react-router-dom";
import { showTab } from "../../store/actions/ui-actions";
import { useDispatch } from "react-redux";
import { PageRequest } from "../../interface/common-interface";
import TablePaginationActions from "../../components/drawer/table-pagination/table-paginationa-actions";
import { showAlertModal, hideAlertModal } from "../../store/actions/ui-actions";
import { connect } from "react-redux";
import { ALERT } from "../../utils/ui-constants";

interface MilestoneTableProps {
  waitForMileStones: boolean;
  milestones: any[];
  totalElements: number;
  pageRequest: PageRequest;
  handlePagination: (pageRequest: PageRequest, event: any) => any;
  onActionClick: (
    action: string,
    milestoneNumber: string,
    version: string,
    event: any
  ) => void;
  _fetchMilestones: (event: any) => void;
  showAlertModal: (event: any) => void;
  hideAlertModal: (event: any) => void;
  milestoneNumber: string;
  statusCounts: {
    state: string;
    count: number;
  }[];
}

const useStyles = makeStyles({
  skeleton: {
    padding: theme.spacing(3),
  },
});

const MilestoneTable: FC<MilestoneTableProps> = (props) => {

  const [milestoneStatusData, setMilestoneStatusData] = useState<any[]>([]);

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const onActionClick =
    (
      action: string,
      milestoneNumber: string,
      version: string,
      displayName?: string
    ) =>
    (event: any) => {
      switch (action) {
        case "EDIT":
          props.onActionClick(action, milestoneNumber, version, event);
          break;

        case "DELETE":
          props.showAlertModal({
            heading: "Warning",
            description: `Do you want to delete milestone ${version}?`,
            actionButtons: [
              {
                title: "No",
                callback: (event: any) => {
                  props.hideAlertModal(event);
                },
              },
              {
                title: "Yes",
                callback: (event: any) => {
                  onDelete(action, milestoneNumber, version, event);
                },
              },
            ],
            variant: ALERT.WARNING,
          });
          break;

        case "CLOSE":
          props.showAlertModal({
            heading: "Warning",
            description: `Are you sure you want to close ${version} milestone?`,
            actionButtons: [
              {
                title: "No",
                callback: (event: any) => {
                  props.hideAlertModal(event);
                },
              },
              {
                title: "Yes",
                callback: (event: any) => {
                  closeMilestone(action, milestoneNumber, version, event);
                },
              },
            ],
            variant: ALERT.WARNING,
          });
          break;

        case "VIEW":
          {
            history.push(
              `/workspace?milestoneNumber=${milestoneNumber}&version=${displayName}&page=1&size=20`
            );
            dispatch(showTab("ALL_ISSUES_TAB"));
          }
          break;

          case "RELEASEISSUE":
            props.showAlertModal({
              heading: "Warning",
              description: `Do you want to create Release Issue ?`,
              actionButtons: [
                {
                  title: "No",
                  callback: (event: any) => {
                    props.hideAlertModal(event);
                  },
                },
                {
                  title: "Yes",
                  callback: (event: any) => {
                    releaseIssue(action, milestoneNumber, version, event)
                  },
                },
              ],
              variant: ALERT.WARNING,
            });
            break;

          
        

        default:
          break;
      }
    };

  useEffect(() => {
    getMileStoneStatusCount()
      .then((res: AxiosResponse<any>) => {
        setMilestoneStatusData(res.data);
      })
      .catch((err: any) => {});
  }, []);

  const onDelete = (
    action: string,
    milestoneNumber: string,
    version: string,
    event: any
  ) => {
    deleteMileStone(milestoneNumber)
      .then((res: AxiosResponse<any>) => {
        props._fetchMilestones(event);
        props.showAlertModal({
          heading: "Success",
          description: `Milestone ${version} deleted successfully.`,
          actionButtons: [
            {
              title: "Close",
              callback: (event: any) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.SUCCESS,
        });
      })
      .catch((err: any) => {
        props.showAlertModal({
          heading: "Error",
          description: `${err?.response?.data?.message}`,
          actionButtons: [
            {
              title: "Close",
              callback: (event: any) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  const closeMilestone = (
    action: string,
    milestoneNumber: string,
    version: string,
    event: any
  ) => {
    closeMilestoneApi(milestoneNumber)
      .then((res: AxiosResponse<any>) => {
        props._fetchMilestones(event);
        props.showAlertModal({
          heading: "Success",
          description: `Milestone ${version} closed successfully.`,
          actionButtons: [
            {
              title: "Close",
              callback: (event: any) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.SUCCESS,
        });
      })
      .catch((err) => {
        props.showAlertModal({
          heading: "Error",
          description: `${err?.response?.data?.message}`,
          actionButtons: [
            {
              title: "Close",
              callback: (event: any) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  const  releaseIssue = (
    action: string,
    milestoneNumber: string,
    version: string,
    event: any
  ) => {
    postCreateReleaseIssue(milestoneNumber)
      .then((res: AxiosResponse<any>) => {
        props.showAlertModal({
          heading: "Success",
          description: `Issue Created Successfully`,
          actionButtons: [
            {
              title: "Close",
              callback: (event: any) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.SUCCESS,
        });
      })
      .catch((err: any) => {
        props.showAlertModal({
          heading: "Error",
          description: `${err?.response?.data?.message}`,
          actionButtons: [
            {
              title: "Close",
              callback: (event: any) => {
                props.hideAlertModal(event);
              },
            },
          ],
          variant: ALERT.DANGER,
        });
      });
  };

  const areAllIssuesResolved = (milestoneNumber: string) => {
    const milestoneIssues = milestoneStatusData.find(
      (data) => data.milestoneNumber === milestoneNumber
    );

    if (milestoneIssues) {
      return milestoneIssues.statusCounts.every(
        (countData: any) => countData.state === "RESOLVED"
      );
    }

    return false;
  };


  const handleChangePage = (event: any, page: number) => {
    props.handlePagination({ ...props.pageRequest, page }, event);
  };

  const handleChangeRowsPerPage = (event: any) => {
    props.handlePagination(
      { ...props.pageRequest, size: parseInt(event.target.value, 10) },
      event
    );
  };

  return (
    <TableContainer component={Paper}>
      <div style={{ maxHeight: "700px", overflowY: "scroll" }}>
        <Table>
          <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
              <TableCell>Milestone Number</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Issue Status</TableCell>
              <TableCell>Milestone Type</TableCell>
              <TableCell>Project Reference</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">{`Action`}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.waitForMileStones ? (
              Array(5)
                .fill(5)
                .map((skeleton, index) => (
                  <TableRow key={index}>
                    <TableCell colSpan={7} align="center">
                      <Skeleton
                        variant="rect"
                        width="95%"
                        height="10%"
                        animation="wave"
                        className={classes.skeleton}
                      />
                    </TableCell>
                  </TableRow>
                ))
            ) : props.milestones.length !== 0 ? (
              props.milestones.map((info, index) => (
                <TableRow key={index}>
                  <TableCell>{info.milestoneNumber}</TableCell>
                  <TableCell>{info.version}</TableCell>
                  <TableCell>
                    {milestoneStatusData.map((statusData: any) => {
                      if (statusData.milestoneNumber === info.milestoneNumber) {
                        return (
                          <div key={statusData.state}>
                            {statusData.statusCounts.map((countData: any) => (
                              <div key={countData.state}>
                                <li>
                                  {countData.state}: {countData.count}
                                </li>
                              </div>
                            ))}
                          </div>
                        );
                      }
                    })}
                  </TableCell>
                  <TableCell>{info.type}</TableCell>
                  <TableCell>{info.projectRef.value}</TableCell>
                  <TableCell>{info.startDate}</TableCell>
                  <TableCell>{info.endDate}</TableCell>
                  <TableCell>{info.status}</TableCell>
                  <TableCell align="center">
                    {info.status !== "CLOSED" && (
                      <IconButton
                        size="small"
                        color="primary"
                        title="Edit milestone"
                        onClick={onActionClick(
                          "EDIT",
                          info.milestoneNumber,
                          info.version
                        )}
                      >
                        <span className="material-icons">edit</span>
                      </IconButton>
                    )}
                    {info.status !== "CLOSED" && (
                      <IconButton
                        size="small"
                        color="secondary"
                        title="Delete milestone"
                        onClick={onActionClick(
                          "DELETE",
                          info.milestoneNumber,
                          info.version
                        )}
                      >
                        <span className="material-icons">delete</span>
                      </IconButton>
                    )}

                    {info.status !== "CLOSED" && (
                      <IconButton
                        size="small"
                        color="secondary"
                        title="Close milestone"
                        onClick={onActionClick(
                          "CLOSE",
                          info.milestoneNumber,
                          info.version
                        )}
                      >
                        <span className="material-icons">close</span>
                      </IconButton>
                    )}

                    <IconButton
                      size="small"
                      color="primary"
                      title="View issues"
                      onClick={onActionClick(
                        "VIEW",
                        info.milestoneNumber,
                        info.codeName,
                        info.version
                      )}
                    >
                      <span className="material-icons">search</span>
                    </IconButton>

                    {areAllIssuesResolved(info.milestoneNumber) && (
                      <IconButton
                        size="small"
                        color="primary"
                        title="Release issues"
                        onClick={onActionClick(
                          "RELEASEISSUE",
                          info.milestoneNumber,
                          info.codeName,
                          info.version
                        )}
                      >
                        <span className="fas fa-bug dark"></span>
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography variant="h5"> Data Not Available </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                colSpan={8}
                count={props.totalElements}
                rowsPerPage={props.pageRequest.size}
                page={props.pageRequest.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </TableContainer>
  );
};

const mapDispatchToProps = {
  showAlertModal,
  hideAlertModal,
};

export default connect(null, mapDispatchToProps)(MilestoneTable);
