import { UserType } from "../type";
import { Action } from "../actions";
import { UserInfo, UserReducerInitalState } from "../../interface/reducer-interface";

const initialState: UserReducerInitalState = {
    userInfo: {
        token: null,
        username: null,
        roles: [],
    } as UserInfo
};

const userReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case UserType.LOGIN:
            return {
                ...state,
                // userInfo: action.payload.roles,
                userInfo: {
                    ...state.userInfo,
                    token: action.payload.token,
                    username: action.payload.username,
                    roles: action.payload.roles,
                },
            };
        case UserType.LOGOUT:
            return initialState;

        default:
            return state
    }
}


export default userReducer;