import { Box, Button, CircularProgress, colors, Grid, makeStyles, Typography } from "@material-ui/core";
import { FC } from "react";
import { UPLOAD_STATUS } from ".";
import theme from "../../assets/theme/theme";


interface FileUploadHandlerProps {
    onChange: (event: any) => void,
    disableFileUpload?: boolean,
    onCancelUpload: (event: any) => void,
    errorMessage?: string,
    status: UPLOAD_STATUS,
    progress: number,
    files: any[]
}

const style = makeStyles({
    cancelUploadButtton: {
        background: theme.palette.error.main,
        '&:hover': {
            backgroundColor: colors.red[700],
        },
        color: theme.palette.common.white
    },
})

const FileUploadHandler: FC<FileUploadHandlerProps> = (props) => {
    const classes = style();

    const onInputChange = (event: any) => {
        props.onChange(event);
    }

    const onButtonClick = (name: string) => (event: any) => {
        props.onCancelUpload(event);
    }

    return (

        <Grid container spacing={3} item xl={12} lg={12} md={12} sm={12} xs={12}>

            <Grid item container spacing={2} xl={12} lg={12} md={12} sm={12} xs={12}>

                {
                    props.status === UPLOAD_STATUS.IN_PROGRESS ?
                        <Grid item>
                            <Button
                                variant="contained"
                                size='medium'
                                className={classes.cancelUploadButtton}
                                onClick={onButtonClick('cancelUpload')}
                            >
                                {'Cancel Uploading'}
                            </Button>
                        </Grid>
                        :
                        <Grid item>
                            <label htmlFor="upload-file">
                                <input
                                    id="upload-file"
                                    name="upload-file"
                                    style={{ display: 'none' }}
                                    type="file"
                                    multiple
                                    onChange={onInputChange}
                                    disabled={props.disableFileUpload}
                                />
                                <Button
                                    variant="contained"
                                    disabled={props.disableFileUpload}
                                    color='primary'
                                    component='span'
                                    startIcon={<span className="material-icons">upload_file</span>}
                                >
                                    {`Select Files`}
                                </Button>
                            </label>
                        </Grid>
                }
                {
                    props.status === UPLOAD_STATUS.CANCELLED &&
                    <Grid item>
                        <Button
                            variant="outlined"
                            size='medium'
                            color='inherit'
                            onClick={onButtonClick('cancelUpload')}
                            startIcon={<span className="material-icons">refresh</span>}
                        >
                            {'Retry Uploading'}
                        </Button>
                    </Grid>
                }
            </Grid>
            {
                props.progress !== 0 &&

                <Grid container spacing={2} item xl={12} lg={12} md={12} sm={12} xs={12} alignItems='center'>
                    <Grid item>
                        <Box position="relative" display="inline-flex">
                            <CircularProgress variant="determinate" value={props.progress} thickness={4} size={50} />
                            <Box
                                top={0}
                                left={0}
                                bottom={0}
                                right={0}
                                position="absolute"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.progress)}%`}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">{props.files.length === 1 ? `Uploading a file...` : `Uploading ${[props.files.length]} files...`}</Typography>
                    </Grid>
                </Grid>
            }
            {
                props.errorMessage &&
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="subtitle1" color='secondary'>{props.errorMessage}</Typography>
                </Grid>
            }
        </Grid>

    )

}

export default FileUploadHandler;